export const GET_PREP_PLANS = 'GET_PREP_PLANS';
export const SET_PREP_PLANS = 'SET_PREP_PLANS';
export const API_ERROR = 'LOGIN_API_ERROR';
export const SET_PREP_PLAN = 'SET_PREP_PLAN';
export const ADD_PREP_PLAN = 'ADD_PREP_PLAN';
export const EMPTY_PREP_PLAN = 'EMPTY_PREP_PLAN';
export const DELETE_PREP_PLAN = 'DELETE_PREP_PLAN';
export const EDIT_PREP_PLAN = 'EDIT_PREP_PLAN';
export const UPDATE_PREP_PLAN = 'UPDATE_PREP_PLAN';
export const SET_PREP_PLAN_FILTER = 'SET_PREP_PLAN_FILTER';
export const GET_PREP_PLANS_LIST = 'GET_PREP_PLANS_LIST';
export const SET_PREP_PLANS_LIST = 'SET_PREP_PLANS_LIST';