import {
  GET_PREP_PLANS,
  SET_PREP_PLANS,
  GET_PREP_PLANS_LIST,
  SET_PREP_PLANS_LIST,
  SET_PREP_PLAN,
  ADD_PREP_PLAN,
  EMPTY_PREP_PLAN,
  DELETE_PREP_PLAN,
  EDIT_PREP_PLAN,
  UPDATE_PREP_PLAN,
  SET_PREP_PLAN_FILTER,
  API_ERROR,
} from './actionTypes';

export const getPrepPlans = () => {
  return {
    type: GET_PREP_PLANS,
    payload: {},
  };
};

export const setPrepPlans = (data) => {
  return {
    type: SET_PREP_PLANS,
    payload: data,
  };
};

export const getPrepPlansList = () => {
  return {
    type: GET_PREP_PLANS_LIST,
    payload: {},
  };
};

export const setPrepPlansList = (data) => {
  return {
    type: SET_PREP_PLANS_LIST,
    payload: data,
  };
};

export const setPrepPlan = (data) => {
  return {
    type: SET_PREP_PLAN,
    payload: data,
  };
};

export const addPrepPlan = (history) => {
  return {
    type: ADD_PREP_PLAN,
    payload: {history},
  };
};

export const emptyPrepPlan = () => {
  return {
    type: EMPTY_PREP_PLAN,
    payload: {},
  };
};

export const deletePrepPlan = (id) => {
  return {
    type: DELETE_PREP_PLAN,
    payload: { id },
  };
};

export const editPrepPlan = (id) => {
  return {
    type: EDIT_PREP_PLAN,
    payload: { id },
  };
};

export const updatePrepPlan = (history, id) => {
  return {
    type: UPDATE_PREP_PLAN,
    payload: { history, id },
  };
};

export const setPrepPlanFilter = (data) => {
  return {
    type: SET_PREP_PLAN_FILTER,
    payload: data,
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};
