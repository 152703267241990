import {
  GET_RECEIPES,
  SET_RECEIPES,
  GET_RECEIPES_LIST,
  SET_RECEIPES_LIST,
  SET_RECEIPE,
  ADD_RECEIPE,
  EMPTY_RECEIPE,
  DELETE_RECEIPE,
  EDIT_RECEIPE,
  UPDATE_RECEIPE,
  SET_RECEIPE_FILTER,
  API_ERROR,
} from './actionTypes';

export const getReceipes = () => {
  return {
    type: GET_RECEIPES,
    payload: {},
  };
};

export const setReceipes = (data) => {
  return {
    type: SET_RECEIPES,
    payload: data,
  };
};

export const getReceipesList = () => {
  return {
    type: GET_RECEIPES_LIST,
    payload: {},
  };
};

export const setReceipesList = (data) => {
  return {
    type: SET_RECEIPES_LIST,
    payload: data,
  };
};

export const setReceipe = (data) => {
  return {
    type: SET_RECEIPE,
    payload: data,
  };
};

export const addReceipe = (history) => {
  return {
    type: ADD_RECEIPE,
    payload: {history},
  };
};

export const emptyReceipe = () => {
  return {
    type: EMPTY_RECEIPE,
    payload: {},
  };
};

export const deleteReceipe = (id) => {
  return {
    type: DELETE_RECEIPE,
    payload: { id },
  };
};

export const editReceipe = (id) => {
  return {
    type: EDIT_RECEIPE,
    payload: { id },
  };
};

export const updateReceipe = (history, id) => {
  return {
    type: UPDATE_RECEIPE,
    payload: { history, id },
  };
};

export const setReceipeFilter = (data) => {
  return {
    type: SET_RECEIPE_FILTER,
    payload: data,
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};
