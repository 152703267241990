import {
  GET_PRODUCT_CATEGORIES,
  SET_PRODUCT_CATEGORIES,
  GET_PRODUCT_CATEGORIES_LIST,
  SET_PRODUCT_CATEGORIES_LIST,
  SET_PRODUCT_CATEGORY,
  ADD_PRODUCT_CATEGORY,
  EMPTY_PRODUCT_CATEGORY,
  DELETE_PRODUCT_CATEGORY,
  EDIT_PRODUCT_CATEGORY,
  UPDATE_PRODUCT_CATEGORY,
  SET_PRODUCT_CATEGORY_FILTER,
  API_ERROR,
} from './actionTypes';

export const getProductCategories = () => {
  return {
    type: GET_PRODUCT_CATEGORIES,
    payload: {},
  };
};

export const setProductCategories = (data) => {
  return {
    type: SET_PRODUCT_CATEGORIES,
    payload: data,
  };
};

export const getProductCategoriesList = () => {
  return {
    type: GET_PRODUCT_CATEGORIES_LIST,
    payload: {},
  };
};

export const setProductCategoriesList = (data) => {
  return {
    type: SET_PRODUCT_CATEGORIES_LIST,
    payload: data,
  };
};

export const setProductCategory = (data) => {
  return {
    type: SET_PRODUCT_CATEGORY,
    payload: data,
  };
};

export const addProductCategory = (history = {}) => {
  return {
    type: ADD_PRODUCT_CATEGORY,
    payload: {history},
  };
};

export const emptyProductCategory = () => {
  return {
    type: EMPTY_PRODUCT_CATEGORY,
    payload: {},
  };
};

export const deleteProductCategory = (id) => {
  return {
    type: DELETE_PRODUCT_CATEGORY,
    payload: { id },
  };
};

export const editProductCategory = (id) => {
  return {
    type: EDIT_PRODUCT_CATEGORY,
    payload: { id },
  };
};

export const updateProductCategory = (id, history = {}) => {
  return {
    type: UPDATE_PRODUCT_CATEGORY,
    payload: { history, id },
  };
};

export const setProductCategoryFilter = (data) => {
  return {
    type: SET_PRODUCT_CATEGORY_FILTER,
    payload: data,
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};
