import { takeEvery, fork, put, all, call, select } from 'redux-saga/effects';
import {
  GET_PRODUCT_SUB_CATEGORIES,
  ADD_PRODUCT_SUB_CATEGORY,
  DELETE_PRODUCT_SUB_CATEGORY,
  EDIT_PRODUCT_SUB_CATEGORY,
  UPDATE_PRODUCT_SUB_CATEGORY,
  GET_PRODUCT_SUB_CATEGORIES_LIST
} from './actionTypes';
import {
  setProductSubCategoriesList,
  setProductSubCategories,
  emptyProductSubCategory,
  setProductSubCategory,
  apiError,
  getProductSubCategories as fetchProductSubCategories,
  getProductSubCategoriesList as fetchProductSubCategoriesList,
  setProductSubCategoryFilter,
} from './actions';
import { httpDelete, httpGet, httpPost, httpPut } from '../../utils/http';
import { PRODUCT_SUB_CATEGORIES_ENDPOINT } from '../../config/endPoints';
import { successToaster, errorToaster } from "../../components/Common/Toaster";
import {isEmpty} from "lodash"

function* getProductSubCategories() {
  try {
    const subCategoryState = yield select(getProductSubCategory);
    let URL = PRODUCT_SUB_CATEGORIES_ENDPOINT;
    URL += `?offSet=${subCategoryState.filter.offSet}`;
    URL += `&limit=${subCategoryState.filter.limit}`;
    URL += `&query=${subCategoryState.filter.query}`;

    const res = yield httpGet(URL);

    if (res.status !== 200) {
      yield put(apiError(res.data.error));
      return false;
    }

    yield put(setProductSubCategories(res.data.records));
    yield put(
      setProductSubCategoryFilter({
        field: 'pages',
        value: res.data.pages,
      })
    );
  } catch (error) {
    yield put(apiError(error));
  }
}

function* getProductSubCategoriesList({payload: {categoryId}}) {
  try {
    let URL = `${PRODUCT_SUB_CATEGORIES_ENDPOINT}/get/list/${categoryId}`;
    const res = yield httpGet(URL);

    if (res.status !== 200) {
      yield put(apiError(res.data.error));
      return false;
    }
    yield put(setProductSubCategoriesList(res.data));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* addProductSubCategory({payload: {history}}) {
  try {
    const subCategoryState = yield select(getProductSubCategory);
    const produtSubcategory = subCategoryState.productSubCategory;
    const res = yield httpPost(PRODUCT_SUB_CATEGORIES_ENDPOINT, produtSubcategory);
    console.log(res);
    if (res.status !== 200) {
      yield put(apiError(res.data.error));
      return false;
    }

    yield put(emptyProductSubCategory());
    if(!isEmpty(history)) history.push('/categories/list');
    successToaster('Record added.', 'Success');
  } catch (error) {
    yield put(apiError(error));
  }
}

function* deleteProductSubCategory({ payload: { id } }) {
  try {
    const res = yield httpDelete(`${PRODUCT_SUB_CATEGORIES_ENDPOINT}/${id}`);
    if (res.status !== 200) {
      yield put(apiError(res.data.error));
      return false;
    }
    console.log('shoud run');
  } catch (error) {
    yield put(apiError(error));
  }
}

function* editProductSubCategory({ payload: { id } }) {
  try {
    const res = yield httpGet(`${PRODUCT_SUB_CATEGORIES_ENDPOINT}/${id}`);
    if (res.status !== 200) {
      yield put(apiError(res.data.error));
      return false;
    }

    yield put(setProductSubCategory({ field: 'category_name', value: res.data.category_name }));
    yield put(setProductSubCategory({ field: 'parent_id', value: res.data.parent_id }));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* updateProductSubCategory({ payload: { history, id } }) {
  try {
    const subCategoryState = yield select(getProductSubCategory);
    const produtSubcategory = subCategoryState.productSubCategory;
    const res = yield httpPut(`${PRODUCT_SUB_CATEGORIES_ENDPOINT}/${id}`, produtSubcategory);
    console.log(res);
    if (res.status !== 200) {
      yield put(apiError(res.data.error));
      return false;
    }

    yield put(emptyProductSubCategory());
    if(!isEmpty(history)) history.push('/categories/list');
    successToaster('Record updated', 'Success');
  } catch (error) {
    yield put(apiError(error));
  }
}

const getProductSubCategory = (state) => state.ProductSubCategory;

export function* watchGetProductSubCategories() {
  yield takeEvery(GET_PRODUCT_SUB_CATEGORIES, getProductSubCategories);
}

export function* watchGetProductSubCategoriesList() {
  yield takeEvery(GET_PRODUCT_SUB_CATEGORIES_LIST, getProductSubCategoriesList);
}

export function* watchAddProductSubCategory() {
  yield takeEvery(ADD_PRODUCT_SUB_CATEGORY, addProductSubCategory);
}

export function* watchDeleteProdcutSubCategory() {
  yield takeEvery(DELETE_PRODUCT_SUB_CATEGORY, deleteProductSubCategory);
}

export function* watchEditProductCategory() {
  yield takeEvery(EDIT_PRODUCT_SUB_CATEGORY, editProductSubCategory);
}

export function* watchUpdateProductSubCategory() {
  yield takeEvery(UPDATE_PRODUCT_SUB_CATEGORY, updateProductSubCategory);
}

function* productSubCategorySaga() {
  yield all([
    fork(watchGetProductSubCategories),
    fork(watchAddProductSubCategory),
    fork(watchDeleteProdcutSubCategory),
    fork(watchEditProductCategory),
    fork(watchUpdateProductSubCategory),
    fork(watchGetProductSubCategoriesList)
  ]);
}

export default productSubCategorySaga;
