import {
  GET_PRODUCT_SUB_CATEGORIES,
  SET_PRODUCT_SUB_CATEGORIES,
  GET_PRODUCT_SUB_CATEGORIES_LIST,
  SET_PRODUCT_SUB_CATEGORIES_LIST,
  SET_PRODUCT_SUB_CATEGORY,
  ADD_PRODUCT_SUB_CATEGORY,
  EMPTY_PRODUCT_SUB_CATEGORY,
  DELETE_PRODUCT_SUB_CATEGORY,
  EDIT_PRODUCT_SUB_CATEGORY,
  UPDATE_PRODUCT_SUB_CATEGORY,
  SET_PRODUCT_SUB_CATEGORY_FILTER,
  API_ERROR,
} from './actionTypes';

export const getProductSubCategories = () => {
  return {
    type: GET_PRODUCT_SUB_CATEGORIES,
    payload: {},
  };
};

export const setProductSubCategories = (data) => {
  return {
    type: SET_PRODUCT_SUB_CATEGORIES,
    payload: data,
  };
};

export const getProductSubCategoriesList = (categoryId) => {
  return {
    type: GET_PRODUCT_SUB_CATEGORIES_LIST,
    payload: {categoryId},
  };
};

export const setProductSubCategoriesList = (data) => {
  return {
    type: SET_PRODUCT_SUB_CATEGORIES_LIST,
    payload: data,
  };
};

export const setProductSubCategory = (data) => {
  return {
    type: SET_PRODUCT_SUB_CATEGORY,
    payload: data,
  };
};

export const addProductSubCategory = (history = {}) => {
  return {
    type: ADD_PRODUCT_SUB_CATEGORY,
    payload: {history},
  };
};

export const emptyProductSubCategory = () => {
  return {
    type: EMPTY_PRODUCT_SUB_CATEGORY,
    payload: {},
  };
};

export const deleteProductSubCategory = (id) => {
  return {
    type: DELETE_PRODUCT_SUB_CATEGORY,
    payload: { id },
  };
};

export const editProductSubCategory = (id) => {
  return {
    type: EDIT_PRODUCT_SUB_CATEGORY,
    payload: { id },
  };
};

export const updateProductSubCategory = (id, history = {}) => {
  return {
    type: UPDATE_PRODUCT_SUB_CATEGORY,
    payload: { history, id },
  };
};

export const setProductSubCategoryFilter = (data) => {
  return {
    type: SET_PRODUCT_SUB_CATEGORY_FILTER,
    payload: data,
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};
