import { takeEvery, fork, put, all, call, select } from 'redux-saga/effects';
import {
  GET_PREP_PLAN_REPORTS,
  GET_PREP_PLAN_REPORTS_LIST,
  DELETE_PREP_PLAN_REPORT,
} from './actionTypes';
import {
  setPrepPlanReportsList,
  setPrepPlanReports,
  apiError,
  getPrepPlanReports as fetchPrepPlanReports,
  setPrepPlanReportFilter,
} from './actions';
import { httpDelete, httpGet, httpPost, httpPut } from '../../utils/http';
import { PREP_PLAN_REPORTS_ENDPOINT } from '../../config/endPoints';
import { successToaster, errorToaster } from "../../components/Common/Toaster";

function* getPrepPlanReports() {
  try {
    const prepPlanReportState = yield select(getPrepPlanReport);
    let URL = PREP_PLAN_REPORTS_ENDPOINT;
    URL += `?offSet=${prepPlanReportState.filter.offSet}`;
    URL += `&limit=${prepPlanReportState.filter.limit}`;
    URL += `&query=${prepPlanReportState.filter.query}`;

    const res = yield httpGet(URL);

    if (res.status !== 200) {
      yield put(apiError(res.data.error));
      return false;
    }

    yield put(setPrepPlanReports(res.data.records));
    yield put(
      setPrepPlanReportFilter({
        field: 'pages',
        value: res.data.pages,
      })
    );
  } catch (error) {
    yield put(apiError(error));
  }
}

function* getPrepPlanReportsList() {
  try {
    let URL = `${PREP_PLAN_REPORTS_ENDPOINT}/get/list`;
    const res = yield httpGet(URL);

    if (res.status !== 200) {
      yield put(apiError(res.data.error));
      return false;
    }

    yield put(setPrepPlanReportsList(res.data));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* deletePrepPlanReport({ payload: { id } }) {
  try {
    const res = yield httpDelete(`${PREP_PLAN_REPORTS_ENDPOINT}/${id}`);
    if (res.status !== 200) {
      yield put(apiError(res.data.error));
      return false;
    }
    console.log('shoud run');
    yield put(fetchPrepPlanReports());
  } catch (error) {
    yield put(apiError(error));
  }
}

const getPrepPlanReport = (state) => state.PrepPlanReport;

export function* watchGetPrepPlanReports() {
  yield takeEvery(GET_PREP_PLAN_REPORTS, getPrepPlanReports);
}

export function* watchGetPrepPlanReportsList() {
  yield takeEvery(GET_PREP_PLAN_REPORTS_LIST, getPrepPlanReportsList);
}

export function* watchDeletePrepPlanReport() {
  yield takeEvery(DELETE_PREP_PLAN_REPORT, deletePrepPlanReport);
}

function* prepPlanReportSaga() {
  yield all([
    fork(watchGetPrepPlanReports),
    fork(watchDeletePrepPlanReport),
    fork(watchGetPrepPlanReportsList)
  ]);
}

export default prepPlanReportSaga;
