import { takeEvery, fork, put, all, call, select } from 'redux-saga/effects';
import {
  GET_EMERGENCY_ITEMS,
  ADD_EMERGENCY_ITEM,
  DELETE_EMERGENCY_ITEM,
  EDIT_EMERGENCY_ITEM,
  UPDATE_EMERGENCY_ITEM,
} from './actionTypes';
import {
  setEmergencyItems,
  emptyEmergencyItem,
  setEmergencyItem,
  apiError,
  getEmergencyItems as fetchEmergencyItems,
  setEmergencyItemFilter,
} from './actions';
import { httpDelete, httpGet, httpPost, httpPut } from '../../utils/http';
import { EMERGENCY_ITEMS_ENDPOINT } from '../../config/endPoints';
import { successToaster, errorToaster } from "../../components/Common/Toaster";

function* getEmergencyItems() {
  try {
    const emergencyItemState = yield select(getEmergencyItem);
    let URL = EMERGENCY_ITEMS_ENDPOINT;
    URL += `?offSet=${emergencyItemState.filter.offSet}`;
    URL += `&limit=${emergencyItemState.filter.limit}`;
    URL += `&query=${emergencyItemState.filter.query}`;

    const res = yield httpGet(URL);

    if (res.status !== 200) {
      yield put(apiError(res.data.error));
      return false;
    }

    yield put(setEmergencyItems(res.data.records));
    yield put(
      setEmergencyItemFilter({
        field: 'pages',
        value: res.data.pages,
      })
    );
  } catch (error) {
    yield put(apiError(error));
  }
}

function* addEmergencyItem({payload: {history}}) {
  try {
    const emergencyItemState = yield select(getEmergencyItem);
    const emergencyItem = emergencyItemState.emergencyItem;
    const res = yield httpPost(EMERGENCY_ITEMS_ENDPOINT, emergencyItem);
    console.log(res);
    if (res.status !== 200) {
      yield put(apiError(res.data.error));
      return false;
    }

    yield put(emptyEmergencyItem());
    history.push('/emergencyItems/list');
    successToaster('Record added', 'Success');
  } catch (error) {
    yield put(apiError(error));
  }
}

function* deleteEmergencyItem({ payload: { id } }) {
  try {
    const res = yield httpDelete(`${EMERGENCY_ITEMS_ENDPOINT}/${id}`);
    if (res.status !== 200) {
      yield put(apiError(res.data.error));
      return false;
    }
    console.log('shoud run');
    yield put(fetchEmergencyItems());
  } catch (error) {
    yield put(apiError(error));
  }
}

function* editEmergencyItem({ payload: { id } }) {
  try {
    const res = yield httpGet(`${EMERGENCY_ITEMS_ENDPOINT}/${id}`);
    if (res.status !== 200) {
      yield put(apiError(res.data.error));
      return false;
    }

    yield put(setEmergencyItem({ field: 'item_name', value: res.data.item_name }));
    yield put(
      setEmergencyItem({ field: 'description', value: res.data.description })
    );
    yield put(setEmergencyItem({ field: 'amount', value: res.data.amount }));
    yield put(setEmergencyItem({ field: 'unit', value: res.data.unit }));
    yield put(setEmergencyItem({ field: 'purchased_date', value: res.data.purchased_date }));
    yield put(setEmergencyItem({ field: 'cost', value: res.data.cost }));
    yield put(setEmergencyItem({ field: 'product_id', value: res.data.product_id }));

  } catch (error) {
    yield put(apiError(error));
  }
}

function* updateEmergencyItem({ payload: {history,  id } }) {
  try {
    const emergencyItemState = yield select(getEmergencyItem);
    const emergencyItem = emergencyItemState.emergencyItem;
    const res = yield httpPut(`${EMERGENCY_ITEMS_ENDPOINT}/${id}`, emergencyItem);
    console.log(res);
    if (res.status !== 200) {
      yield put(apiError(res.data.error));
      return false;
    }

    yield put(emptyEmergencyItem());
    history.push('/emergencyItems/list');
    successToaster('Record updated', 'Success');
  } catch (error) {
    yield put(apiError(error));
  }
}

const getEmergencyItem = (state) => state.EmergencyItem;

export function* watchGetEmergencyItems() {
  yield takeEvery(GET_EMERGENCY_ITEMS, getEmergencyItems);
}

export function* watchAddEmergencyItem() {
  yield takeEvery(ADD_EMERGENCY_ITEM, addEmergencyItem);
}

export function* watchDeleteEmergencyItem() {
  yield takeEvery(DELETE_EMERGENCY_ITEM, deleteEmergencyItem);
}

export function* watchEditEmergencyItem() {
  yield takeEvery(EDIT_EMERGENCY_ITEM, editEmergencyItem);
}

export function* watchUpdateEmergencyItem() {
  yield takeEvery(UPDATE_EMERGENCY_ITEM, updateEmergencyItem);
}

function* emergencyItemSaga() {
  yield all([
    fork(watchGetEmergencyItems),
    fork(watchAddEmergencyItem),
    fork(watchDeleteEmergencyItem),
    fork(watchEditEmergencyItem),
    fork(watchUpdateEmergencyItem)
  ]);
}

export default emergencyItemSaga;
