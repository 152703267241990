import { takeEvery, fork, put, all, call, select } from 'redux-saga/effects';
import {
  GET_PRODUCT_CATEGORIES,
  ADD_PRODUCT_CATEGORY,
  DELETE_PRODUCT_CATEGORY,
  EDIT_PRODUCT_CATEGORY,
  UPDATE_PRODUCT_CATEGORY,
  GET_PRODUCT_CATEGORIES_LIST
} from './actionTypes';
import {
  setProductCategoriesList,
  setProductCategories,
  emptyProductCategory,
  setProductCategory,
  apiError,
  getProductCategories as fetchProductCategories,
  getProductCategoriesList as fetchProductCategoriesList,
  setProductCategoryFilter,
} from './actions';
import { httpDelete, httpGet, httpPost, httpPut } from '../../utils/http';
import { PRODUCT_CATEGORIES_ENDPOINT } from '../../config/endPoints';
import { successToaster, errorToaster } from "../../components/Common/Toaster";
import {isEmpty} from "lodash"

function* getProductCategories() {
  try {
    const categoryState = yield select(getProductCategory);
    let URL = PRODUCT_CATEGORIES_ENDPOINT;
    URL += `?offSet=${categoryState.filter.offSet}`;
    URL += `&limit=${categoryState.filter.limit}`;
    URL += `&query=${categoryState.filter.query}`;

    const res = yield httpGet(URL);

    if (res.status !== 200) {
      yield put(apiError(res.data.error));
      return false;
    }

    yield put(setProductCategories(res.data.records));
    yield put(
      setProductCategoryFilter({
        field: 'pages',
        value: res.data.pages,
      })
    );
  } catch (error) {
    yield put(apiError(error));
  }
}

function* getProductCategoriesList() {
  try {
    let URL = `${PRODUCT_CATEGORIES_ENDPOINT}/get/list`;
    const res = yield httpGet(URL);

    if (res.status !== 200) {
      yield put(apiError(res.data.error));
      return false;
    }
    yield put(setProductCategoriesList(res.data));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* addProductCategory({payload: {history}}) {
  try {
    const categoryState = yield select(getProductCategory);
    const category = categoryState.productCategory;
    const res = yield httpPost(PRODUCT_CATEGORIES_ENDPOINT, category);
    console.log(res);
    if (res.status !== 200) {
      yield put(apiError(res.data.error));
      return false;
    }

    yield put(emptyProductCategory());
    if(!isEmpty(history)) history.push('/categories/list');
    successToaster('Record added.', 'Success');
  } catch (error) {
    yield put(apiError(error));
  }
}

function* deleteProductCategory({ payload: { id } }) {
  try {
    const res = yield httpDelete(`${PRODUCT_CATEGORIES_ENDPOINT}/${id}`);
    if (res.status !== 200) {
      yield put(apiError(res.data.error));
      return false;
    }
    console.log('shoud run');
    yield put(fetchProductCategories());
    yield put(fetchProductCategoriesList());
  } catch (error) {
    yield put(apiError(error));
  }
}

function* editProductCategory({ payload: { id } }) {
  try {
    const res = yield httpGet(`${PRODUCT_CATEGORIES_ENDPOINT}/${id}`);
    if (res.status !== 200) {
      yield put(apiError(res.data.error));
      return false;
    }

    yield put(setProductCategory({ field: 'category_name', value: res.data.category_name }));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* updateProductCategory({ payload: { history, id } }) {
  try {
    const categoryState = yield select(getProductCategory);
    const category = categoryState.productCategory;
    const res = yield httpPut(`${PRODUCT_CATEGORIES_ENDPOINT}/${id}`, category);
    console.log(res);
    if (res.status !== 200) {
      yield put(apiError(res.data.error));
      return false;
    }

    yield put(emptyProductCategory());
    if(!isEmpty(history)) history.push('/categories/list');
    successToaster('Record updated', 'Success');
  } catch (error) {
    yield put(apiError(error));
  }
}

const getProductCategory = (state) => state.ProductCategory;

export function* watchGetProductCategories() {
  yield takeEvery(GET_PRODUCT_CATEGORIES, getProductCategories);
}

export function* watchGetProductCategoriesList() {
  yield takeEvery(GET_PRODUCT_CATEGORIES_LIST, getProductCategoriesList);
}

export function* watchAddProductCategory() {
  yield takeEvery(ADD_PRODUCT_CATEGORY, addProductCategory);
}

export function* watchDeleteProdcutCategory() {
  yield takeEvery(DELETE_PRODUCT_CATEGORY, deleteProductCategory);
}

export function* watchEditProductCategory() {
  yield takeEvery(EDIT_PRODUCT_CATEGORY, editProductCategory);
}

export function* watchUpdateProductCategory() {
  yield takeEvery(UPDATE_PRODUCT_CATEGORY, updateProductCategory);
}

function* productCategorySaga() {
  yield all([
    fork(watchGetProductCategories),
    fork(watchAddProductCategory),
    fork(watchDeleteProdcutCategory),
    fork(watchEditProductCategory),
    fork(watchUpdateProductCategory),
    fork(watchGetProductCategoriesList)
  ]);
}

export default productCategorySaga;
