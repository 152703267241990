import {
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_ERROR,
  CODE_SENT_ON_EMAIL,
  VERIFY_CODE_SUCCESS,
  VERIFY_CODE_FAILED
} from "./actionTypes";

const initialState = {
  forgetSuccessMsg: null,
  forgetError: null,
  codeSent: false,
  codeVerificationFailed: null,
  codeVerified: false
};

const forgetPassword = (state = initialState, action) => {
  switch (action.type) {
    case FORGET_PASSWORD:
      state = {
        ...state,
        forgetSuccessMsg: null,
        forgetError: null,
      };
      break;
    case FORGET_PASSWORD_SUCCESS:
      state = {
        ...state,
        forgetSuccessMsg: action.payload,
      };
      break;
    case FORGET_PASSWORD_ERROR:
      state = { ...state, forgetError: action.payload };
      break;
    case CODE_SENT_ON_EMAIL:
      state = { ...state, codeSent: action.payload };
      break;
    case VERIFY_CODE_FAILED:
      state = { ...state, codeVerificationFailed: action.payload };
      break;
    case VERIFY_CODE_SUCCESS:
      state = { ...state, codeVerified: action.payload };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default forgetPassword;
