import {
  GET_EMERGENCY_ITEMS,
  SET_EMERGENCY_ITEMS,
  API_ERROR,
  SET_EMERGENCY_ITEM,
  SET_EMERGENCY_ITEM_FILTER,
  EMPTY_EMERGENCY_ITEM
} from './actionTypes';
import moment from "moment";

const initialState = {
  emergencyItems: [],
  filter: {
    offSet: 0,
    limit: 10,
    pages: 0,
    currentPage: 1,
    query: ''
  },
  emergencyItem: {
    item_name: '',
    description: '',
    amount: 0,
    unit: '',
    purchased_date: moment().format('YYYY-MM-DD'),
    cost: '',
    product_id: ''
  },
  error: '',
  loading: false,
};

const emergencyItem = (state = initialState, action) => {
  switch (action.type) {
    case GET_EMERGENCY_ITEMS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case SET_EMERGENCY_ITEMS:
      state = {
        ...state,
        emergencyItems: action.payload,
        loading: false,
      };
      break;
    case SET_EMERGENCY_ITEM:
      state = {
        ...state,
        emergencyItem: {
          ...state.emergencyItem,
          [action.payload.field]: action.payload.value,
        },
      };
      break;
    case EMPTY_EMERGENCY_ITEM:
      state = {
        ...state,
        emergencyItem: {
          ...state.emergencyItem,
          item_name: '',
          description: '',
          amount: '',
          unit: '',
          purchased_date: '',
          cost: '',
          product_id: ''
        },
      };
      break;
      case SET_EMERGENCY_ITEM_FILTER:
        state = {
          ...state,
          filter: {
            ...state.filter,
            [action.payload.field]: action.payload.value,
          },
        };
        break;
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default emergencyItem;
