import {
  GET_ORDERS,
  SET_ORDERS,
  API_ERROR,
  SET_ORDER,
  SET_ORDER_FILTER,
  EMPTY_ORDER,
  GET_SAVED_ORDERS,
  SET_SAVED_ORDERS,
  SET_SAVED_ORDER_FILTER
} from './actionTypes';

const initialState = {
  orders: [],
  savedOrders: [],
  savedOrdersFilter: {
    offSet: 0,
    limit: 10,
    pages: 0,
    currentPage: 1,
    query: ''
  },
  filter: {
    offSet: 0,
    limit: 10,
    pages: 0,
    currentPage: 1,
    query: ''
  },
  order: {
    order_name: '',
    order_abbreviation: '',
  },
  error: '',
  loading: false,
};

const order = (state = initialState, action) => {
  switch (action.type) {
    case GET_ORDERS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case SET_ORDERS:
      state = {
        ...state,
        orders: action.payload,
        loading: false,
      };
      break;
    case SET_ORDER:
      state = {
        ...state,
        order: {
          ...state.order,
          [action.payload.field]: action.payload.value,
        },
      };
      break;
    case EMPTY_ORDER:
      state = {
        ...state,
        order: {
          ...state.order,
          order_name: '',
          order_abbreviation: '',
        },
      };
      break;
    case SET_ORDER_FILTER:
      state = {
        ...state,
        filter: {
          ...state.filter,
          [action.payload.field]: action.payload.value,
        },
      };
      break;
    case GET_SAVED_ORDERS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case SET_SAVED_ORDERS:
      state = {
        ...state,
        savedOrders: action.payload,
        loading: false,
      };
      break;
    case SET_SAVED_ORDER_FILTER:
      state = {
        ...state,
        savedOrdersFilter: {
          ...state.savedOrdersFilter,
          [action.payload.field]: action.payload.value,
        },
      };
      break;
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default order;
