import {
  GET_IDEAL_PRODUCT_COSTS,
  SET_IDEAL_PRODUCT_COSTS,
  API_ERROR,
  SET_IDEAL_PRODUCT_COST,
  SET_IDEAL_PRODUCT_COST_FILTER,
  EMPTY_IDEAL_PRODUCT_COST,
  SET_IDEAL_PRODUCT_COSTS_LIST,
  ADD_IDEAL_PRODUCT_COST_SORT
} from "./actionTypes";

const initialState = {
  idealProductCostsList: [],
  idealProductCosts: [],
  filter: {
    offSet: 0,
    limit: 10,
    pages: 0,
    currentPage: 1,
    query: "",
  },
  idealProductCost: {
    is_sized: false,
    size_number: 1,
    is_suggested_quantity: false,
    quantity_number: [],
    ideal_product_cost_name: '',
    ideal_product_cost_description: '',
    fk_product_category_id: '',
    fk_product_sub_category_id: '',
    plu: '',
    url: '',
    ideal_cost_goods: [''],
    current_menu_net_price: [''],
    ideal_product_cost_details: [
      {
        is_procedure: 0,
        product: '',
        procedure: '',
        unit: '',
        sizes: [
          {
            name: 'Size1',
            value: ''
          }
        ],
        cost: ''
      }
    ],
    ideal_product_cost_instructions: [
      {
       step: '',
       time: [
        {
          hours: '',
          minutes: '',
          seconds: ''
        }
       ]
      }
    ],
    image: ''
  },
  error: "",
  loading: false,
  sorts: [
    {
      name: '',
      direction: ''
    }
  ]
};

const idealProductCost = (state = initialState, action) => {
  switch (action.type) {
    case GET_IDEAL_PRODUCT_COSTS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case SET_IDEAL_PRODUCT_COSTS:
      state = {
        ...state,
        idealProductCosts: action.payload,
        loading: false,
      };
      break;
    case SET_IDEAL_PRODUCT_COST:
      state = {
        ...state,
        idealProductCost: {
          ...state.idealProductCost,
          [action.payload.field]: action.payload.value,
        },
      };
      break;
    case EMPTY_IDEAL_PRODUCT_COST:
      state = {
        ...state,
        idealProductCost: {
          ...state.idealProductCost,
            is_sized: false,
            size_number: 1,
            ideal_product_cost_name: '',
            fk_product_category_id: '',
            fk_product_sub_category_id: '',
            is_suggested_quantity: false,
            quantity_number: [],
            ideal_product_cost_description: '',
            fk_product_category_id: '',
            fk_product_sub_category_id: '',
            plu: '',
            url: '',
            ideal_cost_goods: [''],
            current_menu_net_price: [''],
            ideal_product_cost_details: [
              {
                is_procedure: 0,
                product: '',
                procedure: '',
                unit: '',
                sizes: [
                  {
                    name: 'Size1',
                    value: ''
                  }
                ],
                cost: ''
              }
            ],
            ideal_product_cost_instructions: [
              {
               step: '',
               time: [
                {
                  hours: '',
                  minutes: '',
                  seconds: ''
                }
               ]
              }
            ],
            image: ''
        },
      };
      break;
    case SET_IDEAL_PRODUCT_COST_FILTER:
      state = {
        ...state,
        filter: {
          ...state.filter,
          [action.payload.field]: action.payload.value,
        },
      };
      break;
    case ADD_IDEAL_PRODUCT_COST_SORT:
      state = {
        ...state,
       sorts: action.payload
      }
      break; 
    case SET_IDEAL_PRODUCT_COSTS_LIST:
      state = {
        ...state,
        idealProductCostsList: action.payload,
      };
      break;
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default idealProductCost;
