import React from 'react';
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  FormGroup,
} from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import {
  getBusinessLocations,
  editBusinessLocation,
  deleteBusinessLocation,
  setBusinessLocationFilter,
  setBusinessLocations
} from '../../../store/actions';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import Loader from '../../../components/Common/Loader';
import CustomPagination from '../../../components/Common/Pagination';
import {successToaster, errorToaster} from '../../../components/Common/Toaster';
import './style.css';
import { Plans } from '../../../config/plans';
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import arrayMove from "array-move";
import { BUSINESS_LOCATIONS_ENDPOINT } from '../../../config/endPoints';
import { httpPost } from '../../../utils/http';

const DragHandle = sortableHandle(() => (
  <i
    style={{ cursor: "pointer" }}
    className="fas fa-align-justify wookai_orange"
  ></i>
));

const SortableContainer = sortableContainer(({ children }) => {
  return <tbody>{children}</tbody>;
});

const SortableItem = sortableElement(
  ({ businessLocationKey, value, wookaiUserObject, id, changeLocation }) => (
    <React.Fragment>
      <tr key={businessLocationKey}>
      {
        wookaiUserObject.user_type == 'Client' && 
        <td>
          <DragHandle />
        </td>
      }
        
        <td>
          <input
            // className='form-control'
            value={value.id}
            name='location'
            type='radio'
            checked={value.id == id}
            onChange={(event) =>
              changeLocation(event.target.value)
            }
          />
        </td>
        <td>{value.business_name}</td>
        <td>
          {
            wookaiUserObject.user_type != 'Rep-Level' &&
            <div className='button-items'>
              <Link
                to={`/businessLocations/edit/${value.id}`}
              >
                <Button
                  color='secondary'
                  className='btn btn-secondary waves-effect'
                >
                  Edit
                </Button>
              </Link>

            </div>
          }
        </td>
      </tr>
    </React.Fragment>
  )
);

class SelectBusinessLocation extends React.Component {
  /**
   * component state
   */
  state = {
    id: '',
    wookaiUserObject: JSON.parse(localStorage.getItem('wookaiUserObject')),
    componentLoader: false
  };

  /**
   * Component Did mount
   */
  componentDidMount() {
    const { wookaiUserObject } = this.state;
    this.props.getBusinessLocations();
    const seletedBusinessLocation = localStorage.getItem('businessLocation');
    if (seletedBusinessLocation != undefined) {
      this.setState({
        id: seletedBusinessLocation,
      });
    }
    
    if(wookaiUserObject.user_type === 'Rep-Level'){
      setTimeout(() => {
        if(this.props.businessLocations.length == 0){
          errorToaster('You dnt have any business location assigned.', 'Error');
        }
      }, 1000);
    }

  }

  /**
   * pagination Handle click
   */
  PaginationHandleClick = (offSet, page) => {
    console.log(offSet);
    console.log(page);
    console.log(this.props);

    this.props.setBusinessLocationFilter({
      field: 'currentPage',
      value: page,
    });

    this.props.setBusinessLocationFilter({
      field: 'offSet',
      value: offSet,
    });

    this.props.getBusinessLocations();
  };

  /**
   * set business location in local storage
   */
  changeBusinessLocation = () => {
    const { id, wookaiUserObject } = this.state;
    const { businessLocations } = this.props;

    if (id == '') {
      alert('Select Business Lcoation');
      return false;
    }

     //add business location object in local storage
     const selectedBusinessLocation = businessLocations.find(o => o.id == id);
     localStorage.setItem('businessLocationObject', JSON.stringify(selectedBusinessLocation));

    localStorage.setItem('businessLocation', id);
    if(wookaiUserObject.user_type == 'Rep-Level') {
      this.props.history.push('/repItems/list');
    } else {
      this.props.history.push('/dashboard');
    }
    
    successToaster('Location selected successfully', 'Success');
  };


  renderToAddBusinessLocation = () => {
    const {
      businessLocations
    } = this.props;

    let selectedBusinessLocationObject =  JSON.parse(localStorage.getItem('businessLocationObject'));

    if((selectedBusinessLocationObject != null) && 
      ((selectedBusinessLocationObject.stripe_plan_id && selectedBusinessLocationObject.stripe_plan_id == Plans.WOOKAI_SUPPLIER_PRICE_CHECKER) 
      && businessLocations.length == 1)){
        errorToaster('You cannot add more Business locations, Please change your Plan.', 'Error');
        return false;
      }
      else{
        this.props.history.push('/businessLocations/add');
      }
  }

    /**
   * Sort end change
   */
  onSortEnd = ({ oldIndex, newIndex }) => {
    const { businessLocations } = this.props;

    let clone = businessLocations.slice();
    clone = arrayMove(clone, oldIndex, newIndex);

    this.props.setBusinessLocations(clone);
  };

    /**
   * Save sort changes
   */
  saveSortChanges = async () => {
    const { businessLocations } = this.props;
    this.setState({
      componentLoader: true,
    });

    await httpPost(`${BUSINESS_LOCATIONS_ENDPOINT}/update/orderBy`, {
      sort: businessLocations,
    });
    this.setState({
      componentLoader: false,
    });

    successToaster("Sort saved", "Success");
    this.props.getBusinessLocations();
  };

  changeLocation = (id) => {
    this.setState({
      id,
    })
  }

  /**
   * Renders component
   */
  render() {
    const {
      businessLocations,
      filter: { offSet, limit, pages, currentPage, query },
      loading,
    } = this.props;

    const { id, wookaiUserObject } = this.state;

    return (
      <React.Fragment>
        <div className='page-content'>
          <div className='container-fluid'>
            <Breadcrumbs
              title='Business Locations'
              breadcrumbItem='Select Business Location'
            />
            {loading && <Loader />}
            <Row>
              <Col md={12}>
                <Card>
                  <CardBody>
                    {/* <CardTitle>Basic example</CardTitle>
                    <CardSubtitle className='mb-3'>
                      For basic styling—light padding and only horizontal
                      dividers—add the base className <code>.table</code> to any
                      <code>&lt;table&gt;</code>.
                    </CardSubtitle> */}
                    <Row>
                      <Col md='4'>
                        <FormGroup>
                          <input
                            className='form-control'
                            value={query}
                            placeholder='Search'
                            name='query'
                            type='text'
                            onChange={(event) =>
                              this.props.setBusinessLocationFilter({
                                field: event.target.name,
                                value: event.target.value,
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col md='3'>
                        <FormGroup>
                          <Button
                            onClick={() => this.props.getBusinessLocations()}
                            color='primary'
                            className='btn btn-primary waves-effect w100 addbtn'
                          >
                            Search
                          </Button>
                        </FormGroup>
                      </Col>
                      {
                        wookaiUserObject.user_type == 'Client' &&
                        <Col md='2'>
                          <FormGroup className='float-right w100 addbtn'>
                              <Button
                                onClick={() => this.saveSortChanges()}
                                color='primary'
                                className='btn btn-primary waves-effect orange-btn'
                                >
                                Save Sort
                              </Button>
                          </FormGroup>
                        </Col>
                      } 
                      {
                        wookaiUserObject.user_type != 'Rep-Level' &&
                        <Col md='3'>
                          <FormGroup className='float-right w100 addbtn'>
                              <Button
                                onClick={() => this.renderToAddBusinessLocation()}
                                color='primary'
                                className='btn btn-primary waves-effect w100 addbtn'
                              >
                                Add New
                              </Button>
                          </FormGroup>
                        </Col>
                      } 
                    </Row>
                    <div className='table-responsive businessLocationWrap'>
                      <Table className='table table-striped mb-0 table'>
                        <thead>
                          <tr>
                            {
                              wookaiUserObject.user_type == 'Client' && 
                              <th>Sort</th>
                            }
                            <th>Select</th>
                            <th>Business Location</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <SortableContainer
                          onSortEnd={this.onSortEnd}
                          useWindowAsScrollContainer={true}
                          useDragHandle={true}
                        >
                          {businessLocations.length > 0 &&
                            !loading &&
                            businessLocations.map(
                              (businessLocation, businessLocationKey) => (
                                <SortableItem
                                  key={`item-${businessLocationKey}`}
                                  index={businessLocationKey}
                                  value={businessLocation}
                                  businessLocationKey={businessLocationKey}
                                  wookaiUserObject={wookaiUserObject}
                                  id={id}
                                  changeLocation={this.changeLocation}
                                />
                              )
                            )}
                        </SortableContainer>
                      </Table>
                    </div>
                    <Col md='12'>
                      <FormGroup className='float-right mb-0'>
                        <Button
                          onClick={() => this.changeBusinessLocation()}
                          color='primary'
                          className='btn btn-primary waves-effect'
                        >
                          Manage
                        </Button>
                      </FormGroup>
                    </Col>

                    {/* {businessLocations.length > 0 && (
                      <FormGroup className='float-right'>
                        <CustomPagination
                          offSet={offSet}
                          limit={limit}
                          pages={pages}
                          currentPage={currentPage}
                          PaginationHandleClick={this.PaginationHandleClick}
                        />
                      </FormGroup>
                    )} */}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { businessLocations, filter, loading } = state.BusinessLocation;
  return { businessLocations, filter, loading };
};

export default withRouter(
  connect(mapStatetoProps, {
    getBusinessLocations,
    deleteBusinessLocation,
    editBusinessLocation,
    setBusinessLocationFilter,
    setBusinessLocations
  })(SelectBusinessLocation)
);
