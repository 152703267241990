import {
  GET_PREP_PLAN_REPORTS,
  SET_PREP_PLAN_REPORTS,
  API_ERROR,
  SET_PREP_PLAN_REPORT_FILTER,
  SET_PREP_PLAN_REPORTS_LIST,
} from './actionTypes';

const initialState = {
  prepPlanReportsList: [],
  prepPlanReports: [],
  filter: {
    offSet: 0,
    limit: 10,
    pages: 0,
    currentPage: 1,
    query: ''
  },
  error: '',
  loading: false,
};

const prepPlanReport = (state = initialState, action) => {
  switch (action.type) {
    case GET_PREP_PLAN_REPORTS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case SET_PREP_PLAN_REPORTS:
      state = {
        ...state,
        prepPlanReports: action.payload,
        loading: false,
      };
      break;
      case SET_PREP_PLAN_REPORT_FILTER:
        state = {
          ...state,
          filter: {
            ...state.filter,
            [action.payload.field]: action.payload.value,
          },
        };
        break;
      case SET_PREP_PLAN_REPORTS_LIST:
        state = {
          ...state,
          prepPlanReportsList: action.payload
        };
      break;
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default prepPlanReport;
