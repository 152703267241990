import {
  GET_EMERGENCY_ITEMS,
  SET_EMERGENCY_ITEMS,
  SET_EMERGENCY_ITEM,
  ADD_EMERGENCY_ITEM,
  EMPTY_EMERGENCY_ITEM,
  DELETE_EMERGENCY_ITEM,
  EDIT_EMERGENCY_ITEM,
  UPDATE_EMERGENCY_ITEM,
  SET_EMERGENCY_ITEM_FILTER,
  API_ERROR,
} from "./actionTypes";

export const getEmergencyItems = () => {
  return {
    type: GET_EMERGENCY_ITEMS,
    payload: {},
  };
};

export const setEmergencyItems = (data) => {
  return {
    type: SET_EMERGENCY_ITEMS,
    payload: data,
  };
};

export const setEmergencyItem = (data) => {
  return {
    type: SET_EMERGENCY_ITEM,
    payload: data,
  };
};

export const addEmergencyItem = (history) => {
  return {
    type: ADD_EMERGENCY_ITEM,
    payload: { history },
  };
};

export const emptyEmergencyItem = () => {
  return {
    type: EMPTY_EMERGENCY_ITEM,
    payload: {},
  };
};

export const deleteEmergencyItem = (id) => {
  return {
    type: DELETE_EMERGENCY_ITEM,
    payload: { id },
  };
};

export const editEmergencyItem = (id) => {
  return {
    type: EDIT_EMERGENCY_ITEM,
    payload: { id },
  };
};

export const updateEmergencyItem = (history, id) => {
  return {
    type: UPDATE_EMERGENCY_ITEM,
    payload: { history, id },
  };
};

export const setEmergencyItemFilter = (data) => {
  return {
    type: SET_EMERGENCY_ITEM_FILTER,
    payload: data,
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};
