export const GET_PRODUCT_CATEGORIES = 'GET_PRODUCT_CATEGORIES';
export const SET_PRODUCT_CATEGORIES = 'SET_PRODUCT_CATEGORIES';
export const API_ERROR = 'LOGIN_API_ERROR';
export const SET_PRODUCT_CATEGORY = 'SET_PRODUCT_CATEGORY';
export const ADD_PRODUCT_CATEGORY = 'ADD_PRODUCT_CATEGORY';
export const EMPTY_PRODUCT_CATEGORY = 'EMPTY_PRODUCT_CATEGORY';
export const DELETE_PRODUCT_CATEGORY = 'DELETE_PRODCUT_CATEGORY';
export const EDIT_PRODUCT_CATEGORY = 'EDIT_PRODUCT_CATEGORY';
export const UPDATE_PRODUCT_CATEGORY = 'UPDATE_PRODUCT_CATEGORY';
export const SET_PRODUCT_CATEGORY_FILTER = 'SET_PRODUCT_CATEGORY_FILTER';
export const GET_PRODUCT_CATEGORIES_LIST = 'GET_PRODUCT_CATEGORIES_LIST';
export const SET_PRODUCT_CATEGORIES_LIST = 'SET_PRODUCT_CATEGORIES_LIST';