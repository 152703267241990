import {
  GET_ENQUIRIES,
  SET_ENQUIRIES,
  SET_ENQUIRY_FILTER,
  API_ERROR,
} from "./actionTypes";

export const getEnquiries = () => {
  return {
    type: GET_ENQUIRIES,
    payload: {},
  };
};

export const setEnquiries = (data) => {
  return {
    type: SET_ENQUIRIES,
    payload: data,
  };
};

export const setEnquiryFilter = (data) => {
  return {
    type: SET_ENQUIRY_FILTER,
    payload: data,
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};
