export const GET_CONVERSIONS = 'GET_CONVERSIONS';
export const SET_CONVERSIONS = 'SET_CONVERSIONS';
export const API_ERROR = 'LOGIN_API_ERROR';
export const SET_CONVERSION = 'SET_CONVERSION';
export const ADD_CONVERSION = 'ADD_CONVERSION';
export const EMPTY_CONVERSION = 'EMPTY_CONVERSION';
export const DELETE_CONVERSION = 'DELETE_CONVERSION';
export const EDIT_CONVERSION = 'EDIT_CONVERSION';
export const UPDATE_CONVERSION = 'UPDATE_CONVERSION';
export const SET_CONVERSION_FILTER = 'SET_CONVERSION_FILTER';
export const GET_CONVERSIONS_LIST = 'GET_CONVERSIONS_LIST';
export const SET_CONVERSIONS_LIST = 'SET_CONVERSIONS_LIST';