import {
  GET_PREP_PLANS,
  SET_PREP_PLANS,
  API_ERROR,
  SET_PREP_PLAN,
  SET_PREP_PLAN_FILTER,
  EMPTY_PREP_PLAN,
  SET_PREP_PLANS_LIST,
} from "./actionTypes";

const initialState = {
  prepPlansList: [],
  prepPlans: [],
  filter: {
    offSet: 0,
    limit: 10,
    pages: 0,
    currentPage: 1,
    query: "",
  },
  prepPlan: {
    prep_plan_name: "",
    prep_plan_description: "",
    prep_par_levels: [
      {
        day: "monday",
        value: "",
      },
      {
        day: "tuesday",
        value: "",
      },
      {
        day: "wednesday",
        value: "",
      },
      {
        day: "thursday",
        value: "",
      },
      {
        day: "friday",
        value: "",
      },
      {
        day: "saturday",
        value: "",
      },
      {
        day: "sunday",
        value: "",
      },
    ],
    prep_plan_details: [
      {
        batch_processing: 0,
        category_name: "",
        details: [
          {
            check_box: 0,
            receipe_id: "",
            receipe_description: '',
            receipe_data: {
              total: '',
              cost_per_ru_procedure: ''
            },
            batch: '',
            days_out: '',
            process_unit: "",
            process_units_in_par: "",
            par_unit: "",
            cost_per_par_unit: "",
            par_units_per: "",
            days: [
              {
                day: "monday",
                min: "",
                max: "",
                flat: "",
              },
              {
                day: "tuesday",
                min: "",
                max: "",
                flat: "",
              },
              {
                day: "wednesday",
                min: "",
                max: "",
                flat: "",
              },
              {
                day: "thursday",
                min: "",
                max: "",
                flat: "",
              },
              {
                day: "friday",
                min: "",
                max: "",
                flat: "",
              },
              {
                day: "saturday",
                min: "",
                max: "",
                flat: "",
              },
              {
                day: "sunday",
                min: "",
                max: "",
                flat: "",
              },
            ],
          },
        ],
      },
    ],
  },
  error: "",
  loading: false,
};

const receipe = (state = initialState, action) => {
  switch (action.type) {
    case GET_PREP_PLANS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case SET_PREP_PLANS:
      state = {
        ...state,
        prepPlans: action.payload,
        loading: false,
      };
      break;
    case SET_PREP_PLAN:
      state = {
        ...state,
        prepPlan: {
          ...state.prepPlan,
          [action.payload.field]: action.payload.value,
        },
      };
      break;
    case EMPTY_PREP_PLAN:
      state = {
        ...state,
        prepPlan: {
          ...state.prepPlan,
          prep_plan_name: "",
          prep_plan_description: "",
          prep_par_levels: [
            {
              day: "monday",
              value: "",
            },
            {
              day: "tuesday",
              value: "",
            },
            {
              day: "wednesday",
              value: "",
            },
            {
              day: "thursday",
              value: "",
            },
            {
              day: "friday",
              value: "",
            },
            {
              day: "saturday",
              value: "",
            },
            {
              day: "sunday",
              value: "",
            },
          ],
          prep_plan_details: [
            {
              batch_processing: 0,
              category_name: "",
              details: [
                {
                  check_box: 0,
                  receipe_id: "",
                  receipe_description: '',
                  receipe_data: {
                    total: '',
                    cost_per_ru_procedure: ''
                  },
                  batch: '',
                  days_out: '',
                  process_unit: "",
                  process_units_in_par: "",
                  par_unit: "",
                  cost_per_par_unit: "",
                  par_units_per: "",
                  days: [
                    {
                      day: "monday",
                      min: "",
                      max: "",
                      flat: "",
                    },
                    {
                      day: "tuesday",
                      min: "",
                      max: "",
                      flat: "",
                    },
                    {
                      day: "wednesday",
                      min: "",
                      max: "",
                      flat: "",
                    },
                    {
                      day: "thursday",
                      min: "",
                      max: "",
                      flat: "",
                    },
                    {
                      day: "friday",
                      min: "",
                      max: "",
                      flat: "",
                    },
                    {
                      day: "saturday",
                      min: "",
                      max: "",
                      flat: "",
                    },
                    {
                      day: "sunday",
                      min: "",
                      max: "",
                      flat: "",
                    },
                  ],
                },
              ],
            },
          ],
        },
      };
      break;
    case SET_PREP_PLAN_FILTER:
      state = {
        ...state,
        filter: {
          ...state.filter,
          [action.payload.field]: action.payload.value,
        },
      };
      break;
    case SET_PREP_PLANS_LIST:
      state = {
        ...state,
        prepPlansList: action.payload,
      };
      break;
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default receipe;
