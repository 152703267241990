import { takeEvery, fork, put, all, call, select } from 'redux-saga/effects';

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from './actionTypes';
import { loginSuccess, logoutUserSuccess, apiError, agreement } from './actions';

//Include Both Helper File with needed methods
import { getFirebaseBackend } from '../../../helpers/firebase_helper';
import {httpPost} from '../../../utils/http';
import {AUTH_ENDPOINT} from '../../../config/endPoints';
import {successToaster} from '../../../components/Common/Toaster';
import { END_USER_AGREEMENT_VERSION } from '../../../config/securityKeys';

const fireBaseBackend = getFirebaseBackend();


function* loginUser({ payload: { user, history } }) {
    try {
        const res = yield httpPost(`${AUTH_ENDPOINT}/login`, user);
        if (res.status !== 200){
            yield put(apiError(res.data.error));
            return false;
        }

        yield put(apiError(''));
        localStorage.setItem('token', res.data.token);
        localStorage.setItem('wookaiUserObject', JSON.stringify(res.data.userObject));


        //User Agreement check
        if(res.data.userObject.role != 'Admin'){
            let loginState = yield select(getLogin)
            if(!loginState.isAgree && res.data.userObject.eula_version != END_USER_AGREEMENT_VERSION){
                yield put(agreement(true));
                return false;
            }else{
                yield put(agreement(false));
            }
    
            if(res.data.userObject.eula_version != END_USER_AGREEMENT_VERSION){
                httpPost(`${AUTH_ENDPOINT}/updateEulaVersion`, {eulaVersion: END_USER_AGREEMENT_VERSION});
            }
        }

        yield put(loginSuccess(res.data.token));
        history.push('/dashboard');
        successToaster('You are logged in', 'Success.')
          
    } catch (error) {
        yield put(apiError(error));
    }
}

function* logoutUser({ payload: { history } }) {
    try {
         localStorage.removeItem("token");
         localStorage.removeItem("businessLocation");
         localStorage.removeItem("businessLocationObject");
         localStorage.removeItem("pendingAdditionalInfo");
         localStorage.removeItem("pendingParLevels");
         localStorage.removeItem("pendingUnits");
         localStorage.removeItem("wookaiUserObject");
         localStorage.removeItem("firstBusinessLocationObject");
         if(process.env.REACT_APP_DEFAULTAUTH === 'firebase')
         {
           const response = yield call(fireBaseBackend.logout);
           yield put(logoutUserSuccess(response));
         }
        history.push('/login');
        successToaster('You are logged out', 'Success.')
    } catch (error) {
        yield put(apiError(error));
    }
}

const getLogin = (state) => state.Login;


export function* watchUserLogin() {
    yield takeEvery(LOGIN_USER, loginUser)
}

export function* watchUserLogout() {
    yield takeEvery(LOGOUT_USER, logoutUser)
}

function* authSaga() {
    yield all([
        fork(watchUserLogin),
        fork(watchUserLogout),
    ]);
}

export default authSaga;