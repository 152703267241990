import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';

/**
 * Reload first time without lazy
 */
 import UiAlert from '../pages/Ui/UiAlert';
 import UiButtons from '../pages/Ui/UiButtons';
 import UiCards from '../pages/Ui/UiCards';
 import UiCarousel from '../pages/Ui/UiCarousel';
 import UiColors from '../pages/Ui/UiColors';
 import UiDropdown from '../pages/Ui/UiDropdown';
 import UiGeneral from '../pages/Ui/UiGeneral';
 import UiGrid from '../pages/Ui/UiGrid';
 import UiImages from '../pages/Ui/UiImages';
 import UiLightbox from '../pages/Ui/UiLightbox';
 import UiModal from '../pages/Ui/UiModal';
 import UiProgressbar from '../pages/Ui/UiProgressbar';
 import UiSweetAlert from '../pages/Ui/UiSweetAlert';
 import UiTabsAccordions from '../pages/Ui/UiTabsAccordions';
 import UiTypography from '../pages/Ui/UiTypography';
 import UiVideo from '../pages/Ui/UiVideo';
 import UiSessionTimeout from '../pages/Ui/UiSessionTimeout';
 import UiRating from '../pages/Ui/UiRating';
 import UiRangeSlider from '../pages/Ui/UiRangeSlider';
 import UiNotifications from '../pages/Ui/ui-notifications';
 import UiImageCropper from '../pages/Ui/ui-image-cropper';
 
 import IconBoxicons from '../pages/Icons/IconBoxicons';
 import IconDripicons from '../pages/Icons/IconDripicons';
 import IconMaterialdesign from '../pages/Icons/IconMaterialdesign';
 import IconFontawesome from '../pages/Icons/IconFontawesome';

  import BusinessLocationAdd from '../pages/Business-Locations/Add-Business-Location';
  import SelectBusinessLocation from '../pages/Business-Locations/Select-Business-Location';
  import Dashboard from '../pages/Dashboard/index';

/**
 * Manage Account
 */
// import AccountManage from '../pages/Account/Manage-Account';
const AccountManage = lazy(() => import('../pages/Account/Manage-Account'));

/**
 * User Routes
 */
// import Users from '../pages/Users';
// import UserAdd from '../pages/Users/Add-User';
const Users = lazy(() => import('../pages/Users'));
const UserAdd = lazy(() => import('../pages/Users/Add-User'));


/**
 * Assigned Reps
 */
// import AssignedReps from '../pages/Assigned-Reps';
// import AddAssignedRep from '../pages/Assigned-Reps/Add-Assigned-Rep';
const AssignedReps = lazy(() => import('../pages/Assigned-Reps'));
const AddAssignedRep = lazy(() => import('../pages/Assigned-Reps/Add-Assigned-Rep'));

/**
 * Client Routes
 */
// import Clients from '../pages/Clients';
// import ClientAdd from '../pages/Clients/Add-Client';
const Clients = lazy(() => import('../pages/Clients'));
const ClientAdd = lazy(() => import('../pages/Clients/Add-Client'));

/**
 * Client Routes
 */
// import SupplierReps from '../pages/Supplier-Reps';
// import SupplierRepAdd from '../pages/Supplier-Reps/Add-Supplier-Rep';
const SupplierReps = lazy(() => import('../pages/Supplier-Reps'));
const SupplierRepAdd = lazy(() => import('../pages/Supplier-Reps/Add-Supplier-Rep'));

/**
 * Unit Routes
 */
// import Units from '../pages/Units';
// import UnitAdd from '../pages/Units/Add-Unit';
const Units = lazy(() => import('../pages/Units'));
const UnitAdd = lazy(() => import('../pages/Units/Add-Unit'));

/**
 * Unit List Routes
 */
// import Conversions from '../pages/Conversions';
// import ConversionAdd from '../pages/Conversions/Add-Conversion';
const Conversions = lazy(() => import('../pages/Conversions'));
const ConversionAdd = lazy(() => import('../pages/Conversions/Add-Conversion'));

/**
 * Video Routes
 */
// import Videos from '../pages/Videos';
// import VideoAdd from '../pages/Videos/Add-Video';
const Videos = lazy(() => import('../pages/Videos'));
const VideoAdd = lazy(() => import('../pages/Videos/Add-Video'));

/**
 * Setting Routes
 */
// import SettingAdd from '../pages/Settings/Add-Setting';
const SettingAdd = lazy(() => import('../pages/Settings/Add-Setting'));


/**
 * Mass Emails
 */
// import MassEmails from '../pages/Mass-Emails';
// import MassEmailAdd from '../pages/Mass-Emails/Add-Mass-Email';
const MassEmails = lazy(() => import('../pages/Mass-Emails'));
const MassEmailAdd = lazy(() => import('../pages/Mass-Emails/Add-Mass-Email'));

/**
 * Canned Emails
 */
// import CannedEmails from '../pages/Canned-Emails';
// import CannedEmailAdd from '../pages/Canned-Emails/Add-Canned-Email';
const CannedEmails = lazy(() => import('../pages/Canned-Emails'));
const CannedEmailAdd = lazy(() => import('../pages/Canned-Emails/Add-Canned-Email'));

/**
 * Cms
 */
// import Cms from '../pages/Cms';
// import CmsAdd from '../pages/Cms/Add-Cms';
const Cms = lazy(() => import('../pages/Cms'));
const CmsAdd = lazy(() => import('../pages/Cms/Add-Cms'));

/**
 * Ads
 */
// import AdAdd from '../pages/Ads/Add-Ad';
const AdAdd = lazy(() => import('../pages/Ads/Add-Ad'));

/**
 * Mass Emails
 */
// import EmailContents from '../pages/Email-Contents';
// import EmailContentAdd from '../pages/Email-Contents/Add-Email-Content';
const EmailContents = lazy(() => import('../pages/Email-Contents'));
const EmailContentAdd = lazy(() => import('../pages/Email-Contents/Add-Email-Content'));

/**
 * Admins
 */
// import Admins from '../pages/Admins';
// import AdminAdd from '../pages/Admins/Add-Admin';
const Admins = lazy(() => import('../pages/Admins'));
const AdminAdd = lazy(() => import('../pages/Admins/Add-Admin'));

/**
 * BusinessProfiles
 */
// import BusinessProfiles from '../pages/Business-Profiles';
const BusinessProfiles = lazy(() => import('../pages/Business-Profiles'));


/**
 * DefaultProfiles
 */
// import DefaultProfiles from '../pages/Default-Profiles';
// import ManageProfile from '../pages/Default-Profiles/Manage-Profile';
const DefaultProfiles = lazy(() => import('../pages/Default-Profiles'));
const ManageProfile = lazy(() => import('../pages/Default-Profiles/Manage-Profile'));

/**
 * Vendors
 */
// import Vendors from '../pages/Vendors';
// import VendorAdd from '../pages/Vendors/Add-Vendor';
// import VendorAddNew from '../pages/Vendors/Add-Vendor/New-Vendor';
// import VendorAddWookai from '../pages/Vendors/Add-Vendor/Wookai-Vendor';
// import VendorRelations from '../pages/Vendors/Relations';
const Vendors = lazy(() => import('../pages/Vendors'));
const VendorAdd = lazy(() => import('../pages/Vendors/Add-Vendor'));
const VendorAddNew = lazy(() => import('../pages/Vendors/Add-Vendor/New-Vendor'));
const VendorAddWookai = lazy(() => import('../pages/Vendors/Add-Vendor/Wookai-Vendor'));
const VendorRelations = lazy(() => import('../pages/Vendors/Relations'));
const VendorLoginDetails = lazy(() => import('../pages/Vendors/Login-Details'));
const VendorPriceLogs  = lazy(() => import('../pages/Vendors/PriceLogs'));


const RepVendors = lazy(() => import('../pages/Rep-Vendors'));
const RepVendorRelations = lazy(() => import('../pages/Rep-Vendors/Relations'));


/**
 * Vendors
 */
// import WookaiVendors from '../pages/Wookai-Vendors';
// import WookaiVendorAdd from '../pages/Wookai-Vendors/Add-Wookai-Vendor';
// import UpdatWookaiVendorPrice from '../pages/Wookai-Vendors/Update-Wookai-Price';
const WookaiVendors = lazy(() => import('../pages/Wookai-Vendors'));
const WookaiVendorAdd = lazy(() => import('../pages/Wookai-Vendors/Add-Wookai-Vendor'));
const UpdatWookaiVendorPrice = lazy(() => import('../pages/Wookai-Vendors/Update-Wookai-Price'));
const UpdatWookaiVendorInvoiceMapping = lazy(() => import('../pages/Wookai-Vendors/Update-Invoice-Mapping'));

/**
 * Business Locations
 */
// import BusinessLocations from '../pages/Business-Locations';
// import BusinessLocationAdd from '../pages/Business-Locations/Add-Business-Location';
// import SelectBusinessLocation from '../pages/Business-Locations/Select-Business-Location';
const BusinessLocations = lazy(() => import('../pages/Business-Locations'));
// const BusinessLocationAdd = lazy(() => import('../pages/Business-Locations/Add-Business-Location'));
// const SelectBusinessLocation = lazy(() => import('../pages/Business-Locations/Select-Business-Location'));

/**
 * Item Locations
 */
// import ItemLocations from '../pages/Item-Locations';
// import ItemLocationAdd from '../pages/Item-Locations/Add-Item-Location';
const ItemLocations = lazy(() => import('../pages/Item-Locations'));
const ItemLocationAdd = lazy(() => import('../pages/Item-Locations/Add-Item-Location'));

/**
 * Categories
 */
// import Categories from '../pages/Categories';
// import CategoryAdd from '../pages/Categories/Add-Category';
const Categories = lazy(() => import('../pages/Categories'));
const CategoryAdd = lazy(() => import('../pages/Categories/Add-Category'));

/**
 * SubCategories
 */
// import SubCategories from '../pages/Sub-Categories';
// import SubCategoryAdd from '../pages/Sub-Categories/Add-Sub-Category';
const SubCategories = lazy(() => import('../pages/Sub-Categories'));
const SubCategoryAdd = lazy(() => import('../pages/Sub-Categories/Add-Sub-Category'));

/**
 * Products
 */
// import Products from '../pages/Products';
// import ProductAdd from '../pages/Products/Add-Product';
// import SingleProductAdd from '../pages/Products/Add-Product/Single-Product';
// import MultipleProductAdd from '../pages/Products/Add-Product/Multiple-Product';
// import PendingUnits from '../pages/Products/Pending-Units';
// import PendingAdditionalInfo from '../pages/Products/Pending-Additional-Info';
// import PendingParLevels from '../pages/Products/Pending-Par-Levels';
// import ImportProduct from '../pages/Products/Add-Product/Import-Product';
// import EditParLevels from '../pages/Products/Edit-Par-Levels';
// import HideProducts from '../pages/Products/Hide-Products';
const Products = lazy(() => import('../pages/Products'));
const ProductAdd = lazy(() => import('../pages/Products/Add-Product'));
const SingleProductAdd = lazy(() => import('../pages/Products/Add-Product/Single-Product'));
const MultipleProductAdd = lazy(() => import('../pages/Products/Add-Product/Multiple-Product'));
const PendingUnits = lazy(() => import('../pages/Products/Pending-Units'));
const PendingAdditionalInfo = lazy(() => import('../pages/Products/Pending-Additional-Info'));
const PendingParLevels = lazy(() => import('../pages/Products/Pending-Par-Levels'));
const ImportProduct = lazy(() => import('../pages/Products/Add-Product/Import-Product'));
const EditParLevels = lazy(() => import('../pages/Products/Edit-Par-Levels'));
const HideProducts = lazy(() => import('../pages/Products/Hide-Products'));
const SortProducts = lazy(() => import('../pages/Products/Sort-Product'));
const EditLocations = lazy(() => import('../pages/Products/Edit-Locations'));


const RepProducts = lazy(() => import('../pages/Rep-Products'));

/**
 * Vendor Items
 */
// import VendorItems from '../pages/Vendors-Items';
// import VendorItemsAdd from '../pages/Vendors-Items/Add-Vendor-Item';
// import UpdatVendorPrice from '../pages/Vendors/Update-Price';
// import ImportVendorRelationships from '../pages/Vendors/Import-Relationships';
// import VendorItemsBulkUpdate from '../pages/Vendors-Items/Bulk-Update';
const VendorItems = lazy(() => import('../pages/Vendors-Items'));
const VendorItemsAdd = lazy(() => import('../pages/Vendors-Items/Add-Vendor-Item'));
const UpdatVendorPrice = lazy(() => import('../pages/Vendors/Update-Price'));
const ImportVendorRelationships = lazy(() => import('../pages/Vendors/Import-Relationships'));
const VendorItemsBulkUpdate = lazy(() => import('../pages/Vendors-Items/Bulk-Update'));

/**
 * Ignore Comments
 */
// import IgnoreComments from '../pages/Ignore-Comments';
const IgnoreComments = lazy(() => import('../pages/Ignore-Comments'));

/**
 * Orders
 */
// import Orders from '../pages/Orders';
// import OrderAdd from '../pages/Orders/Add-Order';
// import OrderView from '../pages/Orders/View-Order';
// import OrderReceived from '../pages/Orders/Received-Order';
// import SavedOrder from '../pages/Orders/Saved-Order';
const Orders = lazy(() => import('../pages/Orders'));
const OrderAdd = lazy(() => import('../pages/Orders/Add-Order'));
const OrderView = lazy(() => import('../pages/Orders/View-Order'));
const OrderReceived = lazy(() => import('../pages/Orders/Received-Order'));
const SavedOrder = lazy(() => import('../pages/Orders/Saved-Order'));
const OrderReport = lazy(() => import('../pages/Orders/Order-Report'));
const ImportInventory = lazy(() => import('../pages/Orders/Import-Inventory'));
const OrderRebate = lazy(() => import('../pages/Orders/Order-Rebate'));


const RepOrders = lazy(() => import('../pages/Rep-Orders'));
const RepOrderView = lazy(() => import('../pages/Rep-Orders/View-Rep-Orders'));
const RepSavedOrder = lazy(() => import('../pages/Rep-Orders/Saved-Order'));
const RepRequestPricing = lazy(() => import('../pages/Rep-Orders/Request-Pricing'));


/**
 * Inventory Cost of Goods Routes
 */
const InventoryCost = lazy(() => import('../pages/Inventory-Cost'));
const ViewInventory = lazy(() => import('../pages/Inventory-Cost/View-Inventory'));
const GoodsReport = lazy(() => import('../pages/Inventory-Cost/Goods-Report'));
const EditInventory = lazy(() => import('../pages/Inventory-Cost/Edit-Inventory'));


/**
 * Emergency Items Routes
 */
//  import EmergencyItems from '../pages/Emergency-Items';
//  import EmergencyItemAdd from '../pages/Emergency-Items/Add-Emergency-Item';
 const EmergencyItems = lazy(() => import('../pages/Emergency-Items'));
 const EmergencyItemAdd = lazy(() => import('../pages/Emergency-Items/Add-Emergency-Item'));


 /**
  * Enquiries
  */
//  import Enquiries from '../pages/Enquiries';
 const Enquiries = lazy(() => import('../pages/Enquiries'));


 /**
  * Reports
  */
//  import VendorsPriceChange from '../pages/Reports/Vendors-Price-Change';
//  import VendorsUnrelatedItems from '../pages/Reports/Vendors-Unrelated-Items';
//  import VendorsAudit from '../pages/Reports/Vendors-Audit';
 const VendorsPriceChange = lazy(() => import('../pages/Reports/Vendors-Price-Change'));
 const VendorsUnrelatedItems = lazy(() => import('../pages/Reports/Vendors-Unrelated-Items'));
 const VendorsAudit = lazy(() => import('../pages/Reports/Vendors-Audit'));
 const PrepPlanReports = lazy(() => import('../pages/Reports/Prep-Plan'));
 const PrepPlanReportView = lazy(() => import('../pages/Reports/Prep-Plan/Prep-Plan-View'));
 const PrepPlanReportPrint = lazy(() => import('../pages/Reports/Prep-Plan/Print'));
 const PriceHistory = lazy(() => import('../pages/Reports/Price-History')); 
 const Rebates = lazy(() => import('../pages/Reports/Rebate')); 
 const Variance = lazy(()=> import('../pages/Reports/Variance'))



/**
 * Receipes
*/
// import Receipes from '../pages/Receipes';
// import ReceipeAdd from '../pages/Receipes/Add-Receipe';
// import ReceipeView from '../pages/Receipes/View-Receipe';
const Receipes = lazy(() => import('../pages/Receipes'));
const ReceipeAdd = lazy(() => import('../pages/Receipes/Add-Receipe'));
const ReceipeView = lazy(() => import('../pages/Receipes/View-Receipe'));
const ReceipeEditLocations = lazy(() => import('../pages/Receipes/Edit-Receipe-Locations'));


/**
 * Prep Plan
*/
// import PrepPlans from '../pages/Prep-Plan';
// import PrepPlanAdd from '../pages/Prep-Plan/Add-Prep-Plan';
const PrepPlans = lazy(() => import('../pages/Prep-Plan'));
const PrepPlanAdd = lazy(() => import('../pages/Prep-Plan/Add-Prep-Plan'));
const UsePrepPlan = lazy(() => import('../pages/Prep-Plan/Use-Prep-Plan'));


const IdealProductCosts = lazy(() => import('../pages/Ideal-Product-Cost'));
const IdealProductCostAdd = lazy(() => import('../pages/Ideal-Product-Cost/Add-Ideal-Product-Cost'));
const IdealProductCostView = lazy(() => import('../pages/Ideal-Product-Cost/View-Ideal-Product-Cost'));

const Pricing = lazy(() => import('../pages/Pricing'));

// // Pages Component
// import Chat from '../pages/Chat/Chat';
const Chat = lazy(() => import('../pages/Chat/Chat'));


// Profile
// import UserProfile from '../pages/Authentication/user-profile';
const UserProfile = lazy(() => import('../pages/Authentication/user-profile'));


// Pages Calendar
// import Calendar from '../pages/Calendar/index';
const Calendar = lazy(() => import('../pages/Calendar/index'));


// //Tasks
// import TasksList from '../pages/Tasks/tasks-list';
// import TasksKanban from '../pages/Tasks/tasks-kanban';
// import TasksCreate from '../pages/Tasks/tasks-create';
const TasksList = lazy(() => import('../pages/Tasks/tasks-list'));
const TasksKanban = lazy(() => import('../pages/Tasks/tasks-kanban'));
const TasksCreate = lazy(() => import('../pages/Tasks/tasks-create'));


// //Projects
// import ProjectsGrid from '../pages/Projects/projects-grid';
// import ProjectsList from '../pages/Projects/projects-list';
// import ProjectsOverview from '../pages/Projects/projects-overview';
// import ProjectsCreate from '../pages/Projects/projects-create';
const ProjectsGrid = lazy(() => import('../pages/Projects/projects-grid'));
const ProjectsList = lazy(() => import('../pages/Projects/projects-list'));
const ProjectsOverview = lazy(() => import('../pages/Projects/projects-overview'));
const ProjectsCreate = lazy(() => import('../pages/Projects/projects-create'));


// //Ecommerce Pages
// import EcommerceProducts from '../pages/Ecommerce/EcommerceProducts';
// import EcommerceProductDetail from '../pages/Ecommerce/EcommerceProductDetail';
// import EcommerceOrders from '../pages/Ecommerce/EcommerceOrders';
// import EcommerceCustomers from '../pages/Ecommerce/EcommerceCustomers';
// import EcommerceCart from '../pages/Ecommerce/EcommerceCart';
// import EcommerceCheckout from '../pages/Ecommerce/EcommerceCheckout';
// import EcommerceShops from '../pages/Ecommerce/EcommerceShops';
// import EcommerceAddProduct from '../pages/Ecommerce/EcommerceAddProduct';
const EcommerceProducts = lazy(() => import('../pages/Ecommerce/EcommerceProducts'));
const EcommerceProductDetail = lazy(() => import('../pages/Ecommerce/EcommerceProductDetail'));
const EcommerceOrders = lazy(() => import('../pages/Ecommerce/EcommerceOrders'));
const EcommerceCustomers = lazy(() => import('../pages/Ecommerce/EcommerceCustomers'));
const EcommerceCart = lazy(() => import('../pages/Ecommerce/EcommerceCart'));
const EcommerceCheckout = lazy(() => import('../pages/Ecommerce/EcommerceCheckout'));
const EcommerceShops = lazy(() => import('../pages/Ecommerce/EcommerceShops'));
const EcommerceAddProduct = lazy(() => import('../pages/Ecommerce/EcommerceAddProduct'));


//Email
// import EmailInbox from '../pages/Email/email-inbox';
// import EmailRead from '../pages/Email/email-read';
const EmailInbox = lazy(() => import('../pages/Email/email-inbox'));
const EmailRead = lazy(() => import('../pages/Email/email-read'));


//Invoices
// import InvoicesList from '../pages/Invoices/invoices-list';
// import InvoiceDetail from '../pages/Invoices/invoices-detail';
const InvoicesList = lazy(() => import('../pages/Invoices/invoices-list'));
const InvoiceDetail = lazy(() => import('../pages/Invoices/invoices-detail'));


// Authentication related pages
// import Login from '../pages/Authentication/Login';
// import Logout from '../pages/Authentication/Logout';
// import Register from '../pages/Authentication/Register';
// import ForgetPwd from '../pages/Authentication/ForgetPassword';
// import VerifyEmail from '../pages/Authentication/Verify-Email';
const Login = lazy(() => import('../pages/Authentication/Login'));
const Logout = lazy(() => import('../pages/Authentication/Logout'));
const Register = lazy(() => import('../pages/Authentication/Register'));
const ForgetPwd = lazy(() => import('../pages/Authentication/ForgetPassword'));
const VerifyEmail = lazy(() => import('../pages/Authentication/Verify-Email'));


//  // Inner Authentication
// import Login1 from '../pages/AuthenticationInner/Login';
// import Register1 from '../pages/AuthenticationInner/Register';
// import ForgetPwd1 from '../pages/AuthenticationInner/ForgetPassword';
// import LockScreen from '../pages/AuthenticationInner/auth-lock-screen';
const Login1 = lazy(() => import('../pages/AuthenticationInner/Login'));
const Register1 = lazy(() => import('../pages/AuthenticationInner/Register'));
const ForgetPwd1 = lazy(() => import('../pages/AuthenticationInner/ForgetPassword'));
const LockScreen = lazy(() => import('../pages/AuthenticationInner/auth-lock-screen'));


// Dashboard
// import Dashboard from '../pages/Dashboard/index';
// import DashboardSaas from '../pages/Dashboard-saas/index';
// import DashboardCrypto from '../pages/Dashboard-crypto/index';
// const Dashboard = lazy(() => import('../pages/Dashboard/index'));
const DashboardSaas = lazy(() => import('../pages/Dashboard-saas/index'));
const DashboardCrypto = lazy(() => import('../pages/Dashboard-crypto/index'));


//Crypto
// import CryptoWallet from '../pages/Crypto/crypto-wallet';
// import CryptoBuySell from '../pages/Crypto/crypto-buy-sell';
// import CryptoExchange from '../pages/Crypto/crypto-exchange';
// import CryptoLending from '../pages/Crypto/crypto-lending';
// import CryptoOrders from '../pages/Crypto/crypto-orders';
// import CryptoKYCApplication from '../pages/Crypto/crypto-kyc-application';
// import CryptoIcoLanding from '../pages/Crypto/CryptoIcoLanding/index';
const CryptoWallet = lazy(() => import('../pages/Crypto/crypto-wallet'));
const CryptoBuySell = lazy(() => import('../pages/Crypto/crypto-buy-sell'));
const CryptoExchange = lazy(() => import('../pages/Crypto/crypto-exchange'));
const CryptoLending = lazy(() => import('../pages/Crypto/crypto-lending'));
const CryptoOrders = lazy(() => import('../pages/Crypto/crypto-orders'));
const CryptoKYCApplication = lazy(() => import('../pages/Crypto/crypto-kyc-application'));
const CryptoIcoLanding = lazy(() => import('../pages/Crypto/CryptoIcoLanding/index'));


// Charts
// import ChartApex from '../pages/Charts/Apexcharts';
// import ChartistChart from '../pages/Charts/ChartistChart';
// import ChartjsChart from '../pages/Charts/ChartjsChart';
// import EChart from '../pages/Charts/EChart';
// import SparklineChart from '../pages/Charts/SparklineChart';
// import ToastUIChart from '../pages/Charts/ToastUIChart';
// import ChartsKnob from '../pages/Charts/charts-knob';
const ChartApex = lazy(() => import('../pages/Charts/Apexcharts'));
const ChartistChart = lazy(() => import('../pages/Charts/ChartistChart'));
const ChartjsChart = lazy(() => import('../pages/Charts/ChartjsChart'));
const EChart = lazy(() => import('../pages/Charts/EChart'));
const SparklineChart = lazy(() => import('../pages/Charts/SparklineChart'));
const ToastUIChart = lazy(() => import('../pages/Charts/ToastUIChart'));
const ChartsKnob = lazy(() => import('../pages/Charts/charts-knob'));


// Maps
// import MapsGoogle from '../pages/Maps/MapsGoogle';
// import MapsVector from '../pages/Maps/MapsVector';
// import MapsLeaflet from '../pages/Maps/MapsLeaflet';
const MapsGoogle = lazy(() => import('../pages/Maps/MapsGoogle'));
const MapsVector = lazy(() => import('../pages/Maps/MapsVector'));
const MapsLeaflet = lazy(() => import('../pages/Maps/MapsLeaflet'));


//Icons
// const IconBoxicons = lazy(() => import('../pages/Icons/IconBoxicons'));
// const IconDripicons = lazy(() => import('../pages/Icons/IconDripicons'));
// const IconMaterialdesign = lazy(() => import('../pages/Icons/IconMaterialdesign'));
// const IconFontawesome = lazy(() => import('../pages/Icons/IconFontawesome'));


//Tables
// import BasicTables from '../pages/Tables/BasicTables';
// import DatatableTables from '../pages/Tables/DatatableTables';
// import ResponsiveTables from '../pages/Tables/ResponsiveTables';
// import EditableTables from '../pages/Tables/EditableTables';
const BasicTables = lazy(() => import('../pages/Tables/BasicTables'));
const DatatableTables = lazy(() => import('../pages/Tables/DatatableTables'));
const ResponsiveTables = lazy(() => import('../pages/Tables/ResponsiveTables'));
const EditableTables = lazy(() => import('../pages/Tables/EditableTables'));


// Forms
// import FormElements from '../pages/Forms/FormElements';
// import FormAdvanced from '../pages/Forms/FormAdvanced';
// import FormEditors from '../pages/Forms/FormEditors';
// import FormValidations from '../pages/Forms/FormValidations';
// import FormMask from '../pages/Forms/FormMask';
// import FormRepeater from '../pages/Forms/FormRepeater';
// import FormUpload from '../pages/Forms/FormUpload';
// import FormWizard from '../pages/Forms/FormWizard';
// import FormXeditable from '../pages/Forms/FormXeditable';
const FormElements = lazy(() => import('../pages/Forms/FormElements'));
const FormAdvanced = lazy(() => import('../pages/Forms/FormAdvanced'));
const FormEditors = lazy(() => import('../pages/Forms/FormEditors'));
const FormValidations = lazy(() => import('../pages/Forms/FormValidations'));
const FormMask = lazy(() => import('../pages/Forms/FormMask'));
const FormRepeater = lazy(() => import('../pages/Forms/FormRepeater'));
const FormUpload = lazy(() => import('../pages/Forms/FormUpload'));
const FormWizard = lazy(() => import('../pages/Forms/FormWizard'));
const FormXeditable = lazy(() => import('../pages/Forms/FormXeditable'));


//Ui

// const UiAlert = lazy(() => import('../pages/Ui/UiAlert'));
// const UiButtons = lazy(() => import('../pages/Ui/UiButtons'));
// const UiCards = lazy(() => import('../pages/Ui/UiCards'));
// const UiCarousel = lazy(() => import('../pages/Ui/UiCarousel'));
// const UiColors = lazy(() => import('../pages/Ui/UiColors'));
// const UiDropdown = lazy(() => import('../pages/Ui/UiDropdown'));
// const UiGeneral = lazy(() => import('../pages/Ui/UiGeneral'));
// const UiGrid = lazy(() => import('../pages/Ui/UiGrid'));
// const UiImages = lazy(() => import('../pages/Ui/UiImages'));
// const UiLightbox = lazy(() => import('../pages/Ui/UiLightbox'));
// const UiModal = lazy(() => import('../pages/Ui/UiModal'));
// const UiProgressbar = lazy(() => import('../pages/Ui/UiProgressbar'));
// const UiSweetAlert = lazy(() => import('../pages/Ui/UiSweetAlert'));
// const UiTabsAccordions = lazy(() => import('../pages/Ui/UiTabsAccordions'));
// const UiTypography = lazy(() => import('../pages/Ui/UiTypography'));
// const UiVideo = lazy(() => import('../pages/Ui/UiVideo'));
// const UiSessionTimeout = lazy(() => import('../pages/Ui/UiSessionTimeout'));
// const UiRating = lazy(() => import('../pages/Ui/UiRating'));
// const UiRangeSlider = lazy(() => import('../pages/Ui/UiRangeSlider'));
// const UiNotifications = lazy(() => import('../pages/Ui/ui-notifications'));
// const UiImageCropper = lazy(() => import('../pages/Ui/ui-image-cropper'));


//Pages
// import PagesStarter from '../pages/Utility/pages-starter';
// import PagesMaintenance from '../pages/Utility/pages-maintenance';
const PagesStarter = lazy(() => import('../pages/Utility/pages-starter'));
const PagesMaintenance = lazy(() => import('../pages/Utility/pages-maintenance'));

// import PagesComingsoon from '../pages/Utility/pages-comingsoon';
// import PagesTimeline from '../pages/Utility/pages-timeline';
// import PagesFaqs from '../pages/Utility/pages-faqs';
// import PagesPricing from '../pages/Utility/pages-pricing';
// import Pages404 from '../pages/Utility/pages-404';
// import Pages500 from '../pages/Utility/pages-500';
const PagesComingsoon = lazy(() => import('../pages/Utility/pages-comingsoon'));
const PagesTimeline = lazy(() => import('../pages/Utility/pages-timeline'));
const PagesFaqs = lazy(() => import('../pages/Utility/pages-faqs'));
const PagesPricing = lazy(() => import('../pages/Utility/pages-pricing'));
const Pages404 = lazy(() => import('../pages/Utility/pages-404'));
const Pages500 = lazy(() => import('../pages/Utility/pages-500'));


//Contacts
// import ContactsGrid from '../pages/Contacts/contacts-grid';
// import ContactsList from '../pages/Contacts/contacts-list';
// import ContactsProfile from '../pages/Contacts/contacts-profile';
const ContactsGrid = lazy(() => import('../pages/Contacts/contacts-grid'));
const ContactsList = lazy(() => import('../pages/Contacts/contacts-list'));
const ContactsProfile = lazy(() => import('../pages/Contacts/contacts-profile'));


const userRoutes = [
  { path: '/manageAccount', component: AccountManage },

  { path: '/users/list', component: Users },
  { path: '/users/add', component: UserAdd },
  { path: '/users/edit/:id', component: UserAdd },

  { path: '/assignedReps/list', component: AssignedReps },
  { path: '/assignedReps/add', component: AddAssignedRep },
  { path: '/assignedReps/edit/:id', component: AddAssignedRep },

  { path: '/clients/list', component: Clients },
  { path: '/clients/add', component: ClientAdd },
  { path: '/clients/edit/:id', component: ClientAdd },

  { path: '/supplierReps/list', component: SupplierReps },
  { path: '/supplierReps/add', component: SupplierRepAdd },
  { path: '/supplierReps/edit/:id', component: SupplierRepAdd },

  { path: '/units/list', component: Units },
  { path: '/units/add', component: UnitAdd },
  { path: '/units/edit/:id', component: UnitAdd },

  { path: '/conversions/list', component: Conversions },
  { path: '/conversions/add', component: ConversionAdd },
  { path: '/conversions/edit/:id', component: ConversionAdd },

  { path: '/videos/list', component: Videos },
  { path: '/videos/add', component: VideoAdd },
  { path: '/videos/edit/:id', component: VideoAdd },

  { path: '/settings', component: SettingAdd },

  { path: '/massEmails/list', component: MassEmails },
  { path: '/massEmails/add', component: MassEmailAdd },
  { path: '/massEmails/edit/:id', component: MassEmailAdd },

  { path: '/cannedEmails/list', component: CannedEmails },
  { path: '/cannedEmails/add', component: CannedEmailAdd },
  { path: '/cannedEmails/edit/:id', component: CannedEmailAdd },

  { path: '/cms/list', component: Cms },
  { path: '/cms/add', component: CmsAdd },
  { path: '/cms/edit/:id', component: CmsAdd },

  { path: '/ads', component: AdAdd }, 

  { path: '/emailContents/list', component: EmailContents },
  { path: '/emailContents/edit/:id', component: EmailContentAdd },

  { path: '/admins/list', component: Admins },
  { path: '/admins/add', component: AdminAdd },
  { path: '/admins/edit/:id', component: AdminAdd },

  { path: '/businessProfiles/list', component: BusinessProfiles },

  { path: '/defaultProfiles/list', component: DefaultProfiles },
  { path: '/defaultProfiles/manage/:id', component: ManageProfile },

  { path: '/vendors/list', component: Vendors },
  { path: '/vendors/add', component: VendorAdd },
  { path: '/vendors/new', component: VendorAddNew },
  { path: '/vendors/wookai', component: VendorAddWookai },
  { path: '/vendors/edit/:id', component: VendorAddNew },
  { path: '/vendors/updatePriceList/:id', component: UpdatVendorPrice},
  { path: '/vendors/importRelationships/:id', component: ImportVendorRelationships},
  { path: '/vendors/relations/:id', component: VendorRelations },
  { path: '/vendors/loginDetails/:id', component: VendorLoginDetails },
  { path: '/vendors/PriceLogs/:id', component: VendorPriceLogs },
  { path: '/vendors/updateMapping/:id', component: UpdatVendorPrice},
  {path: '/repVendors/list', component: RepVendors},
  {path: '/repVendors/relations/:id', component: RepVendorRelations},


  { path: '/wookaiVendors/list', component: WookaiVendors },
  { path: '/wookaiVendors/add', component: WookaiVendorAdd },
  { path: '/wookaiVendors/edit/:id', component: WookaiVendorAdd },
  { path: '/wookaiVendors/updatePriceList/:id', component: UpdatWookaiVendorPrice},
  { path: '/wookaiVendors/updateInvoiceMapping/:id', component: UpdatWookaiVendorInvoiceMapping},
  { path: '/wookaiVendors/updateMapping/:id', component: UpdatWookaiVendorPrice},


  { path: '/businessLocations/list', component: BusinessLocations },
  { path: '/businessLocations/add', component: BusinessLocationAdd },
  { path: '/businessLocations/edit/:id', component: BusinessLocationAdd },
  { path: '/selectBusinessLocation', component: SelectBusinessLocation },

  { path: '/itemLocations/list', component: ItemLocations },
  { path: '/itemLocations/add', component: ItemLocationAdd },
  { path: '/itemLocations/edit/:id', component: ItemLocationAdd },

  { path: '/categories/list', component: Categories },
  { path: '/categories/add', component: CategoryAdd },
  { path: '/categories/edit/:id', component: CategoryAdd },

  { path: '/subCategories/list', component: SubCategories },
  { path: '/subCategories/add', component: SubCategoryAdd },
  { path: '/subCategories/edit/:id', component: SubCategoryAdd },

  { path: '/items/list', component: Products },
  { path: '/items/add', component: ProductAdd },
  { path: '/items/add-single', component: SingleProductAdd},
  { path: '/items/edit/:id', component: SingleProductAdd},
  { path: '/items/add-multiple', component: MultipleProductAdd},
  { path: '/items/pendingUnits', component: PendingUnits},
  { path: '/items/pendingAdditionalInfo', component: PendingAdditionalInfo},
  { path: '/items/pendingParLevels', component: PendingParLevels },
  { path: '/items/import', component: ImportProduct},
  { path: '/items/edit/:id', component: ProductAdd },
  { path: '/items/editParLevels', component: EditParLevels},
  { path: '/items/hide', component: HideProducts},
  { path: '/items/sort', component: SortProducts },
  { path: '/repItems/list', component: RepProducts },
  { path: '/items/editLocations', component: EditLocations},

  { path: '/vendorItems/vendor/:vendorId', component: VendorItems},
  { path: '/vendorItems/edit/:itemId', component: VendorItemsAdd},
  { path: '/vendorItems/update/:vendorId', component: VendorItemsBulkUpdate},

  { path: '/ignoreComments', component: IgnoreComments},

  { path: '/orders/list', component: Orders},
  { path: '/orders/add', component: OrderAdd},
  { path: '/orders/view/:orderId', component: OrderView},
  { path: '/orders/received/:orderId', component: OrderReceived},
  { path: '/savedOrders/list', component: SavedOrder },
  { path: '/orders/edit/:id', component: OrderAdd},
  { path: '/orders/report/:orderId', component: OrderReport},
  { path: '/orders/importInventory', component: ImportInventory},
  { path: '/orders/rebate/:orderId', component: OrderRebate},
  { path: '/orders/duplicate/:id', component: OrderAdd},
  { path: '/repOrders/list', component: RepOrders},
  { path: '/repOrders/view/:orderId', component: RepOrderView},
  { path: '/repSavedOrders/list', component: RepSavedOrder },
  { path: '/repOrders/requestPricing/:orderId', component: RepRequestPricing},
  

  { path: '/inventoryCosts/list', component: InventoryCost},
  { path: '/inventoryCosts/view/:id', component: ViewInventory},
  { path: '/inventoryCosts/edit/:id', component: EditInventory},
  { path: '/inventoryCosts/report', component: GoodsReport},


  { path: '/emergencyItems/list', component: EmergencyItems },
  { path: '/emergencyItems/add', component: EmergencyItemAdd },
  { path: '/emergencyItems/edit/:id', component: EmergencyItemAdd },

  { path: '/reports/vendorPriceChange', component: VendorsPriceChange },
  { path: '/reports/variance', component: Variance },
  { path: '/reports/vendorUnrelatedItems', component: VendorsUnrelatedItems },
  { path: '/reports/vendorsAudit', component: VendorsAudit},
  { path: '/reports/prep-plan', component: PrepPlanReports },
  { path: '/reports/view-prep-plan/:id/:prepPlanId', component: PrepPlanReportView },
  { path: '/reports/prepPlan/print/:id/:prepPlanId', component: PrepPlanReportPrint },
  { path: '/reports/priceHistory', component: PriceHistory },
  { path: '/reports/rebates', component: Rebates },


  { path: '/procedures/list', component: Receipes },
  { path: '/procedures/add', component: ReceipeAdd },
  { path: '/procedures/edit/:id', component: ReceipeAdd },
  { path: '/procedures/view/:id', component: ReceipeView },
  { path: '/procedures/editLocations', component: ReceipeEditLocations},

  { path: '/enquiries', component: Enquiries },


  // { path: '/pdftest', component: PdfTest },

  { path: '/prep-plan/add', component: PrepPlanAdd},
  { path: '/prep-plan/list', component: PrepPlans},
  { path: '/prep-plan/edit/:id', component: PrepPlanAdd },
  { path: '/use-prep-plan/:id', component: UsePrepPlan },
  { path: '/use-prep-plan/:id/report/:reportId', component: UsePrepPlan },

  { path: '/ideal-product-cost/add', component: IdealProductCostAdd},
  { path: '/ideal-product-cost/list', component: IdealProductCosts},
  { path: '/ideal-product-cost/edit/:id', component: IdealProductCostAdd},

  { path: '/pricing', component: Pricing },

  { path: '/dashboard', component: Dashboard },
  { path: '/dashboard-saas', component: DashboardSaas },
  { path: '/dashboard-crypto', component: DashboardCrypto },

  //Crypto
  { path: '/crypto-wallet', component: CryptoWallet },
  { path: '/crypto-buy-sell', component: CryptoBuySell },
  { path: '/crypto-exchange', component: CryptoExchange },
  { path: '/crypto-lending', component: CryptoLending },
  { path: '/crypto-orders', component: CryptoOrders },
  { path: '/crypto-kyc-application', component: CryptoKYCApplication },

  //chat
  { path: '/chat', component: Chat },

  // //calendar
  { path: '/calendar', component: Calendar },

  // //profile
  { path: '/profile', component: UserProfile },

  //Ecommerce
  { path: '/ecommerce-products', component: EcommerceProducts },
  { path: '/ecommerce-product-detail', component: EcommerceProductDetail },
  { path: '/ecommerce-orders', component: EcommerceOrders },
  { path: '/ecommerce-customers', component: EcommerceCustomers },
  { path: '/ecommerce-cart', component: EcommerceCart },
  { path: '/ecommerce-checkout', component: EcommerceCheckout },
  { path: '/ecommerce-shops', component: EcommerceShops },
  { path: '/ecommerce-add-product', component: EcommerceAddProduct },

  //Email
  { path: '/email-inbox', component: EmailInbox },
  { path: '/email-read', component: EmailRead },

  //Invoices
  { path: '/invoices-list', component: InvoicesList },
  { path: '/invoices-detail', component: InvoiceDetail },

  // Tasks
  { path: '/tasks-list', component: TasksList },
  { path: '/tasks-kanban', component: TasksKanban },
  { path: '/tasks-create', component: TasksCreate },

  //Projects
  { path: '/projects-grid', component: ProjectsGrid },
  { path: '/projects-list', component: ProjectsList },
  { path: '/projects-overview', component: ProjectsOverview },
  { path: '/projects-create', component: ProjectsCreate },

  // Contacts
  { path: '/contacts-grid', component: ContactsGrid },
  { path: '/contacts-list', component: ContactsList },
  { path: '/contacts-profile', component: ContactsProfile },

  //Charts
  { path: '/apex-charts', component: ChartApex },
  { path: '/chartist-charts', component: ChartistChart },
  { path: '/chartjs-charts', component: ChartjsChart },
  { path: '/e-charts', component: EChart },
  { path: '/sparkline-charts', component: SparklineChart },
  { path: '/tui-charts', component: ToastUIChart },
  { path: '/charts-knob', component: ChartsKnob },

  // Icons
  { path: '/icons-boxicons', component: IconBoxicons },
  { path: '/icons-dripicons', component: IconDripicons },
  { path: '/icons-materialdesign', component: IconMaterialdesign },
  { path: '/icons-fontawesome', component: IconFontawesome },

  // Tables
  { path: '/tables-basic', component: BasicTables },
  { path: '/tables-datatable', component: DatatableTables },
  { path: '/tables-responsive', component: ResponsiveTables },
  { path: '/tables-editable', component: EditableTables },

  // Maps
  { path: '/maps-google', component: MapsGoogle },
  { path: '/maps-vector', component: MapsVector },
  { path: '/maps-leaflet', component: MapsLeaflet },

  // Forms
  { path: '/form-elements', component: FormElements },
  { path: '/form-advanced', component: FormAdvanced },
  { path: '/form-editors', component: FormEditors },
  { path: '/form-mask', component: FormMask },
  { path: '/form-repeater', component: FormRepeater },
  { path: '/form-uploads', component: FormUpload },
  { path: '/form-wizard', component: FormWizard },
  { path: '/form-validation', component: FormValidations },
  { path: '/form-xeditable', component: FormXeditable },

  // // Ui
  { path: '/ui-alerts', component: UiAlert },
  { path: '/ui-buttons', component: UiButtons },
  { path: '/ui-cards', component: UiCards },
  { path: '/ui-carousel', component: UiCarousel },
  { path: '/ui-colors', component: UiColors },
  { path: '/ui-dropdowns', component: UiDropdown },
  { path: '/ui-general', component: UiGeneral },
  { path: '/ui-grid', component: UiGrid },
  { path: '/ui-images', component: UiImages },
  { path: '/ui-lightbox', component: UiLightbox },
  { path: '/ui-modals', component: UiModal },
  { path: '/ui-progressbars', component: UiProgressbar },
  { path: '/ui-sweet-alert', component: UiSweetAlert },
  { path: '/ui-tabs-accordions', component: UiTabsAccordions },
  { path: '/ui-typography', component: UiTypography },
  { path: '/ui-video', component: UiVideo },
  { path: '/ui-session-timeout', component: UiSessionTimeout },
  { path: '/ui-rating', component: UiRating },
  { path: '/ui-rangeslider', component: UiRangeSlider },
  { path: '/ui-notifications', component: UiNotifications },
  { path: '/ui-image-cropper', component: UiImageCropper },

  //Utility
  { path: '/pages-starter', component: PagesStarter },
  { path: '/pages-timeline', component: PagesTimeline },
  { path: '/pages-faqs', component: PagesFaqs },
  { path: '/pages-pricing', component: PagesPricing },

  // this route should be at the end of all other routes
  { path: '/', exact: true, component: () => <Redirect to='/dashboard' /> },
];

const authRoutes = [
  { path: '/logout', component: Logout },
  { path: '/login', component: Login },
  { path: '/forgot-password', component: ForgetPwd },
  { path: '/register', component: Register },
  { path: '/verifyEmail/:email/:emailCode', component: VerifyEmail},

  { path: '/pages-maintenance', component: PagesMaintenance },
  { path: '/pages-comingsoon', component: PagesComingsoon },
  { path: '/pages-404', component: Pages404 },
  { path: '/pages-500', component: Pages500 },
  { path: '/crypto-ico-landing', component: CryptoIcoLanding },

  // Authentication Inner
  { path: '/pages-login', component: Login1 },
  { path: '/pages-register', component: Register1 },
  { path: '/pages-forget-pwd', component: ForgetPwd1 },
  { path: '/auth-lock-screen', component: LockScreen },
];

export { userRoutes, authRoutes };