export const GET_IDEAL_PRODUCT_COSTS = 'GET_IDEAL_PRODUCT_COSTS';
export const SET_IDEAL_PRODUCT_COSTS = 'SET_IDEAL_PRODUCT_COSTS';
export const API_ERROR = 'LOGIN_API_ERROR';
export const SET_IDEAL_PRODUCT_COST = 'SET_IDEAL_PRODUCT_COST';
export const ADD_IDEAL_PRODUCT_COST = 'ADD_IDEAL_PRODUCT_COST';
export const EMPTY_IDEAL_PRODUCT_COST = 'EMPTY_IDEAL_PRODUCT_COST';
export const DELETE_IDEAL_PRODUCT_COST = 'DELETE_IDEAL_PRODUCT_COST';
export const EDIT_IDEAL_PRODUCT_COST = 'EDIT_IDEAL_PRODUCT_COST';
export const UPDATE_IDEAL_PRODUCT_COST = 'UPDATE_IDEAL_PRODUCT_COST';
export const SET_IDEAL_PRODUCT_COST_FILTER = 'SET_IDEAL_PRODUCT_COST_FILTER';
export const GET_IDEAL_PRODUCT_COSTS_LIST = 'GET_IDEAL_PRODUCT_COSTS_LIST';
export const SET_IDEAL_PRODUCT_COSTS_LIST = 'SET_IDEAL_PRODUCT_COSTS_LIST';
export const ADD_IDEAL_PRODUCT_COST_SORT = 'ADD_IDEAL_PRODUCT_COST_SORT';