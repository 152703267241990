import { takeEvery, fork, put, all, call, select } from 'redux-saga/effects';
import {
  GET_PREP_PLANS,
  ADD_PREP_PLAN,
  DELETE_PREP_PLAN,
  EDIT_PREP_PLAN,
  UPDATE_PREP_PLAN,
  GET_PREP_PLANS_LIST
} from './actionTypes';
import {
  setPrepPlansList,
  setPrepPlans,
  emptyPrepPlan,
  setPrepPlan,
  apiError,
  getPrepPlans as fetchPrepPlans,
  setPrepPlanFilter,
} from './actions';
import { httpDelete, httpGet, httpPost, httpPut} from '../../utils/http';
import { PREP_PLANS_ENDPOINT } from '../../config/endPoints';
import { successToaster, errorToaster } from "../../components/Common/Toaster";

function* getPrepPlans() {
  try {
    const prepPlanState = yield select(getPrepPlan);
    let URL = PREP_PLANS_ENDPOINT;
    URL += `?offSet=${prepPlanState.filter.offSet}`;
    URL += `&limit=${prepPlanState.filter.limit}`;
    URL += `&query=${prepPlanState.filter.query}`;

    const res = yield httpGet(URL);

    if (res.status !== 200) {
      yield put(apiError(res.data.error));
      return false;
    }

    yield put(setPrepPlans(res.data.records));
    yield put(
      setPrepPlanFilter({
        field: 'pages',
        value: res.data.pages,
      })
    );
  } catch (error) {
    yield put(apiError(error));
  }
}

function* getPrepPlansList() {
  try {
    let URL = `${PREP_PLANS_ENDPOINT}/get/list`;
    const res = yield httpGet(URL);

    if (res.status !== 200) {
      yield put(apiError(res.data.error));
      return false;
    }
    yield put(setPrepPlansList(res.data));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* addPrepPlan({payload: {history}}) {
  try {
    const prepPlanState = yield select(getPrepPlan);
    const prepPlan = prepPlanState.prepPlan;

    const res = yield httpPost(PREP_PLANS_ENDPOINT, prepPlan);
    console.log(res);
    if (res.status !== 200) {
      yield put(apiError(res.data.error));
      return false;
    }

    yield put(emptyPrepPlan());
    history.push('/prep-plan/list');
    successToaster('Record added', 'Success');
  } catch (error) {
    yield put(apiError(error));
  }
}

function* deletePrepPlan({ payload: { id } }) {
  try {
    const res = yield httpDelete(`${PREP_PLANS_ENDPOINT}/${id}`);
    if (res.status !== 200) {
      yield put(apiError(res.data.error));
      return false;
    }
    console.log('shoud run');
    yield put(fetchPrepPlans());
  } catch (error) {
    yield put(apiError(error));
  }
}

function* editPrepPlan({ payload: { id } }) {
  try {
    const res = yield httpGet(`${PREP_PLANS_ENDPOINT}/${id}`);
    if (res.status !== 200) {
      yield put(apiError(res.data.error));
      return false;
    }

    yield put(setPrepPlan({ field: 'prep_plan_name', value: res.data.prep_plan_name }));
    yield put(setPrepPlan({ field: 'prep_plan_description', value: res.data.prep_plan_description }));
    yield put(setPrepPlan({ field: 'prep_par_levels', value: res.data.prep_par_levels }));
    yield put(setPrepPlan({ field: 'prep_plan_details', value: res.data.prep_plan_details }));


  } catch (error) {
    yield put(apiError(error));
  }
}

function* updatePrepPlan({ payload: { history, id } }) {
  try {
    const prepPlanState = yield select(getPrepPlan);
    const prepPlan = prepPlanState.prepPlan;

    const res = yield httpPut(`${PREP_PLANS_ENDPOINT}/${id}`, prepPlan);
    console.log(res);
    if (res.status !== 200) {
      yield put(apiError(res.data.error));
      return false;
    }

    yield put(emptyPrepPlan());
    history.push('/prep-plan/list');
    successToaster('Record updated.', 'Success');
  } catch (error) {
    yield put(apiError(error));
  }
}

const getPrepPlan = (state) => state.PrepPlan;

export function* watchGetPrepPlans() {
  yield takeEvery(GET_PREP_PLANS, getPrepPlans);
}

export function* watchGetPrepPlansList() {
  yield takeEvery(GET_PREP_PLANS_LIST, getPrepPlansList);
}

export function* watchAddPrepPlan() {
  yield takeEvery(ADD_PREP_PLAN, addPrepPlan);
}

export function* watchDeletePrepPlan() {
  yield takeEvery(DELETE_PREP_PLAN, deletePrepPlan);
}

export function* watchEditPrepPlan() {
  yield takeEvery(EDIT_PREP_PLAN, editPrepPlan);
}

export function* watchUpdatePrepPlan() {
  yield takeEvery(UPDATE_PREP_PLAN, updatePrepPlan);
}

function* prepPlanSaga() {
  yield all([
    fork(watchGetPrepPlans),
    fork(watchAddPrepPlan),
    fork(watchDeletePrepPlan),
    fork(watchEditPrepPlan),
    fork(watchUpdatePrepPlan),
    fork(watchGetPrepPlansList)
  ]);
}

export default prepPlanSaga;
