import {
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_ERROR,
  CODE_SENT_ON_EMAIL,
  VERIFY_CODE,
  VERIFY_CODE_SUCCESS,
  VERIFY_CODE_FAILED,
  CHANGE_PASSWORD_AFTER_CODE_VERIFY
} from "./actionTypes";

export const userForgetPassword = (email, history) => {
  return {
    type: FORGET_PASSWORD,
    payload: { email, history }
  };
};

export const userForgetPasswordSuccess = message => {
  return {
    type: FORGET_PASSWORD_SUCCESS,
    payload: message
  };
};

export const userForgetPasswordError = message => {
  return {
    type: FORGET_PASSWORD_ERROR,
    payload: message
  };
};

export const codeSentOnEmail = message => {
  return {
    type: CODE_SENT_ON_EMAIL,
    payload: message
  };
};

export const verifyCode = (email, code) => {
  console.log('nside actions')
  return {
    type: VERIFY_CODE,
    payload: {email, code}
  };
};

export const verifyCodeFailed = message => {
  return {
    type: VERIFY_CODE_FAILED,
    payload: message
  };
};

export const verifyCodeSuccess = message => {
  return {
    type: VERIFY_CODE_SUCCESS,
    payload: message
  };
};

export const changePasswordAfterCodeverify = (history, email, password) => {
  return {
    type: CHANGE_PASSWORD_AFTER_CODE_VERIFY,
    payload: {history, email, password}
  };
};


