import { takeEvery, fork, put, all, call, select } from 'redux-saga/effects';
import {
  GET_RECEIPES,
  ADD_RECEIPE,
  DELETE_RECEIPE,
  EDIT_RECEIPE,
  UPDATE_RECEIPE,
  GET_RECEIPES_LIST
} from './actionTypes';
import {
  setReceipesList,
  setReceipes,
  emptyReceipe,
  setReceipe,
  apiError,
  getReceipes as fetchReceipes,
  setReceipeFilter,
} from './actions';
import { httpDelete, httpGet, httpPost, httpPut, httpPostFormData, httpPutFormData } from '../../utils/http';
import { RECEIPES_ENDPOINT } from '../../config/endPoints';
import { successToaster, errorToaster } from "../../components/Common/Toaster";

function* getReceipes() {
  try {
    const receipeState = yield select(getReceipe);
    let URL = RECEIPES_ENDPOINT;
    URL += `?offSet=${receipeState.filter.offSet}`;
    URL += `&limit=${receipeState.filter.limit}`;
    URL += `&query=${receipeState.filter.query}`;

    const res = yield httpGet(URL);

    if (res.status !== 200) {
      yield put(apiError(res.data.error));
      return false;
    }

    yield put(setReceipes(res.data.records));
    yield put(
      setReceipeFilter({
        field: 'pages',
        value: res.data.pages,
      })
    );
  } catch (error) {
    yield put(apiError(error));
  }
}

function* getReceipesList() {
  try {
    let URL = `${RECEIPES_ENDPOINT}/get/list`;
    const res = yield httpGet(URL);

    if (res.status !== 200) {
      yield put(apiError(res.data.error));
      return false;
    }
    yield put(setReceipesList(res.data));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* addReceipe({payload: {history}}) {
  try {
    const receipeState = yield select(getReceipe);
    const receipe = receipeState.receipe;

    let formData = new FormData();
    formData.append('data', JSON.stringify(receipe));
    formData.append('image', receipe.image);

    const res = yield httpPostFormData(RECEIPES_ENDPOINT, formData);
    console.log(res);
    if (res.status !== 200) {
      yield put(apiError(res.data.error));
      return false;
    }

    yield put(emptyReceipe());
    history.push('/procedures/list');
    successToaster('Record added', 'Success');
  } catch (error) {
    yield put(apiError(error));
  }
}

function* deleteReceipe({ payload: { id } }) {
  try {
    const res = yield httpDelete(`${RECEIPES_ENDPOINT}/${id}`);
    if (res.status !== 200) {
      yield put(apiError(res.data.error));
      return false;
    }

    if(!res.data.status){
      errorToaster(res.data.message, 'Error');
      return false;
    }
    console.log('res', res);
    console.log('shoud run');
    yield put(fetchReceipes());
  } catch (error) {
    yield put(apiError(error));
  }
}

function* editReceipe({ payload: { id } }) {
  try {
    const res = yield httpGet(`${RECEIPES_ENDPOINT}/${id}`);
    if (res.status !== 200) {
      yield put(apiError(res.data.error));
      return false;
    }

    yield put(setReceipe({ field: 'receipe_name', value: res.data.receipe_name }));
    yield put(setReceipe({ field: 'receipe_description', value: res.data.receipe_description }));
    yield put(setReceipe({ field: 'receipe_details', value: res.data.receipe_details }));
    yield put(setReceipe({ field: 'receipe_instructions', value: res.data.receipe_instructions }));
    yield put(setReceipe({ field: 'url', value: res.data.url }));
    yield put(setReceipe({ field: 'image', value: res.data.image }));
    yield put(setReceipe({ field: 'plu', value: res.data.plu }));
    yield put(setReceipe({ field: 'size_number', value: res.data.size_number }));


  } catch (error) {
    yield put(apiError(error));
  }
}

function* updateReceipe({ payload: { history, id } }) {
  try {
    const receipeState = yield select(getReceipe);
    const receipe = receipeState.receipe;

    let formData = new FormData();
    formData.append('data', JSON.stringify(receipe));
    formData.append('image', receipe.image);

    const res = yield httpPutFormData(`${RECEIPES_ENDPOINT}/${id}`, formData);
    console.log(res);
    if (res.status !== 200) {
      yield put(apiError(res.data.error));
      return false;
    }

    yield put(emptyReceipe());
    history.push('/procedures/list');
    successToaster('Record updated', 'Success');
  } catch (error) {
    yield put(apiError(error));
  }
}

const getReceipe = (state) => state.Receipe;

export function* watchGetReceipes() {
  yield takeEvery(GET_RECEIPES, getReceipes);
}

export function* watchGetReceipesList() {
  yield takeEvery(GET_RECEIPES_LIST, getReceipesList);
}

export function* watchAddReceipe() {
  yield takeEvery(ADD_RECEIPE, addReceipe);
}

export function* watchDeleteReceipe() {
  yield takeEvery(DELETE_RECEIPE, deleteReceipe);
}

export function* watchEditReceipe() {
  yield takeEvery(EDIT_RECEIPE, editReceipe);
}

export function* watchUpdateReceipe() {
  yield takeEvery(UPDATE_RECEIPE, updateReceipe);
}

function* receipeSaga() {
  yield all([
    fork(watchGetReceipes),
    fork(watchAddReceipe),
    fork(watchDeleteReceipe),
    fork(watchEditReceipe),
    fork(watchUpdateReceipe),
    fork(watchGetReceipesList)
  ]);
}

export default receipeSaga;
