export const GET_VENDORS = "GET_VENDORS";
export const SET_VENDORS = "SET_VENDORS";
export const API_ERROR = "LOGIN_API_ERROR";
export const SET_VENDOR = "SET_VENDOR";
export const ADD_VENDOR = "ADD_VENDOR";
export const EMPTY_VENDOR = "EMPTY_VENDOR";
export const DELETE_VENDOR = "DELETE_VENDOR";
export const EDIT_VENDOR = "EDIT_VENDOR";
export const UPDATE_VENDOR = "UPDATE_VENDOR";
export const SET_VENDOR_FILTER = "SET_VENDOR_FILTER";
export const GET_VENDORS_LIST = "GET_VENDORS_LIST";
export const SET_VENDORS_LIST = "SET_VENDORS_LIST";
export const GET_VENDOR_RELATIONS = "GET_VENDOR_RELATIONS";
export const SET_VENDOR_RELATIONS = "SET_VENDOR_RELATIONS";
export const SET_VENDOR_RELATION_FILTER = "SET_VENDOR_RELATION_FILTER";
export const GET_VENDOR_PRICE_LOGS = "GET_VENDOR_PRICE_LOGS";
export const SET_VENDOR_PRICE_LOGS = "SET_VENDOR_PRICE_LOGS";
export const SET_VENDOR_PRICE_LOGS_FILTER = "SET_VENDOR_PRICE_LOGS_FILTER";
