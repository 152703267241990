import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Card, CardBody, CardTitle, Modal, ModalHeader, ModalBody, ModalFooter, Media, Table } from "reactstrap";
import { Link } from "react-router-dom";

//import Charts
import StackedColumnChart from "./StackedColumnChart";

import modalimage1 from "../../assets/images/product/img-7.png";
import modalimage2 from "../../assets/images/product/img-4.png";

// Pages Components
import WelcomeComp from "./WelcomeComp";
import MonthlyEarning from "./MonthlyEarning";
import SocialSource from "./SocialSource";
import ActivityComp from "./ActivityComp";
import TopCities from "./TopCities";
import LatestTranaction from "./LatestTranaction";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

//Import Images
//import LogoImg from "../../assets/images/logo-circle.png";
import LogoImg from "../../assets/images/logo-circle.png";
import LogoImgFour from "../../assets/images/logo-circle-fourthoption.png";
import HandIcon from "../../assets/images/units-Icon.png";
import SupplierIcon from "../../assets/images/supplier-icon.png";
import SalesIcon from "../../assets/images/sales-representative-icon.png";
import ConversionIcon from "../../assets/images/conversion-Icon.png";
import CategoriesIcon from "../../assets/images/categories-Icon.png";
import UserIcon from "../../assets/images/usermanagement-icon.png";
import CountIcon from "../../assets/images/count.png";
import SubCategoriesIcon from "../../assets/images/sub-categories-Icon.png";
import ItemLocationIcon from "../../assets/images/itemlocation-icon.png";
import OrderIcon from "../../assets/images/manage-order.png";
import ItemIcon from "../../assets/images/item-icon.png";
import wookaiSetup from "../../assets/images/wookai-setup.png";
import UserManagement from "../../assets/images/management.png";
import OrderingReporting from "../../assets/images/reporting.png";
import wookaisetupIcon from "../../assets/images/wwokaisetup-icon.png";
import backIcon from "../../assets/images/backarrow.png";
import assistantIcon from "../../assets/images/manage-assistant-icon.png";
import orderIcon from "../../assets/images/order-icon.png";
import circleone from "../../assets/images/bg-circle.png";
import OrderReceiveIcon from "../../assets/images/order-receive-icon.png";
import HelpIcon from "../../assets/images/help-icon.png";
import EmergencyIcon from "../../assets/images/emergenrcy-item-icon.png";
import InventoryIcon from "../../assets/images/Inventory-Cost.png";
import saveOrderIcon from "../../assets/images/saveOrder.png";
import itemListManagement from "../../assets/images/itemListManagement.png";
import priceManagement from "../../assets/images/priceManagement.png";

import priceManageIcon from "../../assets/images/price-management-icon.png";
import ItemListManagement from "../../assets/images/Item-List-Management.png";
import ProceduresIcon from "../../assets/images/Procedures.png";
import PrepPlanningIcon from "../../assets/images/PrepPlanning.png";
import IdealProductCostIcon from "../../assets/images/Ideal-Product-Cost.png";
import listIcon from "../../assets/images/listIcon.png";
import PendingUnitsIcon from "../../assets/images/Pending-Units.png";
import PendingAdditionalInfoIcon from "../../assets/images/Pending-Additional-Info.png";
import EditParLevelsIcon from "../../assets/images/Edit-Par-Levels.png";
import PendingParLevelsIcon from "../../assets/images/Pending-par-Levels.png";
import ReportsIcon from "../../assets/images/ReportsIcon.png";
import ReportMain from "../../assets/images/reports-option.png";
import SuppliersPriceChangeOrangeIcon from "../../assets/images/SuppliersPriceChangeIcon.svg";
import SuppliersUnrelatedItemsIcon from "../../assets/images/SuppliersUnrelatedItemsIcon.svg";
import SuppliersAuditIcon from "../../assets/images/SuppliersAuditIcon.svg";
import rebateIcon from "../../assets/images/rebateIcon.svg";
import priceHistory from "../../assets/images/priceHistory.svg";
import DailyProductionIcon from "../../assets/images/dailyProductionIcon.png";
import Inventory from "../../assets/images/Inventory.png";
import sortIcon from "../../assets/images/sortIcon.png";
import MenuItemsCostPricing from "../../assets/images/MenuItemsCostPricing.svg";
import { Plans } from '../../config/plans';
import jwt_decode from 'jwt-decode';


import $ from 'jquery';
import "./style.css";
import "./dashboard.css";
//i18n
import { withNamespaces } from 'react-i18next';

const Dashboard = (props) => {

	const [modal, setmodal] = useState(false);
	const selectedBusinessLocationObject = JSON.parse(localStorage.getItem('businessLocationObject'));
	// decode token to get the user type
	const jwtDecode = jwt_decode(localStorage.getItem('token'));
	const userType = jwtDecode.user_type;

	useEffect(() => {
		$(document).ready(function () {
			$(".wookaiOption").not('.disabled').click(function () {
				$(".left-text li").toggleClass("show");
				$(".right-text li").toggleClass("show");
				$(".iconchange").toggleClass("show");
				$(".circle-logoinner").toggleClass("wookaisetupimage");
				$(".banner-inner").addClass("viewbtnone");
			});

			$("a.wookaisetup").not('.disabled').click(function () {
				$(".left-text li").toggleClass("show");
				$(".right-text li").toggleClass("show");
				$(".iconchange").toggleClass("show");
				$(".circle-logoinner").toggleClass("wookaisetupimage");
				$(".banner-inner").addClass("viewbtnone");
			});

			$(".back-btn").not('.disabled').click(function () {
				$(".left-text li").removeClass("show");
				$(".right-text li").removeClass("show");
				$(".iconchange").removeClass("show");
				$(".circle-logoinner").removeClass("wookaisetupimage");
				$(".banner-inner").removeClass("viewbtnone");
			});

			$(".back-btn2").not('.disabled').click(function () {
				$(".left-text li").removeClass("show2");
				$(".right-text li").removeClass("show2");
				$(".iconchange").removeClass("show2");
				$(".circle-logoinner").removeClass("managemantimage");
				$(".banner-inner").removeClass("viewbtn");
			});

			$(".manage-user").not('.disabled').click(function () {
				$(".left-text li").toggleClass("show2");
				$(".right-text li").toggleClass("show2");
				$(".iconchange").toggleClass("show2");
				$(".iconchange").removeClass("show");
				$(".circle-logoinner").toggleClass("managemantimage");
				$(".banner-inner").toggleClass("viewbtn");
			});

			$("#usermanage").not('.disabled').click(function () {
				$(".left-text li").toggleClass("show2");
				$(".right-text li").toggleClass("show2");
				$(".iconchange").toggleClass("show2");
				$(".iconchange").removeClass("show");
				$(".circle-logoinner").toggleClass("managemantimage");
				$(".banner-inner").toggleClass("viewbtn");
			});

			$(".manage-order1").not('.disabled').click(function () {
				$(".left-text li").toggleClass("show3");
				$(".right-text li").toggleClass("show3");
				$(".iconchange").toggleClass("show3");
				$(".iconchange").toggleClass("show2");
				$(".circle-logoinner").toggleClass("orderingimage");
				$(".banner-inner").toggleClass("viewbtn3");
			});

			$("li.manageuser.manageuserthird").not('.disabled').click(function () {
				$(".left-text li").toggleClass("show3");
				$(".right-text li").toggleClass("show3");
				$(".iconchange").toggleClass("show3");
				$(".iconchange").toggleClass("show2");
				$(".circle-logoinner").toggleClass("orderingimage");
				$(".banner-inner").toggleClass("viewbtn3");
			});

			$(".back-btn3").not('.disabled').click(function () {
				$(".left-text li").removeClass("show3");
				$(".right-text li").removeClass("show3");
				$(".iconchange").removeClass("show2");
				$(".iconchange").removeClass("show3");
				$(".circle-logoinner").removeClass("orderingimage");
				$(".banner-inner").removeClass("viewbtn3");
			});


			$("#firstclick").not('.disabled').click(function () {
				$(".wookaicircleone").addClass("hide");
				$("#options-block-one").addClass("showoption");
				$(".mobile-dashboard").addClass("show");
				$(".banner-inner").removeClass("viewbtn3");
			});
			$("#alternate.mobilebtn").not('.disabled').click(function () {
				$(".wookaicircleone").removeClass("hide");
				$(".wookai-options").removeClass("showoption");
				$(".mobile-dashboard").removeClass("show");
				$(".mobile-dashboard").removeClass("showSecond");
				$(".mobile-dashboard").removeClass("showThird");
				$(".mobile-dashboard").removeClass("showFourth");
				$(".mobile-dashboard").removeClass("showFifth");
				$(".mobile-dashboard").removeClass("showSeventh");
			});

			$("#thirdclick").not('.disabled').click(function () {
				window.scrollTo(0, 0)
				$(".wookaicircleone").addClass("hide");
				$("#options-third-block").addClass("showoption");
				$(".mobile-dashboard").addClass("showThird");
				$(".banner-inner").removeClass("viewbtn3");
			});

			$("#secondclick").not('.disabled').click(function () {
				window.scrollTo(0, 0)
				$(".wookaicircleone").addClass("hide");
				$("#options-second-block").addClass("showoption");
				$(".mobile-dashboard").addClass("showSecond");
				$(".banner-inner").removeClass("viewbtn3");
			});

			$("#fourthclick").not('.disabled').click(function () {
				window.scrollTo(0, 0)
				$(".wookaicircleone").addClass("hide");
				$("#options-fourth-block").addClass("showoption");
				$(".mobile-dashboard").addClass("showFourth");
				$(".banner-inner").removeClass("viewbtn3");
			});

			$("#fifthclick").not('.disabled').click(function () {
				window.scrollTo(0, 0)
				$(".wookaicircleone").addClass("hide");
				$("#options-fifth-block").addClass("showoption");
				$(".mobile-dashboard").addClass("showFifth");
				$(".banner-inner").removeClass("viewbtn3");
			});

			$("#sixthClick").not('.disabled').click(function () {
				window.scrollTo(0, 0)
				$(".wookaicircleone").addClass("hide");
				$("#options-sixth-block").addClass("showoption");
				$(".mobile-dashboard").addClass("showFifth");
				$(".banner-inner").removeClass("viewbtn3");
			});
			$(".PriceManage").not('.disabled').click(function () {
				$(".left-text li").toggleClass("showPrice");
				$(".right-text li").toggleClass("showPrice");
				$(".iconchange").toggleClass("showPrice");
				$(".iconchange").removeClass("show");
				$(".circle-logoinner").toggleClass("priceManagement");
				$(".banner-inner").toggleClass("viewbtn4");
			});

			$(".priceManageIcon").not('.disabled').click(function () {
				$(".left-text li").toggleClass("showPrice");
				$(".right-text li").toggleClass("showPrice");
				$(".iconchange").toggleClass("showPrice");
				$(".iconchange").removeClass("show");
				$(".circle-logoinner").toggleClass("priceManagement");
				$(".banner-inner").toggleClass("viewbtn4");
			});

			$(".back-btn4").not('.disabled').click(function () {
				$(".left-text li").removeClass("showPrice");
				$(".right-text li").removeClass("showPrice");
				$(".iconchange").removeClass("showPrice");
				$(".circle-logoinner").removeClass("priceManagement");
				$(".banner-inner").removeClass("viewbtn4");
			});

			$(".itemList").not('.disabled').click(function () {
				$(".left-text").toggleClass("listItemManage");
				$(".right-text").toggleClass("listItemManage");
				$(".iconchange").toggleClass("listItemManage");
				$(".circle-logoinner").toggleClass("itemListImg");
				$(".banner-inner").addClass("viewbtn5");
			});

			$(".itemListIcon").not('.disabled').click(function () {
				$(".left-text").toggleClass("listItemManage");
				$(".right-text").toggleClass("listItemManage");
				$(".iconchange").toggleClass("listItemManage");
				$(".circle-logoinner").toggleClass("itemListImg");
				$(".banner-inner").addClass("viewbtn5");
			});

			$(".back-btn5").not('.disabled').click(function () {
				$(".left-text").removeClass("listItemManage");
				$(".right-text").removeClass("listItemManage");
				$(".iconchange").removeClass("listItemManage");
				$(".circle-logoinner").removeClass("itemListImg");
				$(".banner-inner").removeClass("viewbtn5");
			});

			$(".reportClick").click(function () {
				$(".left-text").addClass("showReport");
				$(".right-text").addClass("showReport");
				$(".iconchange").addClass("showReport");
				$(".iconchange").addClass("showReport");
				$(".circle-logoinner").addClass("reportImage");
				$(".banner-inner").addClass("viewbtn6");
			});

			$(".back-btn6").click(function () {
				$(".left-text").removeClass("showReport");
				$(".right-text").removeClass("showReport");
				$(".iconchange").removeClass("showReport");
				$(".circle-logoinner").removeClass("reportImage");
				$(".banner-inner").removeClass("viewbtn6");
			});

			$(".inventoryClick").not('.disabled').click(function () {
				$(".left-text li").toggleClass("inventoryOption");
				$(".right-text li").toggleClass("inventoryOption");
				$(".iconchange").toggleClass("inventoryOption");
				$(".circle-logoinner").toggleClass("inventoryImage");
				$(".banner-inner").addClass("viewbtn7");
			});
			$(".back-btn7").click(function () {
				$(".left-text li").removeClass("inventoryOption");
				$(".right-text li").removeClass("inventoryOption");
				$(".iconchange").removeClass("inventoryOption");
				$(".circle-logoinner").removeClass("inventoryImage");
				$(".banner-inner").removeClass("viewbtn7");
			});

			/* Inventory Mobile View Click */
			$("#seventhclick").not('.disabled').click(function () {
				$(".wookaicircleone").addClass("hide");
				$("#options-block-one").addClass("seventhoption");
				$(".mobile-dashboard").addClass("showSeventh");
				$(".banner-inner").removeClass("viewbtn3");
			});

		});


		// Your code here
	}, []);

	const reports = [
		{ title: "Orders", iconClass: "bx-copy-alt", description: "1,235" },
		{ title: "Revenue", iconClass: "bx-archive-in", description: "$35, 723" },
		{ title: "Average Price", iconClass: "bx-purchase-tag-alt", description: "$16.2" }
	];
	const email = [
		{ title: "Week", linkto: "#", isActive: false },
		{ title: "Month", linkto: "#", isActive: false },
		{ title: "Year", linkto: "#", isActive: true }
	];

	return (
		<React.Fragment>
			<div className="page-content dashboard-wrapper">
				<Container fluid>

					{/* Render Breadcrumb */}
					<Breadcrumbs title={props.t('Dashboard')} breadcrumbItem={props.t('Dashboard')} />
					{
						((selectedBusinessLocationObject != null) && (selectedBusinessLocationObject.stripe_plan_id == Plans.WOOKAI_SUPPLIER_PRICE_CHECKER))
						&&
						<>
							<Row>
								<Col lg="12">
									<div className="banner-wrap">
										<div className="banner-inner">
											<span className="back-btn" id="alternate">
												<img src={backIcon} alt="" />
											</span>
											<span className="back-btn2" id="alternate">
												<img src={backIcon} alt="" />
											</span>
											<span className="back-btn3" id="alternate">
												<img src={backIcon} alt="" />
											</span>
											<span className="back-btn4" id="alternate">
												<img src={backIcon} alt="" />
											</span>
											<span className="back-btn5" id="alternate">
												<img src={backIcon} alt="" />
											</span>
											<span className="back-btn6" id="alternate">
												<img src={backIcon} alt="" />
											</span>
											<span className="back-btn7" id="alternate">
												<img src={backIcon} alt="" />
											</span>
											<Row>
												<Col lg="2" sm="2" xs="2" className="iconchange">
													<ul className="icons firstthree">
														<li className="manageaccount">
															<a href="javascript:void(0);" className="wookaisetup disabled">
																<img src={wookaisetupIcon} alt="" />
															</a>
															<Link className='SuppliersPriceChangeIcon' to={`/reports/vendorPriceChange`}>
																<img src={SuppliersPriceChangeOrangeIcon} alt="SuppliersPriceChangeIcon" />
															</Link>
															<Link className="procedureIcon" to={`/procedures/list`}>
																<img src={ProceduresIcon} alt="" />
															</Link>
															<Link className="listIcon" to={`/items/list`}>
																<img src={listIcon} alt="" />
															</Link>
															<Link className="second-img" to={`/units/list`}>
																<img src={HandIcon} alt="" />
															</Link>
															<Link className="third-click-img" to={`${selectedBusinessLocationObject.is_slave == 0 ? '/vendors/list' : '#'}`}>
																<img src={SupplierIcon} alt="" />
															</Link>
															<Link className="fourth-click-img" to={`/emergencyItems/list`}>
																<img src={EmergencyIcon} alt="" />
															</Link>
															<Link className="inventory_count_img" to={`/inventoryCosts/list`}>
																<img src={orderIcon} alt='order-icon' />
															</Link>
															<p className="manage">WookAI Setup</p>
															<p className="manage-cate">Units</p>
															<p className="manage-order">Special Order Items</p>
														</li>
														<li className="manageuser PriceOption ">
															<a id="usermanage" className="wookaisetup-user" href="javascript:void(0);">
																<img src={UserIcon} alt="" />
															</a>
															<Link className='suopilerAuditIcon disabled' to={`#`}>
																<img src={SuppliersAuditIcon} alt="SuppliersAuditIcon" />
															</Link>
															<Link className="prepPlanIcon" to={`/prep-plan/list`}>
																<img src={PrepPlanningIcon} alt="" />
															</Link>
															<Link className="pendingListIcon" to={`/items/pendingAdditionalInfo`}>
																<img src={PendingAdditionalInfoIcon} alt="" />
															</Link>
															<Link className="second-img" to={`/conversions/list`}>
																<img src={ConversionIcon} alt="" />
															</Link>
															<Link className="third-click-img disabled" to={`/assignedReps/list`}>
																<img src={SalesIcon} alt="" />
															</Link>
															<Link className="fourth-click-img" to={`/orders/list`}>
																<img src={orderIcon} alt="" />
															</Link>
															<Link className="cost_goods_report" to={`/inventoryCosts/report`}>
																<img src={saveOrderIcon} alt="" />
															</Link>
															<p className="manage">User Management</p>
															<p className="manage-cate">Conversions</p>
														</li>
														<li className="countorder disabled">
															<Link className="wookaisetup" to={`${selectedBusinessLocationObject.is_slave == 0 ? '/vendors/list' : '#'}`}>
																<img src={CountIcon} alt="" />
															</Link>
															<Link className="editParIcon" to={`/items/editParLevels`}>
																<img src={EditParLevelsIcon} alt="" />
															</Link>
															<Link className="second-img" to={`/categories/list`}>
																<img src={CategoriesIcon} alt="" />
															</Link>
															<Link className="third-click-img" to={`/users/list`}>
																<img src={assistantIcon} alt="" />
															</Link>
															<Link className="fourth-click-img" to={`/savedOrders/list`}>
																<img src={saveOrderIcon} alt="" />
															</Link>

															<p className="manage">Count & Order Supplies</p>
															<p className="manage-cate">Categories</p>
															<p className="manage-supply">Suppliers</p>
														</li>
														<li className="manageuser manageuserthird disabled">
															<a id="usermanage" className="wookaisetup-user disabled" href="javascript:void(0);">
																<img src={OrderReceiveIcon} alt="" />
															</a>
															<Link className="rebateIcon" to={`/`}>
																<img src={rebateIcon} alt='rebateIcon' />
															</Link>
															<Link className="second-img" to={`/conversions/list`}>
																<img src={ConversionIcon} alt="" />
															</Link>
															<Link className="third-click-img" to={`/assignedReps/list`}>
																<img src={SalesIcon} alt="" />
															</Link>
															<Link className="fourth-click-img" to={`/orders/list`}>
																<img src={orderIcon} alt="" />
															</Link>
															<p className="manage">User Management</p>
															<p className="manage-cate">Conversions</p>
														</li>
														<li className="manageuser manageuserfourth-text ">
															<a id="usermanage" className="wookaisetup-user disabled" href="javascript:void(0);">
																<img src={InventoryIcon} alt="" />
															</a>
															<Link to={`/ideal-product-cost/list`} className="idealIcon">
																<img src={IdealProductCostIcon} alt="" />
															</Link>
															<Link className="second-img" to={`/conversions/list`}>
																<img src={ConversionIcon} alt="" />
															</Link>
															<Link className="third-click-img" to={`/assignedReps/list`}>
																<img src={SalesIcon} alt="" />
															</Link>
															<Link className="fourth-click-img" to={`/orders/list`}>
																<img src={orderIcon} alt="" />
															</Link>
															<p className="manage">User Management</p>
															<p className="manage-cate">Conversions</p>
														</li>
													</ul>
												</Col>
												<Col lg="8" sm="8" xs="8">
													<div className="circle-logo-wrap">
														<ul className="left-text">
															<li className="first-text">
																<p className="manage wookaiOption disabled"><a href="javascript:void(0);">Measurements & Organization</a></p>
																<Link className='inventoryCount' to={`/inventoryCosts/list`}>
																	<p className=''>Inventory Count</p>
																</Link>
																<Link className="" to={`#`}>
																	<p className="managePriceText">Recipes</p>
																</Link>
																<p className="manage1 listItemText disabled">
																	<Link className="" to={`#`}>List</Link>
																</p>
																<p className="manage-cate disabled">
																	<Link className="" to={`#`}>
																		Units
																	</Link>
																</p>
																<p className="manage-supply">
																	<Link className="" to={'/vendors/list'}>
																		Suppliers
																	</Link>
																</p>
																<p className="manage-order">
																	<Link className="disabled " to={`#`}>
																		Special Order Items
																	</Link>
																</p>
																<p className='manageReport'>
																	<Link className='' to={`/reports/vendorPriceChange`}>Suppliers Price Change</Link>
																</p>
															</li>
															<li className="second-text PriceOption ">

																<p className="manage manage-user"> Supplier & User Management</p>
																<p className='suppliersAudit disabled'><Link to={`#`}>Suppliers Audit</Link></p>
																<p className="managePriceText manage">
																	<Link className="" to={`/items/pendingAdditionalInfo`}>Pending Additional Info</Link> </p>
																<p className="manage prepPlan">
																	<Link className="" to={`#`}>Prep Planning</Link>
																</p>
																<p className="manage-cate">
																	<Link className="" to={`/conversions/list`}>
																		Conversions
																	</Link>
																</p>
																<p className="manage-supply disabled">
																	<Link className="" to={`/assignedReps/list`}>
																		Sales Representatives
																	</Link>
																</p>
																<p className="manage-order">
																	<Link className="" to={`/orders/list`}>
																		Orders
																	</Link>
																</p>
																<p className='inventoryReport'>
																	<Link to={`/inventoryCosts/report`}>
																		Cost of Goods Report
																	</Link>
																</p>

															</li>
															<li className="second-text order-report-text disabled">
																<p className="manage manage-user12 manage-order1">Ordering & Receiving</p>
																<p className="manage-cate ">
																	<Link className="" to={`/conversions/list`}>
																		Ordering, receiving & Reporting
																	</Link>
																</p>
																<p className="manage-cate rebate">
																	<Link className="" to={`/reports/rebates`}>
																		Rebates
																	</Link>
																</p>
															</li>
															<li className="third-text disabled">

																<p className="manage">Count & Order Supplies</p>
																<p className="managePriceText manage">
																	<Link className="" to={`/items/editParLevels`}>Edit Par Levels</Link>
																</p>
																<p className="manage-cate">
																	<Link className="" to={`/categories/list`}>
																		Categories
																	</Link>
																</p>
																<p className="manage-supply">
																	<Link className="" to={`/users/list`}>
																		Supervisors/ Managers
																	</Link>
																</p>
																<p className="manage-order">
																	<Link to={`/savedOrders/list`}>
																		Saved Orders & App Exports
																	</Link>
																</p>

															</li>
															<li className="second-text count-order-text disabled">
																<Link className="" to={`/ideal-product-cost/list`}> <p className="manage idealCost">Menu Costing</p></Link>
																<p className="manage manage-user123">Inventory Cost of Goods</p>
																<p className="manage-cate">
																	<Link className="" to={`/conversions/list`}>
																		Count & Order Supplies
																	</Link>
																</p>
															</li>

														</ul>
														<div className="circle-logoinner disabled">
															<img className="logo_img logo-first" src={LogoImgFour} alt="" />
															<img className="logo_img nextimg" src={LogoImg} alt="" />
															<img className="inventory_img" src={Inventory} alt="inventoryImage" />
															<img className="wookai-setup-img" src={wookaiSetup} alt="" />
															<img className="user-management-img" src={UserManagement} alt="" />
															<img className="resporting-img" src={OrderingReporting} alt="" />
															<img className="itemListImg" src={itemListManagement} alt="" />
															<img className="priceManagement" src={priceManagement} alt="" />
															<img className='reoprtMain' src={ReportMain} alt="ReportsMainImage" />

														</div>
														<ul className="right-text">
															<li className="fourth-text ">
																<p className="manage PriceManage disabled">PREP PLANNING & <br />MENU ITEM SETUP</p>
																<Link to={`#`} className="suppliersUnrelated disabled">
																	Suppliers Unrelated <br /> Items
																</Link>
																<p className="itemListText manage-cate">
																	<Link className="" to={`/items/pendingUnits`}>Pending Units</Link>
																</p>
																<Link className='sub-cate' to={`/subCategories/list`}>
																	<p className="manage-cate  manage-suplier">Sub Categories</p>
																</Link>
															</li>
															<li className="fifth-text disabled">
																<p className="manage manage-order11 itemList">ITEM LIST MANAGEMENT</p>
																<p className='dailyProduction'>
																	<Link to={`/reports/priceHistory`}>Price <br />History</Link>
																</p>
																<p className="itemListText manage-cate">
																	<Link className="" to={`/items/pendingParLevels`}>Pending Par Levels</Link>
																</p>
																<Link to={`/itemLocations/list`}>
																	<p className="manage-cate">Items Locations</p>
																</Link>
																<Link className='menu_cost_price' to={`/reports/variance`}>
																	<p className="menu_cost_price_text">MenuMatic</p>
																</Link>
															</li>

															<li className="fifth-text schedule-text">
																<p className="reportClick manage schedule">Reports</p>
															</li>
															<li className="fifth-text help-text disabled">
																
																<a target = "_blank"  href="https://wookai.com/wookai-members-only">
																	<p className="manage help">HELP</p>
																</a>

															</li>
															<li className="sixth-text">
																<p className="manage">Manage Order & Receiving</p>
																<Link to={`/items/list`}>
																	<p className="manage-cate">Items</p>
																</Link>
																<p className="itemListText manage-cate">
																	<Link className="" to={`/items/sort`}>Sort</Link>
																</p>
															</li>
														</ul>
													</div>
												</Col>
												<Col lg="2" sm="2" xs="2" className="iconchange right-side">
													<ul className="icons">
														<li className="manageaccount managelocations disabled">
															<span className="priceManageIcon disabled">
																<img src={priceManageIcon} alt="" />
															</span>
															<Link className="dailyProductionIcon" >
																<img src={DailyProductionIcon} alt='DailyProductionIcon' />
															</Link>
															<Link className="suppliersUnrelatedIcon" to={`#`}>
																<img src={SuppliersUnrelatedItemsIcon} alt="" />
															</Link>
															<Link className="pendingUnitIcon" to={`/items/pendingUnits`}>
																<img src={PendingUnitsIcon} alt="" />
															</Link>
															<Link className="second-img" to={`/subCategories/list`}>
																<img src={SubCategoriesIcon} alt="" />
															</Link>
															<Link className="fourth-click-img" to={`/savedOrders/list`}>
																<img src={saveOrderIcon} alt="" />
															</Link>
															<p>Manage Storage Locations</p>
														</li>
														<li className="manageuser manageitems disabled">
															<span id="order-item" className="itemListIcon disabled" href="javascript:void(0);">
																<img src={ItemListManagement} alt="" />
															</span>
															<Link className='dailyProductionIcon' to={`/reports/prep-plan`}>
																<img src={priceHistory} alt="priceHistory" />
															</Link>
															<Link className="parLevelIcon" to={`/items/pendingParLevels`}>
																<img src={PendingParLevelsIcon} alt="" />
															</Link>
															<Link className="second-img2" to={`/itemLocations/list`}>
																<img src={ItemLocationIcon} alt="" />
															</Link>
															<Link className="second-img" to={`/itemLocations/list`}>
																<img src={ItemLocationIcon} alt="" />
															</Link>
															<Link className="menu_item_icon" to={`/reports/variance`}>
																<img src={MenuItemsCostPricing} alt="MenuItemsCostPricing"/>
															</Link>
															<p>Manage Items</p>
														</li>
														<li className="manageuser schedule-icon">
															<span id="order-item" className="wookaisetup-order reportClick" href="javascript:void(0);">
																<img src={ReportsIcon} alt="" />
															</span>
															<Link className="second-img" to={`/itemLocations/list`}>
																<img src={ItemLocationIcon} alt="" />
															</Link>
															<p>Manage Items</p>
														</li>
														<li className="manageuser help-icon disabled">
															<a target = "_blank" id="order-item" className="wookaisetup-order" href="https://wookai.com/wookai-members-only">
																<img src={HelpIcon} alt="" />
															</a>
															<Link className="second-img" to={`/itemLocations/list`}>
																<img src={ItemLocationIcon} alt="" />
															</Link>
															<p>Manage Items</p>
														</li>
														<li className="countorder mangeorder disabled">
															<Link className="wookaisetup " to={`/orders/create`}>
																<img src={OrderIcon} alt="" />
															</Link>
															<Link className="second-img" to={`/items/list`}>
																<img src={ItemIcon} alt="" />
															</Link>
															<Link className="sortItem" to={`/items/sort`}>
																<img src={sortIcon} alt="sortIcon" />
															</Link>
															<p>Manage Order & Receiving</p>
														</li>
													</ul>
												</Col>
											</Row>
										</div>
									</div>
								</Col>
							</Row>
							{/* Mobile Dashboard Start */}
							<div className="mobile-dashboard">
								<span className="back-btn mobilebtn" id="alternate">
									<img src={backIcon} alt="" />
								</span>
								<Row>
									<Col lg="12">
										<div id="firstclick" className="wookaicircleone firstclick disabled">
											<img className="rotating" src={circleone} alt="" />
											<div className="circletext wookaifirst-click">
												<img className="rotating123" src={wookaisetupIcon} alt="" />
												{/* <h2>WookAI Setup</h2> */}
												<h2>Measurements &<br/> Organization</h2>
											</div>
										</div>
										<div id="options-block-one" className="wookai-options">
											<ul>
												<li>
													<div className="wookaicircleone-inner ">
														<Link className="" to={`/units/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={HandIcon} alt="" />
																<h2>Units</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/conversions/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={ConversionIcon} alt="" />
																<h2>Conversions</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/categories/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={CategoriesIcon} alt="" />
																<h2>Categories</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/subCategories/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={SubCategoriesIcon} alt="" />
																<h2>Sub Categories</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/itemLocations/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={ItemLocationIcon} alt="" />
																<h2>Items Locations</h2>
															</div>
														</Link>
													</div>
												</li>
											</ul>
										</div>

										<div id="secondclick" className="wookaicircleone disabled">
											<img className="rotating" src={circleone} alt="" />
											<div className="circletext wookaifirst-click">
												<img className="rotating123" src={UserIcon} alt="" />
												<h2>Supplier & User <br /> Management</h2>
											</div>
										</div>

										<div id="options-second-block" className="wookai-options second-block">
											<ul>
												<li>
													<div className="wookaicircleone-inner ">
														<Link className="" to={`${selectedBusinessLocationObject.is_slave == 0 ? '/vendors/list' : '#'}`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={SupplierIcon} alt="" />
																<h2>Suppliers</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/assignedReps/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={SalesIcon} alt="" />
																<h2>Sales <br /> Representatives</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/users/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={assistantIcon} alt="" />
																<h2>Supervisors/ <br /> Managers</h2>
															</div>
														</Link>
													</div>
												</li>
											</ul>
										</div>

										<div id="thirdclick" className="wookaicircleone fourthclick disabled">
											<img className="rotating" src={circleone} alt="" />
											<div className="circletext wookaifirst-click">
												<img className="rotating123" src={OrderReceiveIcon} alt="" />
												<h2>Ordering & <br /> Receiving </h2>
											</div>
										</div>
										<div id="options-third-block" className="wookai-options second-block">
											<ul>
												<li>
													<div className="wookaicircleone-inner ">
														<Link to={`/emergencyItems/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={EmergencyIcon} alt="" />
																<h2>Special Order Items</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link to={`/orders/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={orderIcon} alt="" />
																<h2>Orders</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link to={`/savedOrders/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={saveOrderIcon} alt="" />
																<h2>Saved Orders & App Exports</h2>
															</div>
														</Link>
													</div>
												</li>
											</ul>
										</div>

										<div id='seventhclick' className={`wookaicircleone seventhclick`}>
											<img className="rotating" src={circleone} alt="" />
											<div className="circletext inventoryseventh-click">
												<img className="rotating123" src={InventoryIcon} alt="" />
												<h2>Inventory Cost <br /> of Goods</h2>
											</div>
										</div>

										<div id="options-seventh-block" className="inventory-options seventh-block">
											<ul>
												<li>
													<div className="wookaicircleone-inner ">
														<Link className="" to={`/procedures/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext inventoryseventh-click">
																<img className="rotating123" src={orderIcon} alt="orderIcon" />
																<h2>Inventory <br /> Counts</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/prep-plan/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext inventoryseventh-click">
																<img className="rotating123" src={saveOrderIcon} alt="" />
																<h2>Cost of Goods<br /> Report</h2>
															</div>
														</Link>
													</div>
												</li>
											</ul>
										</div>

										<div id="fourthclick" className="wookaicircleone fourthclick disabled">

											<img className="rotating" src={circleone} alt="" />
											<div className="circletext wookaifirst-click">
												<img className="rotating123" src={priceManageIcon} alt="" />
												<h2>Prep Planning &<br/> Menu Item Setup</h2>
											</div>
										</div>

										<div id="options-fourth-block" className="wookai-options fourth-block">
											<ul>
												<li>
													<div className="wookaicircleone-inner ">
														<Link className="" to={`/procedures/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={ProceduresIcon} alt="" />
																<h2>Recipes</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/prep-plan/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={PrepPlanningIcon} alt="" />
																<h2>Prep Planning</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/ideal-product-cost/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={IdealProductCostIcon} alt="" />
																<h2>Menu Item <br />Setup</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/ideal-product-cost/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={MenuItemsCostPricing} alt="MenuItemsCostPricing" />
																<h2>MenuMatic</h2>
															</div>
														</Link>
													</div>
												</li>
											</ul>
										</div>


										<div id="fifthclick" className="wookaicircleone fifthclick disabled">
											<img className="rotating" src={circleone} alt="" />
											<div className="circletext wookaifirst-click">
												<img className="rotating123" src={ItemListManagement} alt="" />
												<h2>Item List <br /> Management</h2>
											</div>
										</div>

										<div id="options-fifth-block" className="wookai-options fifth-block">
											<ul>
												<li>
													<div className="wookaicircleone-inner ">
														<Link className="" to={`/items/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={listIcon} alt="" />
																<h2>LIST</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/items/pendingUnits`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={PendingUnitsIcon} alt="" />
																<h2>PENDING UNITS</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/items/pendingAdditionalInfo`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={PendingAdditionalInfoIcon} alt="" />
																<h2>PENDING <br />ADDITIONAL <br /> INFO</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/users/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={PendingParLevelsIcon} alt="" />
																<h2>PENDING <br />PAR LEVELS</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/items/editParLevels`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={EditParLevelsIcon} alt="" />
																<h2>EDIT <br />PAR LEVELS</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/items/sort`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={sortIcon} alt="" />
																<h2>SORT</h2>
															</div>
														</Link>
													</div>
												</li>
											</ul>
										</div>
										<div id="sixthClick" className="wookaicircleone reportClickMobile disabled">
											<img className="rotating" src={circleone} alt="" />
											<div className="circletext wookaifirst-click">
												<img className="rotating123" src={ReportsIcon} alt="" />
												<h2>REPORTS</h2>
											</div>
										</div>
										<div id="options-sixth-block" className="wookai-options fifth-block">
											<ul>
												<li>
													<div className="wookaicircleone-inner ">
														<Link className="" to={`/reports/vendorPriceChange`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={SuppliersPriceChangeOrangeIcon} alt="" />
																<h2>Suppliers <br />Price Change</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/reports/vendorUnrelatedItems`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={SuppliersUnrelatedItemsIcon} alt="" />
																<h2>Suppliers <br />Unrelated Items</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/reports/vendorsAudit`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={SuppliersAuditIcon} alt="" />
																<h2>Suppliers Audit</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/reports/priceHistory`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={DailyProductionIcon} alt="" />
																<h2>Price History</h2>
															</div>
														</Link>
													</div>
												</li>
											</ul>
										</div>
										<div className="wookaicircleone thirdclick disabled">

											<img className="rotating" src={circleone} alt="" />
											<div className="circletext wookaifirst-click">
												<img className="rotating123" src={HelpIcon} alt="" />
												<h2>Help</h2>
											</div>
										</div>
									</Col>
								</Row>
							</div>
						</>
					}
					{
						((selectedBusinessLocationObject != null) && (selectedBusinessLocationObject.stripe_plan_id == Plans.WOOKAI_SUPPLY_LIST_MANAGEMENT_SUPPLIER_PRICE_MANAGEMENT_PRODUCT_MANAGEMENT || selectedBusinessLocationObject.stripe_plan_id == Plans.WOOKAI_SUPPLY_LIST_MANAGEMENT_SUPPLIER_PRICE_MANAGEMENT_PRODUCT_MANAGEMENT_ADDITIONAL))
						&&
						<>
							<Row>
								<Col lg="12">
									<div className="banner-wrap">
										<div className="banner-inner">
											<span className="back-btn" id="alternate">
												<img src={backIcon} alt="" />
											</span>
											<span className="back-btn2" id="alternate">
												<img src={backIcon} alt="" />
											</span>
											<span className="back-btn3" id="alternate">
												<img src={backIcon} alt="" />
											</span>
											<span className="back-btn4" id="alternate">
												<img src={backIcon} alt="" />
											</span>
											<span className="back-btn5" id="alternate">
												<img src={backIcon} alt="" />
											</span>
											<span className="back-btn6" id="alternate">
												<img src={backIcon} alt="" />
											</span>
											<span className="back-btn7" id="alternate">
												<img src={backIcon} alt="" />
											</span>
											<Row>
												<Col lg="2" sm="2" xs="2" className="iconchange">
													<ul className="icons firstthree">
														<li className={`manageaccount ${userType == 'Low-Level' ? '' : ''}`}>
															<a href="javascript:void(0);" className={`wookaisetup ${userType == 'Low-Level' ? 'disabled' : ''}`}>
																<img src={wookaisetupIcon} alt="" />
															</a>
															<Link className='SuppliersPriceChangeIcon' to={`/reports/vendorPriceChange`}>
																<img src={SuppliersPriceChangeOrangeIcon} alt="SuppliersPriceChangeIcon" />
															</Link>
															<Link className="procedureIcon" to={`/procedures/list`}>
																<img src={ProceduresIcon} alt="" />
															</Link>
															<Link className="listIcon" to={`/items/list`}>
																<img src={listIcon} alt="" />
															</Link>
															<Link className="second-img" to={`/units/list`}>
																<img src={HandIcon} alt="" />
															</Link>
															<Link className="third-click-img" to={`${selectedBusinessLocationObject.is_slave == 0 ? '/vendors/list' : '#'}`}>
																<img src={SupplierIcon} alt="" />
															</Link>
															<Link className="fourth-click-img" to={`/emergencyItems/list`}>
																<img src={EmergencyIcon} alt="" />
															</Link>
															<Link className="inventory_count_img" to={`/inventoryCosts/list`}>
																<img src={orderIcon} alt='order-icon' />
															</Link>
															<p className="manage">WookAI Setup</p>
															<p className="manage-cate">Units</p>
															<p className="manage-order">Special Order Items</p>
														</li>
														<li className={`manageuser PriceOption`}>
															<a id="usermanage" className={`wookaisetup-user`} href="javascript:void(0);">
																<img src={UserIcon} alt="" />
															</a>
															<Link className='suopilerAuditIcon ' to={`/reports/vendorsAudit`}>
																<img src={SuppliersAuditIcon} alt="SuppliersAuditIcon" />
															</Link>
															<Link className="prepPlanIcon" to={`/prep-plan/list`}>
																<img src={PrepPlanningIcon} alt="" />
																{/* </a>				 */}
															</Link>
															<Link className="pendingListIcon" to={`/items/pendingAdditionalInfo`}>
																<img src={PendingAdditionalInfoIcon} alt="" />
															</Link>
															<Link className="second-img" to={`/conversions/list`}>
																<img src={ConversionIcon} alt="" />
															</Link>
															<Link className="third-click-img" to={`/assignedReps/list`}>
																<img src={SalesIcon} alt="" />
															</Link>
															<Link className="fourth-click-img" to={`/orders/list`}>
																<img src={orderIcon} alt="" />
															</Link>
															<Link className="cost_goods_report" to={`/inventoryCosts/report`}>
																<img src={saveOrderIcon} alt="" />
															</Link>
															<p className="manage">User Management</p>
															<p className="manage-cate">Conversions</p>
														</li>
														<li className="countorder">
															<Link className="wookaisetup" to={`${selectedBusinessLocationObject.is_slave == 0 ? '/vendors/list' : '#'}`}>
																<img src={CountIcon} alt="" />
															</Link>
															<Link className="editParIcon" to={`/items/editParLevels`}>
																<img src={EditParLevelsIcon} alt="" />
															</Link>
															<Link className="second-img" to={`/categories/list`}>
																<img src={CategoriesIcon} alt="" />
															</Link>
															<Link className="third-click-img" to={`/users/list`}>
																<img src={assistantIcon} alt="" />
															</Link>
															<Link className="fourth-click-img" to={`/savedOrders/list`}>
																<img src={saveOrderIcon} alt="" />
															</Link>

															<p className="manage">Count & Order Supplies</p>
															<p className="manage-cate">Categories</p>
															<p className="manage-supply">Suppliers</p>
														</li>
														<li className="manageuser manageuserthird disabled">
															<a id="usermanage" className="wookaisetup-user " href="javascript:void(0);">
																<img src={OrderReceiveIcon} alt="" />
															</a>
															<Link className="rebateIcon" to={`/`}>
																<img src={rebateIcon} alt='rebateIcon' />
															</Link>
															<Link className="second-img" to={`/conversions/list`}>
																<img src={ConversionIcon} alt="" />
															</Link>
															<Link className="third-click-img" to={`/assignedReps/list`}>
																<img src={SalesIcon} alt="" />
															</Link>
															<Link className="fourth-click-img" to={`/orders/list`}>
																<img src={orderIcon} alt="" />
															</Link>
															<p className="manage">User Management</p>
															<p className="manage-cate">Conversions</p>
														</li>
														<li className="manageuser manageuserfourth-text">
															<a id="usermanage" className="wookaisetup-user inventoryClick" href="javascript:void(0);">
																<img src={InventoryIcon} alt="" />
															</a>
															<Link to={`/ideal-product-cost/list`} className="idealIcon">
																<img src={IdealProductCostIcon} alt="" />
															</Link>
															<Link className="second-img" to={`/conversions/list`}>
																<img src={ConversionIcon} alt="" />
															</Link>
															<Link className="third-click-img" to={`/assignedReps/list`}>
																<img src={SalesIcon} alt="" />
															</Link>
															<Link className="fourth-click-img" to={`/orders/list`}>
																<img src={orderIcon} alt="" />
															</Link>
															<p className="manage">User Management</p>
															<p className="manage-cate">Conversions</p>
														</li>
													</ul>
												</Col>
												<Col lg="8" sm="8" xs="8">
													<div className="circle-logo-wrap">
														<ul className="left-text">
															<li className="first-text">
																<p className={`manage wookaiOption ${userType == 'Low-Level' ? 'disabled' : ''}`}><a href="javascript:void(0);">Measurements & Organization</a></p>
																<Link className="" to={`/procedures/list`}>
																	<p className="managePriceText">Recipes</p>
																</Link>
																<p className="manage1 listItemText">
																	<Link className="" to={`/items/list`}>List</Link>
																</p>
																<p className="manage-cate">
																	<Link className="" to={`/units/list`}>
																		Units
																	</Link>
																</p>
																<p className="manage-supply">
																	<Link className="" to={`${selectedBusinessLocationObject.is_slave == 0 ? '/vendors/list' : '#'}`}>
																		Suppliers
																	</Link>
																</p>
																<p className="manage-order">
																	<Link className="" to={`/emergencyItems/list`}>
																		Special Order Items
																	</Link>
																</p>
																<p className='manageReport'>
																	<Link className='' to={`/reports/vendorPriceChange`}>Suppliers Price Change</Link>
																</p>
															</li>
															<li className="second-text PriceOption">

																<p className={`manage manage-user ${userType == 'Low-Level' ? 'disabled' : ''}`}>Supplier & User Management</p>
																<p className='suppliersAudit'><Link to={`/reports/vendorsAudit`}>Suppliers Audit</Link></p>
																<p className="managePriceText manage">
																	<Link className="" to={`/items/pendingAdditionalInfo`}>Pending Additional Info</Link> </p>
																<p className="manage prepPlan">
																	<Link className="" to={`/prep-plan/list`}>Prep Planning</Link>
																</p>
																<p className="manage-cate">
																	<Link className="" to={`/conversions/list`}>
																		Conversions
																	</Link>
																</p>
																<p className="manage-supply">
																	<Link className="" to={`/assignedReps/list`}>
																		Sales Representatives
																	</Link>
																</p>
																<p className="manage-order">
																	<Link className="" to={`/orders/list`}>
																		Orders
																	</Link>
																</p>

															</li>
															<li className="second-text order-report-text">
																<p className="manage manage-user12 manage-order1 disabled">Ordering & Receiving</p>
																<p className="manage-cate ">
																	<Link className="" to={`/conversions/list`}>
																		Ordering, receiving & Reporting
																	</Link>
																</p>
																<p className="manage-cate rebate">
																	<Link className="" to={`/reports/rebates`}>
																		Rebates
																	</Link>
																</p>
															</li>
															<li className="third-text">

																<p className="manage">Count & Order Supplies</p>
																<p className="managePriceText manage">
																	<Link className="" to={`/items/editParLevels`}>Edit Par Levels</Link>
																</p>
																<p className="manage-cate">
																	<Link className="" to={`/categories/list`}>
																		Categories
																	</Link>
																</p>
																<p className="manage-supply">
																	<Link className="" to={`/users/list`}>
																		Supervisors/ Managers
																	</Link>
																</p>
																<p className="manage-order">
																	<Link to={`/savedOrders/list`}>
																		Saved Orders & App Exports
																	</Link>
																</p>
															</li>
															<li className="second-text count-order-text">
																<Link className="" to={`/ideal-product-cost/list`}> <p className="manage idealCost">Menu Costing</p></Link>
																<p className="manage manage-user123">Inventory Cost of Goods</p>
																<p className="manage-cate">
																	<Link className="" to={`/conversions/list`}>
																		Count & Order Supplies
																	</Link>
																</p>
															</li>
														</ul>
														<div className="circle-logoinner">
															<img className="logo_img logo-first" src={LogoImgFour} alt="" />
															<img className="logo_img nextimg" src={LogoImg} alt="" />
															<img className="inventory_img" src={Inventory} alt="inventoryImage" />
															<img className="wookai-setup-img" src={wookaiSetup} alt="" />
															<img className="user-management-img" src={UserManagement} alt="" />
															<img className="resporting-img" src={OrderingReporting} alt="" />
															<img className="itemListImg" src={itemListManagement} alt="" />
															<img className="priceManagement" src={priceManagement} alt="" />
															<img className="reportImageMain" src={ReportMain} alt="" />

														</div>
														<ul className="right-text">
															<li className="fourth-text">
																<p className={`manage PriceManag`}>PREP PLANNING & <br />MENU ITEM SETUP</p>
																<Link to={`/reports/vendorUnrelatedItems`} className="suppliersUnrelated">
																	Suppliers Unrelated <br /> Items
																</Link>
																<p className="itemListText manage-cate">
																	<Link className="" to={`/items/pendingUnits`}>Pending Units</Link>
																</p>
																<Link className='sub-cate' to={`/subCategories/list`}>
																	<p className="manage-cate  manage-suplier">Sub Categories</p>
																</Link>
															</li>
															<li className="fifth-text">
																<p className={`manage manage-order11 itemList ${userType == 'Low-Level' ? 'disabled' : ''}`}>ITEM LIST MANAGEMENT</p>
																<p className='dailyProduction'>
																	<Link to={`/reports/priceHistory`}>Price <br />History</Link>
																</p>
																<p className="itemListText manage-cate">
																	<Link className="" to={`/items/pendingParLevels`}>Pending Par Levels</Link>
																</p>
																<Link to={`/itemLocations/list`}>
																	<p className="manage-cate">Items Locations</p>
																</Link>
																<Link className='menu_cost_price' to={`/reports/variance`}>
																	<p className="menu_cost_price_text">MenuMatic</p>
																</Link>
															</li>

															<li className="fifth-text schedule-text">
																<p className={`reportClick manage schedule`}>Reports</p>
															</li>
															<li className="fifth-text help-text">
																
																<a target = "_blank"  href="https://wookai.com/wookai-members-only">
																	<p className="manage help">HELP</p>
																</a>

															</li>
															<li className="sixth-text">
																<p className="manage">Manage Order & Receiving</p>
																<Link to={`/items/list`}>
																	<p className="manage-cate">Items</p>
																</Link>
																<p className="itemListText manage-cate">
																	<Link className="" to={`/items/sort`}>Sort</Link>
																</p>
															</li>
														</ul>
													</div>
												</Col>
												<Col lg="2" sm="2" xs="2" className="iconchange right-side">
													<ul className="icons">
														<li className={`manageaccount managelocations ${userType == 'Low-Level' ? 'disabled' : ''}`}>

															<span className={`priceManageIcon ${userType == 'Low-Level' ? 'disabled' : ''}`}>
																<img src={priceManageIcon} alt="" />
															</span>
															<Link className="dailyProductionIcon" >
																<img src={DailyProductionIcon} alt='DailyProductionIcon' />
															</Link>
															<Link className="suppliersUnrelatedIcon" to={`/reports/vendorUnrelatedItems`}>
																<img src={SuppliersUnrelatedItemsIcon} alt="" />
															</Link>
															<Link className="pendingUnitIcon" to={`/items/pendingUnits`}>
																<img src={PendingUnitsIcon} alt="" />
															</Link>
															<Link className="second-img" to={`/subCategories/list`}>
																<img src={SubCategoriesIcon} alt="" />
															</Link>
															<Link className="fourth-click-img" to={`/savedOrders/list`}>
																<img src={saveOrderIcon} alt="" />
															</Link>
															<p>Manage Storage Locations</p>
														</li>
														<li className={`manageuser manageitems ${userType == 'Low-Level' ? '' : ''}`}>
															<span id="order-item" className={`itemListIcon ${userType == 'Low-Level' ? 'disabled' : ''}`} href="javascript:void(0);">
																<img src={ItemListManagement} alt="" />
															</span>
															<Link className='dailyProductionIcon' to={`/reports/prep-plan`}>
																<img src={priceHistory} alt="priceHistory" />
															</Link>
															<Link className="parLevelIcon" to={`/items/pendingParLevels`}>
																<img src={PendingParLevelsIcon} alt="" />
															</Link>
															<Link className="second-img2" to={`/itemLocations/list`}>
																<img src={ItemLocationIcon} alt="" />
															</Link>
															<Link className="second-img" to={`/itemLocations/list`}>
																<img src={ItemLocationIcon} alt="" />
															</Link>
															<Link className="menu_item_icon" to={`/reports/variance`}>
																<img src={MenuItemsCostPricing} alt="MenuItemsCostPricing"/>
															</Link>
															<p>Manage Items</p>
														</li>
														<li className={`manageuser schedule-icon`}>

															<span id="order-item" className={`wookaisetup-order reportClick`} href="javascript:void(0);">

																<img src={ReportsIcon} alt="" />
															</span>
															<Link className="second-img" to={`/itemLocations/list`}>
																<img src={ItemLocationIcon} alt="" />
															</Link>
															<p>Manage Items</p>
														</li>
														<li className="manageuser help-icon">
															<a target = "_blank" id="order-item" className="wookaisetup-order" href="https://wookai.com/wookai-members-only">
																<img src={HelpIcon} alt="" />
															</a>
															<Link className="second-img" to={`/itemLocations/list`}>
																<img src={ItemLocationIcon} alt="" />
															</Link>
															<p>Manage Items</p>
														</li>
														<li className="countorder mangeorder">
															<Link className="wookaisetup" to={`/orders/create`}>
																<img src={OrderIcon} alt="" />
															</Link>
															<Link className="second-img" to={`/items/list`}>
																<img src={ItemIcon} alt="" />
															</Link>
															<Link className="sortItem" to={`/items/sort`}>
																<img src={sortIcon} alt="sortIcon" />
															</Link>
															<p>Manage Order & Receiving</p>
														</li>
													</ul>
												</Col>
											</Row>
										</div>
									</div>
								</Col>
							</Row>
							<div className="mobile-dashboard">
								<span className="back-btn mobilebtn" id="alternate">
									<img src={backIcon} alt="" />
								</span>
								<Row>
									<Col lg="12">
										<div id="firstclick" className={`wookaicircleone firstclick  ${userType == 'Low-Level' ? 'disabled' : ''}`}>
											<img className="rotating" src={circleone} alt="" />
											<div className="circletext wookaifirst-click">
												<img className="rotating123" src={wookaisetupIcon} alt="" />
												{/* <h2>WookAI Setup</h2> */}
												<h2>Measurements &<br/> Organization</h2>
											</div>
										</div>
										<div id="options-block-one" className="wookai-options">
											<ul>
												<li>
													<div className="wookaicircleone-inner ">
														<Link className="" to={`/units/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={HandIcon} alt="" />
																<h2>Units</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/conversions/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={ConversionIcon} alt="" />
																<h2>Conversions</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/categories/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={CategoriesIcon} alt="" />
																<h2>Categories</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/subCategories/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={SubCategoriesIcon} alt="" />
																<h2>Sub Categories</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/itemLocations/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={ItemLocationIcon} alt="" />
																<h2>Items Locations</h2>
															</div>
														</Link>
													</div>
												</li>
											</ul>
										</div>

										<div id="secondclick" className={`wookaicircleone   ${userType == 'Low-Level' ? 'disabled' : ''}`}>
											<img className="rotating" src={circleone} alt="" />
											<div className="circletext wookaifirst-click">
												<img className="rotating123" src={UserIcon} alt="" />
												<h2>Supplier & User <br /> Management</h2>
											</div>
										</div>

										<div id="options-second-block" className="wookai-options second-block">
											<ul>
												<li>
													<div className="wookaicircleone-inner ">
														<Link className="" to={`${selectedBusinessLocationObject.is_slave == 0 ? '/vendors/list' : '#'}`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={SupplierIcon} alt="" />
																<h2>Suppliers</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/assignedReps/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={SalesIcon} alt="" />
																<h2>Sales <br /> Representatives</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/users/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={assistantIcon} alt="" />
																<h2>Supervisors/ <br /> Managers</h2>
															</div>
														</Link>
													</div>
												</li>
											</ul>
										</div>

										<div id="thirdclick" className="wookaicircleone fourthclick">
											<img className="rotating" src={circleone} alt="" />
											<div className="circletext wookaifirst-click">
												<img className="rotating123" src={OrderReceiveIcon} alt="" />
												<h2>Ordering & <br /> Receiving </h2>
											</div>
										</div>
										<div id="options-third-block" className="wookai-options second-block">
											<ul>
												<li>
													<div className="wookaicircleone-inner ">
														<Link to={`/emergencyItems/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={EmergencyIcon} alt="" />
																<h2>Special Order Items</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link to={`/orders/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={orderIcon} alt="" />
																<h2>Orders</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link to={`/savedOrders/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={saveOrderIcon} alt="" />
																<h2>Saved Orders & App Exports</h2>
															</div>
														</Link>
													</div>
												</li>
											</ul>
										</div>

										<div id='seventhclick' className={`wookaicircleone seventhclick`}>

											<img className="rotating" src={circleone} alt="" />
											<div className="circletext inventoryseventh-click">
												<img className="rotating123" src={InventoryIcon} alt="" />
												<h2>Inventory Cost <br /> of Goods</h2>
											</div>

										</div>

										<div id="options-seventh-block" className="inventory-options seventh-block">
											<ul>
												<li>
													<div className="wookaicircleone-inner ">
														<Link className="" to={`/procedures/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext inventoryseventh-click">
																<img className="rotating123" src={orderIcon} alt="orderIcon" />
																<h2>Inventory <br /> Counts</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/prep-plan/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext inventoryseventh-click">
																<img className="rotating123" src={saveOrderIcon} alt="" />
																<h2>Cost of Goods<br /> Report</h2>
															</div>
														</Link>
													</div>
												</li>
											</ul>
										</div>

										<div id="fourthclick" className={`wookaicircleone fourthclick`}>

											<img className="rotating" src={circleone} alt="" />
											<div className="circletext wookaifirst-click">
												<img className="rotating123" src={priceManageIcon} alt="" />
												<h2>Prep Planning &<br/> Menu Item Setup</h2>
											</div>
										</div>

										<div id="options-fourth-block" className="wookai-options fourth-block">
											<ul>
												<li>
													<div className="wookaicircleone-inner ">
														<Link className="" to={`/procedures/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={ProceduresIcon} alt="" />
																<h2>Recipes</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/prep-plan/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={PrepPlanningIcon} alt="" />
																<h2>PREP PLANNING</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/ideal-product-cost/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={IdealProductCostIcon} alt="" />
																<h2>Menu Item <br />Setup</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/ideal-product-cost/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={MenuItemsCostPricing} alt="MenuItemsCostPricing" />
																<h2>MenuMatic</h2>
															</div>
														</Link>
													</div>
												</li>
											</ul>
										</div>


										<div id="fifthclick" className={`wookaicircleone fifthclick  ${userType == 'Low-Level' ? 'disabled' : ''}`}>
											<img className="rotating" src={circleone} alt="" />
											<div className="circletext wookaifirst-click">
												<img className="rotating123" src={ItemListManagement} alt="" />
												<h2>Item List <br /> Management</h2>
											</div>
										</div>

										<div id="options-fifth-block" className="wookai-options fifth-block">
											<ul>
												<li>
													<div className="wookaicircleone-inner ">
														<Link className="" to={`/items/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={listIcon} alt="" />
																<h2>LIST</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/items/pendingUnits`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={PendingUnitsIcon} alt="" />
																<h2>PENDING UNITS</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/items/pendingAdditionalInfo`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={PendingAdditionalInfoIcon} alt="" />
																<h2>PENDING <br />ADDITIONAL <br /> INFO</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/users/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={PendingParLevelsIcon} alt="" />
																<h2>PENDING <br />PAR LEVELS</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/items/editParLevels`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={EditParLevelsIcon} alt="" />
																<h2>EDIT <br />PAR LEVELS</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/items/sort`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={sortIcon} alt="" />
																<h2>SORT</h2>
															</div>
														</Link>
													</div>
												</li>
											</ul>
										</div>

										<div id="sixthClick" className={`wookaicircleone reportClickMobile`}>
											<img className="rotating" src={circleone} alt="" />
											<div className="circletext wookaifirst-click">
												<img className="rotating123" src={ReportsIcon} alt="" />
												<h2>REPORTS</h2>
											</div>
										</div>
										<div id="options-sixth-block" className="wookai-options fifth-block">
											<ul>
												<li>
													<div className="wookaicircleone-inner ">
														<Link className="" to={`/reports/vendorPriceChange`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={SuppliersPriceChangeOrangeIcon} alt="" />
																<h2>SUPPLIERS <br />PRICE CHANGE</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/reports/vendorUnrelatedItems`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={SuppliersUnrelatedItemsIcon} alt="" />
																<h2>Suppliers <br />Unrelated Items</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/reports/vendorsAudit`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={SuppliersAuditIcon} alt="" />
																<h2>Suppliers Audit</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/reports/priceHistory`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={DailyProductionIcon} alt="" />
																<h2>Price History</h2>
															</div>
														</Link>
													</div>
												</li>
											</ul>
										</div>
										<div className="wookaicircleone thirdclick">
											<img className="rotating" src={circleone} alt="" />
											<div className="circletext wookaifirst-click">
												<img className="rotating123" src={HelpIcon} alt="" />
												<h2>HELP</h2>
											</div>
										</div>
									</Col>
								</Row>
							</div>
						</>
					}

					{
						((selectedBusinessLocationObject != null) && (selectedBusinessLocationObject.stripe_plan_id == Plans.WOOKAI_SUPPLY_LIST_MANAGEMENT_SUPPLIER_PRICE_MANAGEMENT_ORDERING_RECEIVING || selectedBusinessLocationObject.stripe_plan_id == Plans.WOOKAI_SUPPLY_LIST_MANAGEMENT_SUPPLIER_PRICE_MANAGEMENT_ORDERING_RECEIVING_ADDITIONAL))
						&&
						<>
							<Row>
								<Col lg="12">
									<div className="banner-wrap">
										<div className="banner-inner">
											<span className="back-btn" id="alternate">
												<img src={backIcon} alt="" />
											</span>
											<span className="back-btn2" id="alternate">
												<img src={backIcon} alt="" />
											</span>
											<span className="back-btn3" id="alternate">
												<img src={backIcon} alt="" />
											</span>
											<span className="back-btn4" id="alternate">
												<img src={backIcon} alt="" />
											</span>
											<span className="back-btn5" id="alternate">
												<img src={backIcon} alt="" />
											</span>
											<span className="back-btn6" id="alternate">
												<img src={backIcon} alt="" />
											</span>
											<span className="back-btn7" id="alternate">
												<img src={backIcon} alt="" />
											</span>
											<Row>
												<Col lg="2" sm="2" xs="2" className="iconchange">
													<ul className="icons firstthree">
														<li className={`manageaccount ${userType == 'Low-Level' ? '' : ''}`}>
															<a href="javascript:void(0);" className={`wookaisetup ${userType == 'Low-Level' ? 'disabled' : ''}`}>
																<img src={wookaisetupIcon} alt="" />
															</a>
															<Link className='SuppliersPriceChangeIcon' to={`/reports/vendorPriceChange`}>
																<img src={SuppliersPriceChangeOrangeIcon} alt="SuppliersPriceChangeIcon" />
															</Link>
															<Link className="procedureIcon" to={`/procedures/list`}>
																<img src={ProceduresIcon} alt="" />
															</Link>
															<Link className="listIcon" to={`/items/list`}>
																<img src={listIcon} alt="" />
															</Link>
															<Link className="second-img" to={`/units/list`}>
																<img src={HandIcon} alt="" />
															</Link>
															<Link className="third-click-img" to={`${selectedBusinessLocationObject.is_slave == 0 ? '/vendors/list' : '#'}`}>
																<img src={SupplierIcon} alt="" />
															</Link>
															<Link className="fourth-click-img" to={`/emergencyItems/list`}>
																<img src={EmergencyIcon} alt="" />
															</Link>
															<Link className="inventory_count_img" to={`/inventoryCosts/list`}>
																<img src={orderIcon} alt='order-icon' />
															</Link>
															<p className="manage">WookAI Setup</p>
															<p className="manage-cate">Units</p>
															<p className="manage-order">Special Order Items</p>
														</li>
														<li className={`manageuser PriceOption ${userType == 'Low-Level' ? '' : ''}`}>
															<a id="usermanage" className={`wookaisetup-user ${userType == 'Low-Level' ? 'disabled' : ''} `} href="javascript:void(0);">
																<img src={UserIcon} alt="" />
															</a>
															<Link className='suopilerAuditIcon' to={`/reports/vendorsAudit`}>
																<img src={SuppliersAuditIcon} alt="SuppliersAuditIcon" />
															</Link>
															<Link className="prepPlanIcon" to={`/prep-plan/list`}>
																<img src={PrepPlanningIcon} alt="" />
															</Link>
															<Link className="pendingListIcon" to={`/items/pendingAdditionalInfo`}>
																<img src={PendingAdditionalInfoIcon} alt="" />
															</Link>
															<Link className="second-img" to={`/conversions/list`}>
																<img src={ConversionIcon} alt="" />
															</Link>
															<Link className="third-click-img" to={`/assignedReps/list`}>
																<img src={SalesIcon} alt="" />
															</Link>
															<Link className="fourth-click-img" to={`/orders/list`}>
																<img src={orderIcon} alt="" />
															</Link>
															<Link className="cost_goods_report" to={`/inventoryCosts/report`}>
																<img src={saveOrderIcon} alt="" />
															</Link>
															<p className="manage">User Management</p>
															<p className="manage-cate">Conversions</p>
														</li>
														<li className="countorder">
															<Link className="wookaisetup" to={`${selectedBusinessLocationObject.is_slave == 0 ? '/vendors/list' : '#'}`}>
																<img src={CountIcon} alt="" />
															</Link>
															<Link className="editParIcon" to={`/items/editParLevels`}>
																<img src={EditParLevelsIcon} alt="" />
															</Link>
															<Link className="second-img" to={`/categories/list`}>
																<img src={CategoriesIcon} alt="" />
															</Link>
															<Link className="third-click-img" to={`/users/list`}>
																<img src={assistantIcon} alt="" />
															</Link>
															<Link className="fourth-click-img" to={`/savedOrders/list`}>
																<img src={saveOrderIcon} alt="" />
															</Link>

															<p className="manage">Count & Order Supplies</p>
															<p className="manage-cate">Categories</p>
															<p className="manage-supply">Suppliers</p>
														</li>
														<li className="manageuser manageuserthird">
															<a id="usermanage" className="wookaisetup-user " href="javascript:void(0);">
																<img src={OrderReceiveIcon} alt="" />
															</a>
															<Link className="rebateIcon" to={`/`}>
																<img src={rebateIcon} alt='rebateIcon' />
															</Link>
															<Link className="second-img" to={`/conversions/list`}>
																<img src={ConversionIcon} alt="" />
															</Link>
															<Link className="third-click-img" to={`/assignedReps/list`}>
																<img src={SalesIcon} alt="" />
															</Link>
															<Link className="fourth-click-img" to={`/orders/list`}>
																<img src={orderIcon} alt="" />
															</Link>
															<p className="manage">User Management</p>
															<p className="manage-cate">Conversions</p>
														</li>
														<li className="manageuser manageuserfourth-text">
															<a id="usermanage" className="wookaisetup-user inventoryClick" href="javascript:void(0);">
																<img src={InventoryIcon} alt="" />
															</a>
															<Link to={`/ideal-product-cost/list`} className="idealIcon">
																<img src={IdealProductCostIcon} alt="" />
															</Link>
															<Link className="second-img" to={`/conversions/list`}>
																<img src={ConversionIcon} alt="" />
															</Link>
															<Link className="third-click-img" to={`/assignedReps/list`}>
																<img src={SalesIcon} alt="" />
															</Link>
															<Link className="fourth-click-img" to={`/orders/list`}>
																<img src={orderIcon} alt="" />
															</Link>
															<p className="manage">User Management</p>
															<p className="manage-cate">Conversions</p>
														</li>
													</ul>
												</Col>
												<Col lg="8" sm="8" xs="8">
													<div className="circle-logo-wrap">
														<ul className="left-text">
															<li className="first-text">
																<p className={`manage wookaiOption ${userType == 'Low-Level' ? 'disabled' : ''}`}><a href="javascript:void(0);">Measurements & Organization</a></p>
																<Link className="" to={`/procedures/list`}>
																	<p className="managePriceText">Recipes</p>
																</Link>
																<Link className='inventoryCount' to={`/inventoryCosts/list`}>
																	<p className=''>Inventory Count</p>
																</Link>
																<p className="manage1 listItemText">
																	<Link className="" to={`/items/list`}>List</Link>
																</p>
																<p className="manage-cate">
																	<Link className="" to={`/units/list`}>
																		Units
																	</Link>
																</p>
																<p className="manage-supply">
																	<Link className="" to={`${selectedBusinessLocationObject.is_slave == 0 ? '/vendors/list' : '#'}`}>
																		Suppliers
																	</Link>
																</p>
																<p className="manage-order">
																	<Link className="" to={`/emergencyItems/list`}>
																		Special Order Items
																	</Link>
																</p>
																<p className='manageReport'>
																	<Link className='' to={`/reports/vendorPriceChange`}>Suppliers Price Change</Link>
																</p>
															</li>
															<li className="second-text PriceOption">

																<p className='suppliersAudit'><Link to={`/reports/vendorsAudit`}>Suppliers Audit</Link></p>
																<p className={`manage manage-user ${userType == 'Low-Level' ? 'disabled' : ''}`}>Supplier & User Management</p>
																<p className="managePriceText manage">
																	<Link className="" to={`/items/pendingAdditionalInfo`}>Pending Additional Info</Link> </p>
																<p className="manage prepPlan">
																	<Link className="" to={`/prep-plan/list`}>Prep Planning</Link>
																</p>
																<p className="manage-cate">
																	<Link className="" to={`/conversions/list`}>
																		Conversions
																	</Link>
																</p>
																<p className="manage-supply">
																	<Link className="" to={`/assignedReps/list`}>
																		Sales Representatives
																	</Link>
																</p>
																<p className="manage-order">
																	<Link className="" to={`/orders/list`}>
																		Orders
																	</Link>
																</p>
																<p className='inventoryReport'>
																	<Link to={`/inventoryCosts/report`}>
																		Cost of Goods Report
																	</Link>
																</p>
															</li>
															<li className="second-text order-report-text">
																<p className="manage manage-user12 manage-order1">Ordering & Receiving</p>
																<p className="manage-cate ">
																	<Link className="" to={`/conversions/list`}>
																		Ordering, receiving & Reporting
																	</Link>
																</p>
																<p className="manage-cate rebate">
																	<Link className="" to={`/reports/rebates`}>
																		Rebates
																	</Link>
																</p>
															</li>
															<li className="third-text">

																<p className="manage">Count & Order Supplies</p>
																<p className="managePriceText manage">
																	<Link className="" to={`/items/editParLevels`}>Edit Par Levels</Link>
																</p>
																<p className="manage-cate">
																	<Link className="" to={`/categories/list`}>
																		Categories
																	</Link>
																</p>
																<p className="manage-supply">
																	<Link className="" to={`/users/list`}>
																		Supervisors/ Managers
																	</Link>
																</p>
																<p className="manage-order">
																	<Link to={`/savedOrders/list`}>
																		Saved Orders & App Exports
																	</Link>
																</p>
															</li>
															<li className="second-text count-order-text">
																<Link className="" to={`/ideal-product-cost/list`}> <p className="manage idealCost">Menu Costing</p></Link>
																<p className="manage manage-user123">Inventory Cost of Goods</p>
																<p className="manage-cate">
																	<Link className="" to={`/conversions/list`}>
																		Count & Order Supplies
																	</Link>
																</p>
															</li>

														</ul>
														<div className="circle-logoinner">
															<img className="logo_img logo-first" src={LogoImgFour} alt="" />
															<img className="logo_img nextimg" src={LogoImg} alt="" />
															<img className="inventory_img" src={Inventory} alt="inventoryImage" />

															<img className="wookai-setup-img" src={wookaiSetup} alt="" />
															<img className="user-management-img" src={UserManagement} alt="" />
															<img className="resporting-img" src={OrderingReporting} alt="" />
															<img className="itemListImg" src={itemListManagement} alt="" />
															<img className="priceManagement" src={priceManagement} alt="" />
															<img className="reportImageMain" src={ReportMain} alt="" />

														</div>
														<ul className="right-text">
															<li className="fourth-text">
																<p className="manage PriceManage disabled">PREP PLANNING & <br />MENU ITEM SETUP</p>
																<Link to={`/reports/vendorUnrelatedItems`} className="suppliersUnrelated">
																	Suppliers Unrelated <br /> Items
																</Link>
																<p className="itemListText manage-cate">
																	<Link className="" to={`/items/pendingUnits`}>Pending Units</Link>
																</p>
																<Link className='sub-cate' to={`/subCategories/list`}>
																	<p className="manage-cate  manage-suplier">Sub Categories</p>
																</Link>
															</li>

															<li className="fifth-text">
																<p className={`manage manage-order11 itemList ${userType == 'Low-Level' ? 'disabled' : ''}`}>ITEM LIST MANAGEMENT</p>
																<p className='dailyProduction'>
																	<Link to={`/reports/priceHistory`}>Price <br />History</Link>
																</p>
																<p className="itemListText manage-cate">
																	<Link className="" to={`/items/pendingParLevels`}>Pending Par Levels</Link>
																</p>
																<Link to={`/itemLocations/list`}>
																	<p className="manage-cate">Items Locations</p>
																</Link>
																<Link className='menu_cost_price' to={`/reports/variance`}>
																	<p className="menu_cost_price_text">MenuMatic</p>
																</Link>
															</li>

															<li className="fifth-text schedule-text">
																<p className={`reportClick manage schedule`}>Reports</p>
															</li>
															<li className="fifth-text help-text">
																
																<a target = "_blank"  href="https://wookai.com/wookai-members-only">
																	<p className="manage help">HELP</p>
																</a>

															</li>
															<li className="sixth-text">
																<p className="manage">Manage Order & Receiving</p>
																<Link to={`/items/list`}>
																	<p className="manage-cate">Items</p>
																</Link>
																<p className="itemListText manage-cate">
																	<Link className="" to={`/items/sort`}>Sort</Link>
																</p>
															</li>
														</ul>
													</div>
												</Col>
												<Col lg="2" sm="2" xs="2" className="iconchange right-side">
													<ul className="icons">
														<li className={`manageaccount managelocations`}>
															<span className="priceManageIcon disabled">
																<img src={priceManageIcon} alt="" />
															</span>
															<Link className="dailyProductionIcon" >
																<img src={DailyProductionIcon} alt='DailyProductionIcon' />
															</Link>
															<Link className="dailyProductionIcon" >
																<img src={DailyProductionIcon} alt='DailyProductionIcon' />
															</Link>
															<Link className="suppliersUnrelatedIcon" to={`/reports/vendorUnrelatedItems`}>
																<img src={SuppliersUnrelatedItemsIcon} alt="" />
															</Link>
															<Link className="pendingUnitIcon" to={`/items/pendingUnits`}>
																<img src={PendingUnitsIcon} alt="" />
															</Link>
															<Link className="second-img" to={`/subCategories/list`}>
																<img src={SubCategoriesIcon} alt="" />
															</Link>
															<Link className="fourth-click-img" to={`/savedOrders/list`}>
																<img src={saveOrderIcon} alt="" />
															</Link>
															<p>Manage Storage Locations</p>
														</li>
														<li className={`manageuser manageitems ${userType == 'Low-Level' ? '' : ''}`}>
															<span id="order-item" className={`itemListIcon ${userType == 'Low-Level' ? 'disabled' : ''}`} href="javascript:void(0);">
																<img src={ItemListManagement} alt="" />
															</span>
															<Link className='dailyProductionIcon' to={`/reports/prep-plan`}>
																{/* <img src={DailyProductionIcon} alt="SuppliersAuditIcon123"/> */}
																<img src={priceHistory} alt="priceHistory" />
															</Link>
															<Link className="parLevelIcon" to={`/items/pendingParLevels`}>
																<img src={PendingParLevelsIcon} alt="" />
															</Link>
															<Link className="second-img2" to={`/itemLocations/list`}>
																<img src={ItemLocationIcon} alt="" />
															</Link>
															<Link className="second-img" to={`/itemLocations/list`}>
																<img src={ItemLocationIcon} alt="" />
															</Link>
															<Link className="menu_item_icon" to={`/reports/variance`}>
																<img src={MenuItemsCostPricing} alt="MenuItemsCostPricing"/>
															</Link>
															<p>Manage Items</p>
														</li>
														<li className={`manageuser schedule-icon`}>

															{/* <Link to={`/reports/vendorPriceChange`}> */}
															<span id="order-item" className={`wookaisetup-order reportClick`} href="javascript:void(0);">
																<img src={ReportsIcon} alt="" />
															</span>
															{/* </Link> */}
															<Link className="second-img" to={`/itemLocations/list`}>
																<img src={ItemLocationIcon} alt="" />
															</Link>
															<p>Manage Items</p>
														</li>
														<li className="manageuser help-icon">
															<a target = "_blank" id="order-item" className="wookaisetup-order" href="https://wookai.com/wookai-members-only">
																<img src={HelpIcon} alt="" />
															</a>
															<Link className="second-img" to={`/itemLocations/list`}>
																<img src={ItemLocationIcon} alt="" />
															</Link>
															<p>Manage Items</p>
														</li>
														<li className="countorder mangeorder">
															<Link className="wookaisetup" to={`/orders/create`}>
																<img src={OrderIcon} alt="" />
															</Link>
															<Link className="second-img" to={`/items/list`}>
																<img src={ItemIcon} alt="" />
															</Link>
															<Link className="sortItem" to={`/items/sort`}>
																<img src={sortIcon} alt="sortIcon" />
															</Link>
															<p>Manage Order & Receiving</p>
														</li>
													</ul>
												</Col>
											</Row>
										</div>
									</div>
								</Col>
							</Row>
							<div className="mobile-dashboard">
								<span className="back-btn mobilebtn" id="alternate">
									<img src={backIcon} alt="" />
								</span>
								<Row>
									<Col lg="12">
										<div id="firstclick" className={`wookaicircleone firstclick  ${userType == 'Low-Level' ? 'disabled' : ''}`}>
											<img className="rotating" src={circleone} alt="" />
											<div className="circletext wookaifirst-click">
												<img className="rotating123" src={wookaisetupIcon} alt="" />
												{/* <h2>WookAI Setup</h2> */}
												<h2>Measurements &<br/> Organization</h2>
											</div>
										</div>
										<div id="options-block-one" className="wookai-options">
											<ul>
												<li>
													<div className="wookaicircleone-inner ">
														<Link className="" to={`/units/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={HandIcon} alt="" />
																<h2>Units</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/conversions/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={ConversionIcon} alt="" />
																<h2>Conversions</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/categories/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={CategoriesIcon} alt="" />
																<h2>Categories</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/subCategories/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={SubCategoriesIcon} alt="" />
																<h2>Sub Categories</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/itemLocations/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={ItemLocationIcon} alt="" />
																<h2>Items Locations</h2>
															</div>
														</Link>
													</div>
												</li>
											</ul>
										</div>

										<div id="secondclick" className={`wookaicircleone   ${userType == 'Low-Level' ? 'disabled' : ''}`}>
											<img className="rotating" src={circleone} alt="" />
											<div className="circletext wookaifirst-click">
												<img className="rotating123" src={UserIcon} alt="" />
												<h2>Supplier & User <br /> Management</h2>
											</div>
										</div>

										<div id="options-second-block" className="wookai-options second-block">
											<ul>
												<li>
													<div className="wookaicircleone-inner ">
														<Link className="" to={`${selectedBusinessLocationObject.is_slave == 0 ? '/vendors/list' : '#'}`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={SupplierIcon} alt="" />
																<h2>Suppliers</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/assignedReps/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={SalesIcon} alt="" />
																<h2>Sales <br /> Representatives</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/users/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={assistantIcon} alt="" />
																<h2>Supervisors/ <br /> Managers</h2>
															</div>
														</Link>
													</div>
												</li>
											</ul>
										</div>

										<div id="thirdclick" className="wookaicircleone fourthclick">
											<img className="rotating" src={circleone} alt="" />
											<div className="circletext wookaifirst-click">
												<img className="rotating123" src={OrderReceiveIcon} alt="" />
												<h2>Ordering & <br /> Receiving </h2>
											</div>
										</div>
										<div id="options-third-block" className="wookai-options second-block">
											<ul>
												<li>
													<div className="wookaicircleone-inner ">
														<Link to={`/emergencyItems/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={EmergencyIcon} alt="" />
																<h2>Special Order Items</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link to={`/orders/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={orderIcon} alt="" />
																<h2>Orders</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link to={`/savedOrders/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={saveOrderIcon} alt="" />
																<h2>Saved Orders & App Exports</h2>
															</div>
														</Link>
													</div>
												</li>
											</ul>
										</div>
										<div id='seventhclick' className={`wookaicircleone seventhclick`}>

											<img className="rotating" src={circleone} alt="" />
											<div className="circletext inventoryseventh-click">
												<img className="rotating123" src={InventoryIcon} alt="" />
												<h2>Inventory Cost <br /> of Goods</h2>
											</div>

										</div>

										<div id="options-seventh-block" className="inventory-options seventh-block">
											<ul>
												<li>
													<div className="wookaicircleone-inner ">
														<Link className="" to={`/procedures/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext inventoryseventh-click">
																<img className="rotating123" src={orderIcon} alt="orderIcon" />
																<h2>Inventory <br /> Counts</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/prep-plan/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext inventoryseventh-click">
																<img className="rotating123" src={saveOrderIcon} alt="" />
																<h2>Cost of Goods<br /> Report</h2>
															</div>
														</Link>
													</div>
												</li>
											</ul>
										</div>

										<div id="fourthclick" className={`wookaicircleone fourthclick`}>

											<img className="rotating" src={circleone} alt="" />
											<div className="circletext wookaifirst-click">
												<img className="rotating123" src={priceManageIcon} alt="" />
												<h2>Prep Planning &<br/> Menu Item Setup</h2>
											</div>
										</div>

										<div id="options-fourth-block" className="wookai-options fourth-block">
											<ul>
												<li>
													<div className="wookaicircleone-inner ">
														<Link className="" to={`/procedures/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={ProceduresIcon} alt="" />
																<h2>Recipes</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/prep-plan/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={PrepPlanningIcon} alt="" />
																<h2>PREP PLANNING</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/ideal-product-cost/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={IdealProductCostIcon} alt="" />
																<h2>Menu Item <br />Setup</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/ideal-product-cost/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={MenuItemsCostPricing} alt="MenuItemsCostPricing" />
																<h2>MenuMatic</h2>
															</div>
														</Link>
													</div>
												</li>
											</ul>
										</div>


										<div id="fifthclick" lassName={`wookaicircleone fifthclick  ${userType == 'Low-Level' ? 'disabled' : ''}`}>
											<img className="rotating" src={circleone} alt="" />
											<div className="circletext wookaifirst-click">
												<img className="rotating123" src={ItemListManagement} alt="" />
												<h2>Item List <br /> Management</h2>
											</div>
										</div>

										<div id="options-fifth-block" className="wookai-options fifth-block">
											<ul>
												<li>
													<div className="wookaicircleone-inner ">
														<Link className="" to={`/items/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={listIcon} alt="" />
																<h2>LIST</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/items/pendingUnits`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={PendingUnitsIcon} alt="" />
																<h2>PENDING UNITS</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/items/pendingAdditionalInfo`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={PendingAdditionalInfoIcon} alt="" />
																<h2>PENDING <br />ADDITIONAL <br /> INFO</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/users/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={PendingParLevelsIcon} alt="" />
																<h2>PENDING <br />PAR LEVELS</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/items/editParLevels`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={EditParLevelsIcon} alt="" />
																<h2>EDIT <br />PAR LEVELS</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/items/sort`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={sortIcon} alt="" />
																<h2>SORT</h2>
															</div>
														</Link>
													</div>
												</li>
											</ul>
										</div>
										<div id="sixthClick" className={`wookaicircleone reportClickMobile`}>
											<img className="rotating" src={circleone} alt="" />
											<div className="circletext wookaifirst-click">
												<img className="rotating123" src={ReportsIcon} alt="" />
												<h2>REPORTS</h2>
											</div>
										</div>

										<div id="options-sixth-block" className="wookai-options fifth-block">
											<ul>
												<li>
													<div className="wookaicircleone-inner ">
														<Link className="" to={`/reports/vendorPriceChange`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={SuppliersPriceChangeOrangeIcon} alt="" />
																<h2>SUPPLIERS <br />PRICE CHANGE</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/reports/vendorUnrelatedItems`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={SuppliersUnrelatedItemsIcon} alt="" />
																<h2>Suppliers <br />Unrelated Items</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/reports/vendorsAudit`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={SuppliersAuditIcon} alt="" />
																<h2>Suppliers Audit</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/reports/priceHistory`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={DailyProductionIcon} alt="" />
																<h2>Price History</h2>
															</div>
														</Link>
													</div>
												</li>
											</ul>
										</div>


										<div className="wookaicircleone thirdclick">

											<img className="rotating" src={circleone} alt="" />
											<div className="circletext wookaifirst-click">
												<img className="rotating123" src={HelpIcon} alt="" />
												<h2>HELP</h2>
											</div>
										</div>
									</Col>
								</Row>
							</div>
						</>
					}

					{
						((selectedBusinessLocationObject != null) && (selectedBusinessLocationObject.stripe_plan_id == Plans.WOOKAI_ULTIMATE || selectedBusinessLocationObject.stripe_plan_id == Plans.WOOKAI_ULTIMATE_ADDITIONAL || selectedBusinessLocationObject.stripe_plan_id == Plans.WOOKAI_ULTIMATE_YEARLY || selectedBusinessLocationObject.stripe_plan_id == Plans.WOOKAI_ULTIMATE_YEARLY_ADDITIONAL))
						&&
						<>
							<Row>
								<Col lg="12">
									<div className="banner-wrap">
										<div className="banner-inner">
											<span className="back-btn" id="alternate">
												<img src={backIcon} alt="" />
											</span>
											<span className="back-btn2" id="alternate">
												<img src={backIcon} alt="" />
											</span>
											<span className="back-btn3" id="alternate">
												<img src={backIcon} alt="" />
											</span>
											<span className="back-btn4" id="alternate">
												<img src={backIcon} alt="" />
											</span>
											<span className="back-btn5" id="alternate">
												<img src={backIcon} alt="" />
											</span>
											<span className="back-btn6" id="alternate">
												<img src={backIcon} alt="" />
											</span>
											<span className="back-btn7" id="alternate">
												<img src={backIcon} alt="" />
											</span>
											<Row>
												<Col lg="2" sm="2" xs="2" className="iconchange">
													<ul className="icons firstthree">
														<li className={`manageaccount ${userType == 'Low-Level' ? '' : ''}`}>
															<a href="javascript:void(0);" className={`wookaisetup ${userType == 'Low-Level' ? 'disabled' : ''}`}>
																<img src={wookaisetupIcon} alt="" />
															</a>
															<Link className='SuppliersPriceChangeIcon' to={`/reports/vendorPriceChange`}>
																<img src={SuppliersPriceChangeOrangeIcon} alt="SuppliersPriceChangeIcon" />
															</Link>
															<Link className="procedureIcon" to={`/procedures/list`}>
																<img src={ProceduresIcon} alt="" />
															</Link>
															<Link className="listIcon" to={`/items/list`}>
																<img src={listIcon} alt="" />
															</Link>
															<Link className="second-img" to={`/units/list`}>
																<img src={HandIcon} alt="" />
															</Link>
															<Link className="third-click-img" to={`${selectedBusinessLocationObject.is_slave == 0 ? '/vendors/list' : '#'}`}>
																<img src={SupplierIcon} alt="" />
															</Link>
															<Link className="fourth-click-img" to={`/emergencyItems/list`}>
																<img src={EmergencyIcon} alt="" />
															</Link>
															<Link className="inventory_count_img" to={`/inventoryCosts/list`}>
																<img src={orderIcon} alt='order-icon' />
															</Link>
															<p className="manage">WookAI Setup</p>
															<p className="manage-cate">Units</p>
															<p className="manage-order">Special Order Items</p>
														</li>
														<li className={`manageuser PriceOption ${userType == 'Low-Level' ? '' : ''}`}>
															<a id="usermanage" className={`wookaisetup-user ${userType == 'Low-Level' ? 'disabled' : ''} `} href="javascript:void(0);">
																<img src={UserIcon} alt="" />
															</a>
															<Link className='suopilerAuditIcon' to={`/reports/vendorsAudit`}>
																<img src={SuppliersAuditIcon} alt="SuppliersAuditIcon" />
															</Link>
															<Link className="prepPlanIcon" to={`/prep-plan/list`}>
																<img src={PrepPlanningIcon} alt="" />
																{/* </a>				 */}
															</Link>
															<Link className="pendingListIcon" to={`/items/pendingAdditionalInfo`}>
																<img src={PendingAdditionalInfoIcon} alt="" />
															</Link>
															<Link className="second-img" to={`/conversions/list`}>
																<img src={ConversionIcon} alt="" />
															</Link>
															<Link className="third-click-img" to={`/assignedReps/list`}>
																<img src={SalesIcon} alt="" />
															</Link>
															<Link className="fourth-click-img" to={`/orders/list`}>
																<img src={orderIcon} alt="" />
															</Link>
															<Link className="cost_goods_report" to={`/inventoryCosts/report`}>
																<img src={saveOrderIcon} alt="" />
															</Link>
															<p className="manage">User Management</p>
															<p className="manage-cate">Conversions</p>
														</li>
														<li className="countorder">
															<Link className="wookaisetup" to={`${selectedBusinessLocationObject.is_slave == 0 ? '/vendors/list' : '#'}`}>
																<img src={CountIcon} alt="" />
															</Link>
															<Link className="editParIcon" to={`/items/editParLevels`}>
																<img src={EditParLevelsIcon} alt="" />
															</Link>
															<Link className="second-img" to={`/categories/list`}>
																<img src={CategoriesIcon} alt="" />
															</Link>
															<Link className="third-click-img" to={`/users/list`}>
																<img src={assistantIcon} alt="" />
															</Link>
															<Link className="fourth-click-img" to={`/savedOrders/list`}>
																<img src={saveOrderIcon} alt="" />
															</Link>

															<p className="manage">Count & Order Supplies</p>
															<p className="manage-cate">Categories</p>
															<p className="manage-supply">Suppliers</p>
														</li>
														<li className="manageuser manageuserthird ">
															<a id="usermanage" className="wookaisetup-user " href="javascript:void(0);">
																<img src={OrderReceiveIcon} alt="" />
															</a>
															<Link className="rebateIcon" to={`/`}>
																<img src={rebateIcon} alt='rebateIcon' />
															</Link>
															<Link className="second-img" to={`/conversions/list`}>
																<img src={ConversionIcon} alt="" />
															</Link>
															<Link className="third-click-img" to={`/assignedReps/list`}>
																<img src={SalesIcon} alt="" />
															</Link>
															<Link className="fourth-click-img" to={`/orders/list`}>
																<img src={orderIcon} alt="" />
															</Link>
															<p className="manage">User Management</p>
															<p className="manage-cate">Conversions</p>
														</li>
														<li className="manageuser manageuserfourth-text">
															<a id="usermanage" className="wookaisetup-user inventoryClick" href="javascript:void(0);">
																<img src={InventoryIcon} alt="" />
															</a>
															<Link to={`/ideal-product-cost/list`} className="idealIcon">
																<img src={IdealProductCostIcon} alt="" />
															</Link>
															<Link className="second-img" to={`/conversions/list`}>
																<img src={ConversionIcon} alt="" />
															</Link>
															<Link className="third-click-img" to={`/assignedReps/list`}>
																<img src={SalesIcon} alt="" />
															</Link>
															<Link className="fourth-click-img" to={`/orders/list`}>
																<img src={orderIcon} alt="" />
															</Link>
															<p className="manage">User Management</p>
															<p className="manage-cate">Conversions</p>
														</li>
													</ul>
												</Col>
												<Col lg="8" sm="8" xs="8">
													<div className="circle-logo-wrap">
														<ul className="left-text">
															<li className="first-text">
																<p className={`manage wookaiOption ${userType == 'Low-Level' ? 'disabled' : ''}`}><a href="javascript:void(0);">Measurements & Organization</a></p>
																<Link className="" to={`/procedures/list`}>
																	<p className="managePriceText">RECIPES</p>
																</Link>
																<Link className='inventoryCount' to={`/inventoryCosts/list`}>
																	<p className=''>Inventory Count</p>
																</Link>
																<p className="manage1 listItemText">
																	<Link className="" to={`/items/list`}>List</Link>
																</p>
																<p className="manage-cate">
																	<Link className="" to={`/units/list`}>
																		Units
																	</Link>
																</p>
																<p className="manage-supply">
																	<Link className="" to={`${selectedBusinessLocationObject.is_slave == 0 ? '/vendors/list' : '#'}`}>
																		Suppliers
																	</Link>
																</p>
																<p className="manage-order">
																	<Link className="" to={`/emergencyItems/list`}>
																		Special Order Items
																	</Link>
																</p>
																<p className='manageReport'>
																	<Link className='' to={`/reports/vendorPriceChange`}>Suppliers Price Change</Link>
																</p>
															</li>
															<li className="second-text PriceOption">

																<p className={`manage manage-user ${userType == 'Low-Level' ? 'disabled' : ''}`}>Supplier & User Management</p>

																<p className='inventoryReport'>
																	<Link to={`/inventoryCosts/report`}>
																		Cost of Goods Report
																	</Link>
																</p>

																<p className='suppliersAudit'><Link to={`/reports/vendorsAudit`}>Suppliers Audit</Link></p>
																<p className="managePriceText manage">
																	<Link className="" to={`/items/pendingAdditionalInfo`}>Pending Additional Info</Link> </p>
																<p className="manage prepPlan">
																	<Link className="" to={`/prep-plan/list`}>Prep Planning</Link>
																</p>
																<p className="manage-cate">
																	<Link className="" to={`/conversions/list`}>
																		Conversions
																	</Link>
																</p>
																<p className="manage-supply">
																	<Link className="" to={`/assignedReps/list`}>
																		Sales Representatives
																	</Link>
																</p>
																<p className="manage-order">
																	<Link className="" to={`/orders/list`}>
																		Orders
																	</Link>
																</p>

															</li>
															<li className="second-text order-report-text">
																<p className="manage manage-user12 manage-order1">Ordering & Receiving</p>
																<p className="manage-cate rebate">
																	<Link className="" to={`/reports/rebates`}>
																		Rebates
																	</Link>
																</p>
															</li>
															<li className="third-text">

																<p className="manage">Count & Order Supplies</p>
																<p className="managePriceText manage">
																	<Link className="" to={`/items/editParLevels`}>Edit Par Levels</Link>
																</p>
																<p className="manage-cate">
																	<Link className="" to={`/categories/list`}>
																		Categories
																	</Link>
																</p>
																<p className="manage-supply">
																	<Link className="" to={`/users/list`}>
																		Supervisors/ Managers
																	</Link>
																</p>
																<p className="manage-order">
																	<Link to={`/savedOrders/list`}>
																		Saved Orders & App Exports
																	</Link>
																</p>
															</li>
															<li className="second-text count-order-text">
																<Link className="" to={`/ideal-product-cost/list`}> <p className="manage idealCost">Menu Costing</p></Link>
																<p className="inventoryClick manage manage-user123">Inventory Cost of Goods</p>
																<p className="manage-cate">
																	<Link className="" to={`/conversions/list`}>
																		Count & Order Supplies
																	</Link>
																</p>
															</li>

														</ul>
														<div className="circle-logoinner">
															<img className="logo_img logo-first" src={LogoImgFour} alt="" />
															<img className="logo_img nextimg" src={LogoImg} alt="" />
															<img className="inventory_img" src={Inventory} alt="inventoryImage" />
															<img className="wookai-setup-img" src={wookaiSetup} alt="" />
															<img className="user-management-img" src={UserManagement} alt="" />
															<img className="resporting-img" src={OrderingReporting} alt="" />
															<img className="itemListImg" src={itemListManagement} alt="" />
															<img className="priceManagement" src={priceManagement} alt="" />
															<img className="reportImageMain" src={ReportMain} alt="" />

														</div>
														<ul className="right-text">
															<li className="fourth-text">
																<p className={`manage PriceManage`}>PREP PLANNING & <br />MENU ITEM SETUP</p>
																<Link to={`/reports/vendorUnrelatedItems`} className="suppliersUnrelated">
																	Suppliers Unrelated <br /> Items
																</Link>
																<Link to={`/reports/prep-plan`} className="dailyProductionLink">Daily Production</Link>
																<p className="itemListText manage-cate">
																	<Link className="" to={`/items/pendingUnits`}>Pending Units</Link>
																</p>
																<Link className='sub-cate' to={`/subCategories/list`}>
																	<p className="manage-cate  manage-suplier">Sub Categories</p>
																</Link>
															</li>
															<li className="fifth-text">
																<p className={`manage manage-order11 itemList ${userType == 'Low-Level' ? 'disabled' : ''}`}>ITEM LIST MANAGEMENT</p>
																<p className='dailyProduction'>
																	<Link to={`/reports/priceHistory`}>Price <br />History</Link>
																</p>
																<p className="itemListText manage-cate">
																	<Link className="" to={`/items/pendingParLevels`}>Pending Par Levels</Link>
																</p>
																<Link to={`/itemLocations/list`}>
																	<p className="manage-cate">Items Locations</p>
																</Link>
																<Link className='menu_cost_price' to={`/reports/variance`}>
																	<p className="menu_cost_price_text">MenuMatic</p>
																</Link>
															</li>

															<li className="fifth-text schedule-text">
																<p className={`reportClick manage schedule`}>Reports</p>
																{/* </Link> */}
															</li>
															<li className="fifth-text help-text">
																
																<a target = "_blank"  href="https://wookai.com/wookai-members-only">
																	<p className="manage help">HELP</p>
																</a>
															</li>
															<li className="sixth-text">
																<p className="manage">Manage Order & Receiving</p>
																<p className="itemListText manage-cate">
																	<Link className="" to={`/items/sort`}>Sort</Link>
																</p>
															</li>
														</ul>
													</div>
												</Col>
												<Col lg="2" sm="2" xs="2" className="iconchange right-side">
													<ul className="icons">
														<li className={`manageaccount managelocations`}>
															<span className={`priceManageIcon`}>
																<img src={priceManageIcon} alt="priceManageIcon" />
															</span>
															<Link className="dailyProductionIcon" to={`/reports/prep-plan`}>
																<img src={DailyProductionIcon} alt='DailyProductionIcon' />
															</Link>
															<Link className="suppliersUnrelatedIcon" to={`/reports/vendorUnrelatedItems`}>
																<img src={SuppliersUnrelatedItemsIcon} alt="" />
															</Link>
															<Link className="pendingUnitIcon" to={`/items/pendingUnits`}>
																<img src={PendingUnitsIcon} alt="" />
															</Link>
															<Link className="second-img" to={`/subCategories/list`}>
																<img src={SubCategoriesIcon} alt="" />
															</Link>
															<Link className="fourth-click-img" to={`/savedOrders/list`}>
																<img src={saveOrderIcon} alt="" />
															</Link>
															<p>Manage Storage Locations</p>
														</li>
														<li className={`manageuser manageitems ${userType == 'Low-Level' ? '' : ''}`}>
															<span id="order-item" className={`itemListIcon ${userType == 'Low-Level' ? 'disabled' : ''}`} href="javascript:void(0);">
																<img src={ItemListManagement} alt="123123123" />
															</span>
															<Link className='dailyProductionIcon' to={`/reports/priceHistory`}>
																<img src={priceHistory} alt="priceHistory" />
															</Link>
															<Link className="parLevelIcon" to={`/items/pendingParLevels`}>
																<img src={PendingParLevelsIcon} alt="" />
															</Link>
															<Link className="second-img2" to={`/itemLocations/list`}>
																<img src={ItemLocationIcon} alt="" />
															</Link>
															<Link className="second-img" to={`/itemLocations/list`}>
																<img src={ItemLocationIcon} alt="" />
															</Link>
															<Link className="menu_item_icon" to={`/reports/variance`}>
																<img src={MenuItemsCostPricing} alt="MenuItemsCostPricing"/>
															</Link>
															<p>Manage Items</p>
														</li>
														<li className={`manageuser schedule-icon`}>

															<span id="order-item" className={`wookaisetup-order reportClick`} href="javascript:void(0);">
																<img src={ReportsIcon} alt="" />
															</span>
															<Link className="second-img" to={`/itemLocations/list`}>
																<img src={ItemLocationIcon} alt="" />
															</Link>
															<p>Manage Items</p>
														</li>
														<li className="manageuser help-icon">
														<a target = "_blank" id="order-item" className="wookaisetup-order" href="https://wookai.com/wookai-members-only">
															<img src={HelpIcon} alt="" />
														</a>
															<Link className="second-img" to={`/itemLocations/list`}>
																<img src={ItemLocationIcon} alt="" />
															</Link>
															<p>Manage Items</p>
														</li>
														<li className="countorder mangeorder">
															<Link className="wookaisetup" to={`/orders/create`}>
																<img src={OrderIcon} alt="" />
															</Link>
															<Link className="second-img" to={`/items/list`}>
																<img src={ItemIcon} alt="" />
															</Link>
															<Link className="sortItem" to={`/items/sort`}>
																<img src={sortIcon} alt="sortIcon" />
															</Link>
															<p>Manage Order & Receiving</p>
														</li>
													</ul>
												</Col>
											</Row>
										</div>
									</div>
								</Col>
							</Row>

							<div className="mobile-dashboard">
								<span className="back-btn mobilebtn" id="alternate">
									<img src={backIcon} alt="" />
								</span>
								<Row>
									<Col lg="12">
										<div id="firstclick" className={`wookaicircleone firstclick  ${userType == 'Low-Level' ? 'disabled' : ''}`}>
											<img className="rotating" src={circleone} alt="" />
											<div className="circletext wookaifirst-click">
												<img className="rotating123" src={wookaisetupIcon} alt="" />
												{/* <h2>WookAI Setup</h2> */}
												<h2>Measurements &<br/> Organization</h2>
											</div>
										</div>
										<div id="options-block-one" className="wookai-options">
											<ul>
												<li>
													<div className="wookaicircleone-inner ">
														<Link className="" to={`/units/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={HandIcon} alt="" />
																<h2>Units</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/conversions/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={ConversionIcon} alt="" />
																<h2>Conversions</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/categories/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={CategoriesIcon} alt="" />
																<h2>Categories</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/subCategories/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={SubCategoriesIcon} alt="" />
																<h2>Sub Categories</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/itemLocations/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={ItemLocationIcon} alt="" />
																<h2>Items Locations</h2>
															</div>
														</Link>
													</div>
												</li>
											</ul>
										</div>

										<div id="secondclick" className={`wookaicircleone   ${userType == 'Low-Level' ? 'disabled' : ''}`}>
											<img className="rotating" src={circleone} alt="" />
											<div className="circletext wookaifirst-click">
												<img className="rotating123" src={UserIcon} alt="" />
												<h2>Supplier & User <br /> Management</h2>
											</div>
										</div>

										<div id="options-second-block" className="wookai-options second-block">
											<ul>
												<li>
													<div className="wookaicircleone-inner ">
														<Link className="" to={`${selectedBusinessLocationObject.is_slave == 0 ? '/vendors/list' : '#'}`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={SupplierIcon} alt="" />
																<h2>Suppliers</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/assignedReps/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={SalesIcon} alt="" />
																<h2>Sales <br /> Representatives</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/users/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={assistantIcon} alt="" />
																<h2>Supervisors/ <br /> Managers</h2>
															</div>
														</Link>
													</div>
												</li>
											</ul>
										</div>

										<div id="thirdclick" className={`wookaicircleone fourthclick`}>
											<img className="rotating" src={circleone} alt="" />
											<div className="circletext wookaifirst-click">
												<img className="rotating123" src={OrderReceiveIcon} alt="" />
												<h2>Ordering & <br /> Receiving </h2>
											</div>
										</div>
										<div id="options-third-block" className="wookai-options second-block">
											<ul>
												<li>
													<div className="wookaicircleone-inner ">
														<Link to={`/emergencyItems/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={EmergencyIcon} alt="" />
																<h2>Special Order <br/> Items</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link to={`/orders/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={orderIcon} alt="" />
																<h2>Orders</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link to={`/savedOrders/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={saveOrderIcon} alt="" />
																<h2>Saved Orders<br/> & App Exports</h2>
															</div>
														</Link>
													</div>
												</li>
											</ul>
										</div>

										<div id='seventhclick' className={`wookaicircleone seventhclick`}>

											<img className="rotating" src={circleone} alt="" />
											<div className="circletext inventoryseventh-click">
												<img className="rotating123" src={InventoryIcon} alt="" />
												<h2>Inventory Cost <br /> of Goods</h2>
											</div>

										</div>

										<div id="options-seventh-block" className="inventory-options seventh-block">
											<ul>
												<li>
													<div className="wookaicircleone-inner ">
														<Link className="" to={`/procedures/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext inventoryseventh-click">
																<img className="rotating123" src={orderIcon} alt="orderIcon" />
																<h2>Inventory <br /> Counts</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/prep-plan/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext inventoryseventh-click">
																<img className="rotating123" src={saveOrderIcon} alt="" />
																<h2>Cost of Goods<br /> Report</h2>
															</div>
														</Link>
													</div>
												</li>
											</ul>
										</div>


										<div id="fourthclick" className={`wookaicircleone fourthclick`}>

											<img className="rotating" src={circleone} alt="" />
											<div className="circletext wookaifirst-click">
												<img className="rotating123" src={priceManageIcon} alt="" />
												<h2>Prep Planning &<br/> Menu Item Setup</h2>
											</div>
										</div>

										<div id="options-fourth-block" className="wookai-options fourth-block">
											<ul>
												<li>
													<div className="wookaicircleone-inner ">
														<Link className="" to={`/procedures/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={ProceduresIcon} alt="" />
																<h2>Recipes</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/prep-plan/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={PrepPlanningIcon} alt="" />
																<h2>Prep Planning</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/ideal-product-cost/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={IdealProductCostIcon} alt="" />
																<h2>Menu Item <br />Setup</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/ideal-product-cost/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={DailyProductionIcon} alt="" />
																<h2>Daily Production</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/ideal-product-cost/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={MenuItemsCostPricing} alt="MenuItemsCostPricing" />
																<h2>MenuMatic</h2>
															</div>
														</Link>
													</div>
												</li>
											</ul>
										</div>


										<div id="fifthclick" className={`wookaicircleone fifthclick  ${userType == 'Low-Level' ? 'disabled' : ''}`}>
											<img className="rotating" src={circleone} alt="" />
											<div className="circletext wookaifirst-click">
												<img className="rotating123" src={ItemListManagement} alt="" />
												<h2>Item List <br /> Management</h2>
											</div>
										</div>

										<div id="options-fifth-block" className="wookai-options fifth-block">
											<ul>
												<li>
													<div className="wookaicircleone-inner ">
														<Link className="" to={`/items/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={listIcon} alt="" />
																<h2>LIST</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/items/pendingUnits`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={PendingUnitsIcon} alt="" />
																<h2>PENDING UNITS</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/items/pendingAdditionalInfo`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={PendingAdditionalInfoIcon} alt="" />
																<h2>PENDING <br />ADDITIONAL <br /> INFO</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/users/list`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={PendingParLevelsIcon} alt="" />
																<h2>PENDING <br />PAR LEVELS</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/items/editParLevels`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={EditParLevelsIcon} alt="" />
																<h2>EDIT <br />PAR LEVELS</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/items/sort`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={sortIcon} alt="" />
																<h2>SORT</h2>
															</div>
														</Link>
													</div>
												</li>
											</ul>
										</div>

										<div id="sixthClick" className={`wookaicircleone reportClickMobile`}>
											<img className="rotating" src={circleone} alt="" />
											<div className="circletext wookaifirst-click">
												<img className="rotating123" src={ReportsIcon} alt="" />
												<h2>REPORTS</h2>
											</div>
											{/* </Link> */}
										</div>

										<div id="options-sixth-block" className="wookai-options fifth-block">
											<ul>
												<li>
													<div className="wookaicircleone-inner ">
														<Link className="" to={`/reports/vendorPriceChange`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={SuppliersPriceChangeOrangeIcon} alt="" />
																<h2>SUPPLIERS <br />PRICE CHANGE</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/reports/vendorUnrelatedItems`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={SuppliersUnrelatedItemsIcon} alt="" />
																<h2>Suppliers <br />Unrelated Items</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/reports/vendorsAudit`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={SuppliersAuditIcon} alt="" />
																<h2>Suppliers Audit</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/reports/priceHistory`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={DailyProductionIcon} alt="" />
																<h2>Price History</h2>
															</div>
														</Link>
													</div>
												</li>
												<li>
													<div className="wookaicircleone-inner">
														<Link className="" to={`/reports/rebates`}>
															<img className="rotating" src={circleone} alt="" />
															<div className="circletext wookaifirst-click">
																<img className="rotating123" src={rebateIcon} alt="" />
																<h2>Rebates</h2>
															</div>
														</Link>
													</div>
												</li>
											</ul>
										</div>
										<div className="wookaicircleone thirdclick">

											<img className="rotating" src={circleone} alt="" />
											<div className="circletext wookaifirst-click">
												<img className="rotating123" src={HelpIcon} alt="" />
												<h2>HELP</h2>
											</div>
										</div>
									</Col>
								</Row>
							</div>
						</>
					}


				</Container>
			</div>
			{/* <Modal isOpen={modal} role="dialog" autoFocus={true} centered={true} className="exampleModal" tabindex="-1" toggle={() => { setmodal(!modal); }}>
				<div className="modal-content">
					<ModalHeader toggle={() => { setmodal(!modal); }}>
						Order Details
					</ModalHeader >
					<ModalBody>
						<p className="mb-2">Product id: <span className="text-primary">#SK2540</span></p>
						<p className="mb-4">Billing Name: <span className="text-primary">Neal Matthews</span></p>

						<div className="table-responsive">
							<Table className="table table-centered table-nowrap">
								<thead>
									<tr>
										<th scope="col">Product</th>
										<th scope="col">Product Name</th>
										<th scope="col">Price</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<th scope="row">
											<div>
												<img src={modalimage1} alt="" className="avatar-sm" />
											</div>
										</th>
										<td>
											<div>
												<h5 className="text-truncate font-size-14">Wireless Headphone (Black)</h5>
												<p className="text-muted mb-0">$ 225 x 1</p>
											</div>
										</td>
										<td>$ 255</td>
									</tr>
									<tr>
										<th scope="row">
											<div>
												<img src={modalimage2} alt="" className="avatar-sm" />
											</div>
										</th>
										<td>
											<div>
												<h5 className="text-truncate font-size-14">Hoodie (Blue)</h5>
												<p className="text-muted mb-0">$ 145 x 1</p>
											</div>
										</td>
										<td>$ 145</td>
									</tr>
									<tr>
										<td colspan="2">
											<h6 className="m-0 text-right">Sub Total:</h6>
										</td>
										<td>$ 400</td>
									</tr>
									<tr>
										<td colspan="2">
											<h6 className="m-0 text-right">Shipping:</h6>
										</td>
										<td>Free</td>
									</tr>
									<tr>
										<td colspan="2">
											<h6 className="m-0 text-right">Total:</h6>
										</td>
										<td>$ 400</td>
									</tr>
								</tbody>
							</Table>
						</div>
					</ModalBody>
					<ModalFooter>
						<Button type="button" color="secondary" onClick={() => { setmodal(!modal); }}>Close</Button>
					</ModalFooter>
				</div>
			</Modal> */}
		</React.Fragment>
	);
}

export default withNamespaces()(Dashboard);