export const GET_CATEGORIES = 'GET_CATEGORIES';
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const API_ERROR = 'LOGIN_API_ERROR';
export const SET_CATEGORY = 'SET_CATEGORY';
export const ADD_CATEGORY = 'ADD_CATEGORY';
export const EMPTY_CATEGORY = 'EMPTY_CATEGORY';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const EDIT_CATEGORY = 'EDIT_CATEGORY';
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const SET_CATEGORY_FILTER = 'SET_CATEGORY_FILTER';
export const GET_CATEGORIES_LIST = 'GET_CATEGORIES_LIST';
export const SET_CATEGORIES_LIST = 'SET_CATEGORIES_LIST';