import {
  GET_ENQUIRIES,
  SET_ENQUIRIES,
  API_ERROR,
  SET_ENQUIRY_FILTER,
} from './actionTypes';

const initialState = {
  enquiries: [],
  filter: {
    offSet: 0,
    limit: 10,
    pages: 0,
    currentPage: 1,
    query: ''
  },
  error: '',
  loading: false,
};

const enquiry = (state = initialState, action) => {
  switch (action.type) {
    case GET_ENQUIRIES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case SET_ENQUIRIES:
      state = {
        ...state,
        enquiries: action.payload,
        loading: false,
      };
      break;
      case SET_ENQUIRY_FILTER:
        state = {
          ...state,
          filter: {
            ...state.filter,
            [action.payload.field]: action.payload.value,
          },
        };
        break;
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default enquiry;
