export const GET_MASS_EMAILS = 'GET_MASS_EMAILS';
export const SET_MASS_EMAILS = 'SET_MASS_EMAILS';
export const API_ERROR = 'LOGIN_API_ERROR';
export const SET_MASS_EMAIL = 'SET_MASS_EMAIL';
export const ADD_MASS_EMAIL = 'ADD_MASS_EMAIL';
export const EMPTY_MASS_EMAIL = 'EMPTY_MASS_EMAIL';
export const DELETE_MASS_EMAIL = 'DELETE_MASS_EMAIL';
export const EDIT_MASS_EMAIL = 'EDIT_MASS_EMAIL';
export const UPDATE_MASS_EMAIL = 'UPDATE_MASS_EMAIL';
export const SET_MASS_EMAIL_FILTER = 'SET_MASS_EMAIL_FILTER';
export const SEND_MASS_EMAIL = 'SEND_MASS_EMAIL';