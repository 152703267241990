export const GET_PRODUCT_SUB_CATEGORIES = 'GET_PRODUCT_SUB_CATEGORIES';
export const SET_PRODUCT_SUB_CATEGORIES = 'SET_PRODUCT_SUB_CATEGORIES';
export const API_ERROR = 'LOGIN_API_ERROR';
export const SET_PRODUCT_SUB_CATEGORY = 'SET_PRODUCT_SUB_CATEGORY';
export const ADD_PRODUCT_SUB_CATEGORY = 'ADD_PRODUCT_SUB_CATEGORY';
export const EMPTY_PRODUCT_SUB_CATEGORY = 'EMPTY_PRODUCT_SUB_CATEGORY';
export const DELETE_PRODUCT_SUB_CATEGORY = 'DELETE_PRODCUT_CATEGORY';
export const EDIT_PRODUCT_SUB_CATEGORY = 'EDIT_PRODUCT_SUB_CATEGORY';
export const UPDATE_PRODUCT_SUB_CATEGORY = 'UPDATE_PRODUCT_SUB_CATEGORY';
export const SET_PRODUCT_SUB_CATEGORY_FILTER = 'SET_PRODUCT_SUB_CATEGORY_FILTER';
export const GET_PRODUCT_SUB_CATEGORIES_LIST = 'GET_PRODUCT_SUB_CATEGORIES_LIST';
export const SET_PRODUCT_SUB_CATEGORIES_LIST = 'SET_PRODUCT_SUB_CATEGORIES_LIST';