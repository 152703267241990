import React, { useEffect } from 'react';
import { connect } from 'react-redux';

// MetisMenu
import MetisMenu from 'metismenujs';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';

//actions
import { getBusinessLocations, setItemLocation } from '../../store/actions';

//i18n
import { withNamespaces } from 'react-i18next';
import jwt_decode from 'jwt-decode';
import { httpGet } from '../../utils/http';
import { PRODUCTS_ENDPOINT } from '../../config/endPoints';

import "./style.css";

// import wookaiBlackIcon from "../../assets/images/wookaiBlackIcon.png";

import homeIcon from "../../assets/images/homeIcon.svg";
import homeOrangeIcon from "../../assets/images/homeOrangeIcon.png";

import wookaiBlackIcon from "../../assets/images/wookaiIcon.svg";
import wookaiOrangeIcon from "../../assets/images/wookaiOrangeIcon.png";
import ItemListManagementBlackIcon from "../../assets/images/Item-List-Management.svg";
import ItemListManagementOrangeIcon from "../../assets/images/ItemListManagementOrangeIcon.png";
import supplierUserManagementIcon from "../../assets/images/supplier-&-user-management.svg";
import supplierUserManagementOrangeIcon from "../../assets/images/supplier-&-user-managementOrange.png";
import orderingReceiving from "../../assets/images/ORDERING-RECEIVING.svg";
import orderingReceivingOrange from "../../assets/images/ORDERING-&-RECEIVING-Orange.png";
import priceManagementIcon from "../../assets/images/price-management-icon.svg";
import priceManagementOrangeIcon from "../../assets/images/price-management-orange-icon.png";
import inventoryIcon from "../../assets/images/INVENTORY-COST-OF-GOODS.svg";
import inventoryOrangeIcon from "../../assets/images/INVENTORY-COST-OF-GOODS-Orange.png";
import Reports from "../../assets/images/Reports.svg";
import ReportsOrange from "../../assets/images/ReportsOrange.png";
import helpIcon from "../../assets/images/help-icon.svg";
import helpOrangeIcon from "../../assets/images/help-Orange-Icon.png";
import UnitsIcon from "../../assets/images/Units.svg";
import UnitsOrange from "../../assets/images/UnitsOrange.png";
import ListIcon from "../../assets/images/ListIcon.svg";
import ListOrangeIcon from "../../assets/images/ListOrangeIcon.png";
import EditParLevelsIcon from "../../assets/images/EditParLevelsIcon.svg";
import EditParLevelsOrangeIcon from "../../assets/images/EditParLevelsOrange.png";
import PendingUnitsIcon from "../../assets/images/PendingUnitsIcon.svg";
import PendingUnitsOrangeIcon from "../../assets/images/PendingUnitsOrangeIcon.png";
import PendingAdditionalInfoIcon from "../../assets/images/PendingAdditionalInfoIcon.svg";
import PendingAdditionalInfoOrangeIcon from "../../assets/images/PendingAdditionalInfoOrangeIcon.png";
import PendingParLevelsIcon from "../../assets/images/PendingParLevelsIcon.svg";
import PendingParLevelsOrangeIcon from "../../assets/images/PendingParLevelsOrangeIcon.png";
import ProceduresIcon from "../../assets/images/ProceduresIcon.svg";
import ProceduresOrangeIcon from "../../assets/images/ProceduresOrangeIcon.png";
import PrepPlanningIcon from "../../assets/images/PrepPlanningIcon.svg";
import PrepPlanningOrangeIcon from "../../assets/images/PrepPlanningOrangeIcon.png";
import IdealProductCostIcon from "../../assets/images/IdealProductCostIcon.svg";
import IdealProductCostOrangeIcon from "../../assets/images/IdealProductCostOrangeIcon.png";
import ConversionsIcon from "../../assets/images/ConversionsIcon.svg";
import ConversionsOrangeIcon from "../../assets/images/ConversionsOrangeIcon.png";
import SubCategoriesIcon from "../../assets/images/SubCategoriesIcon.svg";
import SubCategoriesOrangeIcon from "../../assets/images/SubCategoriesOrangeIcon.png";
import ItemsLocationIcon from "../../assets/images/ItemsLocationIcon.svg";
import ItemsLocationOrangeIcon from "../../assets/images/ItemsLocationOrangeIcon.png";
import EmergencyItemsIcon from "../../assets/images/EmergencyItemsIcon.svg";
import EmergencyItemsOrangeIcon from "../../assets/images/EmergencyItemsOrangeIcon.png";
import orderIcon from "../../assets/images/orderIcon.svg";
import orderOrangeIcon from "../../assets/images/orderOrangeIcon.png";
import saveOrderIcon from "../../assets/images/saveOrderIcon.png";
import saveOrderOrangeIcon from "../../assets/images/saveOrderOrangeIcon.png";
import supplierIcon from "../../assets/images/supplierIcon.png";
import supplierOrangeIcon from "../../assets/images/supplierOrangeIcon.png";
import managAssistantIcon from "../../assets/images/managAssistantIcon.png";
import managAssistantOrangeIcon from "../../assets/images/managAssistantOrangeIcon.png";
import salesRepresentativeIcon from "../../assets/images/salesRepresentativeIcon.png";
import salesRepresentativeOrangeIcon from "../../assets/images/salesRepresentativeOrangeIcon.png";
import categoriesIcon from "../../assets/images/categoriesIcon.png";
import categoriesOrangeIcon from "../../assets/images/categoriesOrangeIcon.png";
import SuppliersPriceChangeIcon from "../../assets/images/SuppliersPriceChangeIcon.svg";
import SuppliersPriceChangeOrangeIcon from "../../assets/images/SuppliersPriceChangeOrangeIcon.png";
import SuppliersUnrelatedItemsIcon from "../../assets/images/SuppliersUnrelatedItemsIcon.svg";
import SuppliersUnrelatedItemsOrangeIcon from "../../assets/images/SuppliersUnrelatedItemsOrangeIcon.png";
import SuppliersAuditIcon from "../../assets/images/SuppliersAuditIcon.svg";
import SuppliersAuditOrangeIcon from "../../assets/images/SuppliersAuditOrangeIcon.png";
import priceHistory from "../../assets/images/priceHistory.svg";
import priceHistoryYellow from "../../assets/images/priceHistoryYellow.png";
import DailyProductionIconYellow from "../../assets/images/DailyProductionIconYellow.png";
import DailyProductionIcon from "../../assets/images/DailyProductionIcon.svg";
import HideIcon from "../../assets/images/hideIcon.png";
import HideOrange from "../../assets/images/hideOrange.png";
import rebateIcon from "../../assets/images/rebateIcon.png";
import rebateOrangeIcon from "../../assets/images/rebateOrangeIcon.png";
import MenuItemsCostPricingOrangeIcon from "../../assets/images/MenuItemsCostPricing.png";
import MenuItemsCostPricingIcon from "../../assets/images/MenuItemsCostPricing.svg";




import { Plans } from '../../config/plans';



const SidebarContent = (props) => {
  // decode token to get the user type
  const jwtDecode = jwt_decode(localStorage.getItem('token'));
  const userType = jwtDecode.user_type;
  const selectedBusinessLocation = localStorage.getItem('businessLocation');
  const selectedBusinessLocationObject = JSON.parse(localStorage.getItem('businessLocationObject'));
  const pendingUnits = localStorage.getItem('pendingUnits');
  const pendingParLevels = localStorage.getItem('pendingParLevels');
  const pendingAdditionalInfo = localStorage.getItem('pendingAdditionalInfo');

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    var pathName = props.location.pathname;

    //if user type is client/Master then ask him to select business location in case there is more than one business location otherwise set one automatically, if no business location then let him add first
    console.log(jwtDecode);
    if (
      (userType === 'Client' || userType === 'Mid-Level' || userType === 'Low-Level' || userType === 'Rep-Level') &&
      jwtDecode.businessLocations > 1 &&
      (selectedBusinessLocation == undefined || selectedBusinessLocation == '') && (props.location.pathname != '/manageAccount')
    ) 
    {
      console.log('block 1');
      props.history.push('/selectBusinessLocation');
    } 
    else if (
      (userType === 'Client' || userType === 'Mid-Level' || userType === 'Low-Level'  || userType === 'Rep-Level') &&
      jwtDecode.businessLocations == 1 &&
      (selectedBusinessLocation == undefined || selectedBusinessLocation == '') && (props.location.pathname != '/manageAccount')
    ) 
    {
      console.log('block 2');
      props.getBusinessLocations();
    }
    else if (
      (userType === 'Client' || userType === 'Mid-Level' || userType === 'Low-Level'  || userType === 'Rep-Level') &&
      jwtDecode.businessLocations == 0 &&
      (selectedBusinessLocation == undefined || selectedBusinessLocation == '') && (props.location.pathname != '/manageAccount')
    ) 
    {
      console.log('block 3');
      if(props.location.pathname != '/pricing'){
        if(userType === 'Rep-Level'){
          props.history.push('/selectBusinessLocation');
        }else{
          props.history.push('/businessLocations/add');
        }
      }
    }

    //fetch pending units to check everytime
    
    if((userType === 'Client' || userType === 'Mid-Level' || userType === 'Low-Level') && 
      selectedBusinessLocation){
         pendingProcess(); // this is commented because it throws an error!
    }

    const initMenu = () => {
      new MetisMenu('#side-menu');
      var matchingMenuItem = null;
      var ul = document.getElementById('side-menu');
      var items = ul.getElementsByTagName('a');
      for (var i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname]);

  function activateParentDropdown(item) {
    item.classList.add('active');
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add('mm-active');
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show');

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add('mm-active'); // li
          parent3.childNodes[0].classList.add('mm-active'); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add('mm-active');
          }
        }
      }
      return false;
    }
    return false;
  }

  /**
   * store pending process count in local stoage to show the pending menu in sidebar under items
   */
  async function pendingProcess(){
    try {
      let locationNumber = localStorage.getItem('businessLocation');
      if(locationNumber != undefined && locationNumber){
          let pendingUnitsResponse = await httpGet(`${PRODUCTS_ENDPOINT}/get/pendingUnits?offSet=0&limit=10`);
        localStorage.setItem('pendingUnits', pendingUnitsResponse.data.records.length);
    
        let pendingAdditionalInfoResponse = await httpGet(`${PRODUCTS_ENDPOINT}/get/pendingAdditionalInfo?offSet=0&limit=10`);
        localStorage.setItem('pendingAdditionalInfo', pendingAdditionalInfoResponse.data.records.length);
    
        let pendingParLevelsResponse = await httpGet(`${PRODUCTS_ENDPOINT}/get/pendingParLevels?offSet=0&limit=10`);
        localStorage.setItem('pendingParLevels', pendingParLevelsResponse.data.records.length);
      }
    } catch (error) {
      
    }
   

    //if selected business location does not have any subscription or plan then enforce it to subsribe first
    if(!selectedBusinessLocationObject.stripe_plan_id && props.location.pathname != '/selectBusinessLocation' && props.location.pathname != '/businessLocations/add'){
      console.log('this.props.location', props.location.pathname);
      props.history.push('/pricing');
    }
  }

  return (
    <React.Fragment>
      <div id='sidebar-menu'>
        <ul className='metismenu list-unstyled' id='side-menu'>
          <li className='menu-title'>{props.t('Menu')} </li>
          {/* <li>
            <Link to='/#' className='waves-effect'>
              <i className='bx bx-home-circle'></i>
              <span className='badge badge-pill badge-info float-right'>
                03
              </span>
              <span>{props.t('Dashboards')}</span>
            </Link>
            <ul className='sub-menu' aria-expanded='false'>
              <li>
                <Link to='/dashboard'>{props.t('Default')}</Link>
              </li>
              <li>
                <Link to='/dashboard-saas'>{props.t('Saas')}</Link>
              </li>
              <li>
                <Link to='/dashboard-crypto'>{props.t('Crypto')}</Link>
              </li>
            </ul>
          </li> */}

          {userType != "Rep-Level" && (
            <React.Fragment>

              {<li>
                <Link to='/dashboard' className='waves-effect'>
                  {/* <i className='bx bx-home-circle'></i> */}
                  <img className='blackIcon' src={homeIcon} alt="" />
                  <img className='orangeIcon' src={homeOrangeIcon} alt="" />
                  <span>{props.t('Dashboard')}</span>
                </Link>
              </li>}
            </React.Fragment>
          )}
          

          {/* Side bar for Client */}    
          {userType == 'Client' && (
            <React.Fragment>
              {
                (selectedBusinessLocationObject && selectedBusinessLocationObject.is_slave == 0) && 
                <>
                  {/* <li>
                    <Link to='/businessLocations/list' className='waves-effect'>
                      <i className='fas fa-business-time'></i>
                      <span>{props.t('Business Locations')}</span>
                    </Link>
                  </li> */}

                {
                  ((selectedBusinessLocationObject != null) && (selectedBusinessLocationObject.stripe_plan_id && selectedBusinessLocationObject.stripe_plan_id != Plans.WOOKAI_SUPPLIER_PRICE_CHECKER))
                  &&
                  <li>
                  <Link to='/#' className='has-arrow waves-effect'>
                    {/* <i className='fas fa-balance-scale'></i> */}
                    <img className='blackIcon' src={wookaiBlackIcon} alt="" />
                    <img className='orangeIcon' src={wookaiOrangeIcon} alt="" />
                    <span>{props.t('Measurements & Organization')}</span>
                  </Link>
                  <ul className='sub-menu' aria-expanded='false'>
                    <li>
                      <Link to='/units/list'>
                        {/* <i className='fas fa-balance-scale'></i> */}
                        <img className='blackIcon' src={UnitsIcon} alt="" />
                        <img className='orangeIcon' src={UnitsOrange} alt="" />

                        <span>{props.t('Units')}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to='/conversions/list' className='waves-effect'>
                        {/* <i className='fas fa-exchange-alt'></i> */}
                        
                        <img className='blackIcon' src={ConversionsIcon} alt="" />
                        <img className='orangeIcon' src={ConversionsOrangeIcon} alt="" />                        
                        <span>{props.t('Conversions')}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to='/categories/list' className='waves-effect'>
                        {/* <i className='fas fa-clipboard-list'></i> */}
                        
                        <img className='blackIcon' src={categoriesIcon} alt="" />
                        <img className='orangeIcon' src={categoriesOrangeIcon} alt="" />                           
                        <span>{props.t('Categories')}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to='/subCategories/list' className='waves-effect'>
                        {/* <i className="fas fa-project-diagram"></i> */}
                        <img className='blackIcon' src={SubCategoriesIcon} alt="" />
                        <img className='orangeIcon' src={SubCategoriesOrangeIcon} alt="" />                          
                        <span>{props.t('Sub Categories')}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to='/itemLocations/list' className='waves-effect'>
                        {/* <i className='fas fa-location-arrow'></i> */}                         
                        <img className='blackIcon' src={ItemsLocationIcon} alt="" />
                        <img className='orangeIcon' src={ItemsLocationOrangeIcon} alt="" />                          
                        <span>{props.t('Item Locations')}</span>
                      </Link>
                    </li>
                  </ul>
                </li>    
                }

                {
                  ((selectedBusinessLocationObject != null) && (selectedBusinessLocationObject.stripe_plan_id && selectedBusinessLocationObject.stripe_plan_id == Plans.WOOKAI_SUPPLIER_PRICE_CHECKER))
                  &&
                  <li className='disabled'>
                  <Link to='/#' className='has-arrow waves-effect'>
                    {/* <i className='fas fa-balance-scale'></i> */}
                    <img className='blackIcon' src={wookaiBlackIcon} alt="" />
                    <img className='orangeIcon' src={wookaiOrangeIcon} alt="" />
                    <span>{props.t('Measurements & Organization')}</span>
                  </Link>
                  <ul className='sub-menu' aria-expanded='false'>
                    <li>
                      <Link to='#'>
                        {/* <i className='fas fa-balance-scale'></i> */}
                        <img className='blackIcon' src={UnitsIcon} alt="" />
                        <img className='orangeIcon' src={UnitsOrange} alt="" />

                        <span>{props.t('Units')}</span> 
                      </Link>
                    </li>
                    <li>
                      <Link to='#' className='waves-effect'>
                        {/* <i className='fas fa-exchange-alt'></i> */}
                        
                        <img className='blackIcon' src={ConversionsIcon} alt="" />
                        <img className='orangeIcon' src={ConversionsOrangeIcon} alt="" />                        
                        <span>{props.t('Conversions')}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to='#' className='waves-effect'>
                        {/* <i className='fas fa-clipboard-list'></i> */}
                        
                        <img className='blackIcon' src={categoriesIcon} alt="" />
                        <img className='orangeIcon' src={categoriesOrangeIcon} alt="" />                           
                        <span>{props.t('Categories')}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to='#' className='waves-effect'>
                        {/* <i className="fas fa-project-diagram"></i> */}
                        <img className='blackIcon' src={SubCategoriesIcon} alt="" />
                        <img className='orangeIcon' src={SubCategoriesOrangeIcon} alt="" />                          
                        <span>{props.t('Sub Categories')}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to='#' className='waves-effect'>
                        {/* <i className='fas fa-location-arrow'></i> */}                         
                        <img className='blackIcon' src={ItemsLocationIcon} alt="" />
                        <img className='orangeIcon' src={ItemsLocationOrangeIcon} alt="" />                          
                        <span>{props.t('Item Locations')}</span>
                      </Link>
                    </li>
                  </ul>
                </li>    
                }
                </>
              }

              {
                ((selectedBusinessLocationObject != null) && (selectedBusinessLocationObject.stripe_plan_id && selectedBusinessLocationObject.stripe_plan_id != Plans.WOOKAI_SUPPLIER_PRICE_CHECKER))
                  &&
                  <li>
                  <Link to='/#' className='has-arrow waves-effect'>
                    {/* <i className='fas fa-users'></i> */} 
                    <img className='blackIcon' src={supplierUserManagementIcon} alt="" />
                    <img className='orangeIcon' src={supplierUserManagementOrangeIcon} alt="" />
  
                    <span>{props.t('Supplier & User Management')}</span>
                  </Link>
                  <ul className='sub-menu' aria-expanded='false'>
                    {
                      (selectedBusinessLocationObject && selectedBusinessLocationObject.is_slave == 0) &&
                      <li>
                        <Link to='/vendors/list' className='waves-effect'>
                          {/* <i className='fas fa-industry'></i> */}
                          <img className='blackIcon' src={supplierIcon} alt="" />
                          <img className='orangeIcon' src={supplierOrangeIcon} alt="" />
                          
                          <span>{props.t('Suppliers')}</span>
                        </Link>
                      </li>
                    }
                      <li>
                        <Link to='/users/list' className='waves-effect'>
                          {/* <i className='fas fa-users'></i> */}
                          <img className='blackIcon' src={managAssistantIcon} alt="" />
                          <img className='orangeIcon' src={managAssistantOrangeIcon} alt="" />                        
                          <span>{props.t('Supervisors/Managers')}</span>
                        </Link>
                      </li>
  
                      <li>
                        <Link to='/assignedReps/list' className='waves-effect'>
                          {/* <i className="fas fa-chalkboard-teacher"></i> */}
                          
                          <img className='blackIcon' src={salesRepresentativeIcon} alt="" />
                          <img className='orangeIcon' src={salesRepresentativeOrangeIcon} alt="" />                           
                          <span>{props.t('Sales Representatives')}</span>
                        </Link>
                      </li>
                  </ul>
                </li>
              }

              {
                ((selectedBusinessLocationObject != null) && (selectedBusinessLocationObject.stripe_plan_id && selectedBusinessLocationObject.stripe_plan_id == Plans.WOOKAI_SUPPLIER_PRICE_CHECKER))
                  &&
                  <li>
                  <Link to='/#' className='has-arrow waves-effect'>
                    {/* <i className='fas fa-users'></i> */} 
                    <img className='blackIcon' src={supplierUserManagementIcon} alt="" />
                    <img className='orangeIcon' src={supplierUserManagementOrangeIcon} alt="" />
  
                    <span>{props.t('Supplier & User Management')}</span>
                  </Link>
                  <ul className='sub-menu' aria-expanded='false'>
                    {
                      (selectedBusinessLocationObject && selectedBusinessLocationObject.is_slave == 0) &&
                      <li>
                        <Link to='/vendors/list' className='waves-effect'>
                          {/* <i className='fas fa-industry'></i> */}
                          <img className='blackIcon' src={supplierIcon} alt="" />
                          <img className='orangeIcon' src={supplierOrangeIcon} alt="" />
                          
                          <span>{props.t('Suppliers')}</span>
                        </Link>
                      </li>
                    }
                      <li className='disabled'>
                        <Link to='#' className='waves-effect'>
                          {/* <i className='fas fa-users'></i> */}
                          <img className='blackIcon' src={managAssistantIcon} alt="" />
                          <img className='orangeIcon' src={managAssistantOrangeIcon} alt="" />                        
                          <span>{props.t('Supervisors/Managers')}</span>
                        </Link>
                      </li>
  
                      <li className='disabled'>
                        <Link to='#' className='waves-effect'>
                          {/* <i className="fas fa-chalkboard-teacher"></i> */}
                          
                          <img className='blackIcon' src={salesRepresentativeIcon} alt="" />
                          <img className='orangeIcon' src={salesRepresentativeOrangeIcon} alt="" />                           
                          <span>{props.t('Sales Representatives')}</span>
                        </Link>
                      </li>
                  </ul>
                </li>
              }


            {
						((selectedBusinessLocationObject != null) && (selectedBusinessLocationObject.stripe_plan_id == Plans.WOOKAI_SUPPLY_LIST_MANAGEMENT_SUPPLIER_PRICE_MANAGEMENT_PRODUCT_MANAGEMENT 
              || selectedBusinessLocationObject.stripe_plan_id == Plans.WOOKAI_SUPPLY_LIST_MANAGEMENT_SUPPLIER_PRICE_MANAGEMENT_PRODUCT_MANAGEMENT_ADDITIONAL
              || selectedBusinessLocationObject.stripe_plan_id == Plans.WOOKAI_SUPPLIER_PRICE_CHECKER
              ))
						  &&
            <li className='disabled'>
              <Link to='/#' className='has-arrow waves-effect'>
                {/* <i className='fas fa-shopping-cart'></i> */}
                
                <img className='blackIcon' src={orderingReceiving} alt="" />
                <img className='orangeIcon' src={orderingReceivingOrange} alt="" />                  
                <span>{props.t('Orders & Receiving')}</span>
              </Link>
              <ul className='sub-menu' aria-expanded='false'>
                <li>
                  <Link to='#' className='waves-effect'>
                    {/* <i className='fas fa-shopping-cart'></i> */}
                    <img className='blackIcon' src={orderIcon} alt="" />
                  <img className='orangeIcon' src={orderOrangeIcon} alt="" />       
                    <span>{props.t('Orders')}</span>
                  </Link>
                </li>
                <li>
                  <Link to='#' className='waves-effect'>
                    {/* <i className='fas fa-shopping-cart'></i> */}
                    
                    <img className='blackIcon' src={saveOrderIcon} alt="" />
                    <img className='orangeIcon' src={saveOrderOrangeIcon} alt="" />                      
                    <span>{props.t('Saved Orders & App Exports')}</span>
                  </Link>
                </li>
                <li>
                  <Link to='#' className='waves-effect'>
                    {/* <i className='fas fa-shopping-cart'></i> */}
                    <img className='blackIcon' src={EmergencyItemsIcon} alt="" />
                    <img className='orangeIcon' src={EmergencyItemsOrangeIcon} alt="" />                        
                    <span>{props.t('Special Order Items')}</span>
                  </Link>
                </li>
              </ul>
            </li>
            }

          {
						((selectedBusinessLocationObject != null) && (selectedBusinessLocationObject.stripe_plan_id == Plans.WOOKAI_SUPPLY_LIST_MANAGEMENT_SUPPLIER_PRICE_MANAGEMENT_ORDERING_RECEIVING 
              || selectedBusinessLocationObject.stripe_plan_id == Plans.WOOKAI_SUPPLY_LIST_MANAGEMENT_SUPPLIER_PRICE_MANAGEMENT_ORDERING_RECEIVING_ADDITIONAL
              || selectedBusinessLocationObject.stripe_plan_id == Plans.WOOKAI_ULTIMATE ||
              selectedBusinessLocationObject.stripe_plan_id == Plans.WOOKAI_ULTIMATE_ADDITIONAL
              ||  selectedBusinessLocationObject.stripe_plan_id == Plans.WOOKAI_ULTIMATE_YEARLY
              ||  selectedBusinessLocationObject.stripe_plan_id == Plans.WOOKAI_ULTIMATE_YEARLY_ADDITIONAL
              ))
						  &&
            <li>
              <Link to='/#' className='has-arrow waves-effect'>
                {/* <i className='fas fa-shopping-cart'></i> */}
                
                <img className='blackIcon' src={orderingReceiving} alt="" />
                <img className='orangeIcon' src={orderingReceivingOrange} alt="" />                  
                <span>{props.t('Orders & Receiving')}</span>
              </Link>
              <ul className='sub-menu' aria-expanded='false'>
                <li>
                  <Link to='/orders/list' className='waves-effect'>
                    {/* <i className='fas fa-shopping-cart'></i> */}
                    <img className='blackIcon' src={orderIcon} alt="" />
                  <img className='orangeIcon' src={orderOrangeIcon} alt="" />       
                    <span>{props.t('Orders')}</span>
                  </Link>
                </li>
                <li>
                  <Link to='/savedOrders/list' className='waves-effect'>
                    {/* <i className='fas fa-shopping-cart'></i> */}
                    
                    <img className='blackIcon' src={saveOrderIcon} alt="" />
                    <img className='orangeIcon' src={saveOrderOrangeIcon} alt="" />                      
                    <span>{props.t('Saved Orders & App Exports')}</span>
                  </Link>
                </li>
                <li>
                  <Link to='/emergencyItems/list' className='waves-effect'>
                    {/* <i className='fas fa-shopping-cart'></i> */}
                    <img className='blackIcon' src={EmergencyItemsIcon} alt="" />
                    <img className='orangeIcon' src={EmergencyItemsOrangeIcon} alt="" />                        
                    <span>{props.t('Special Order Items')}</span>
                  </Link>
                </li>
              </ul>
            </li>
            }

            {
                ((selectedBusinessLocationObject != null) && (selectedBusinessLocationObject.stripe_plan_id && selectedBusinessLocationObject.stripe_plan_id != Plans.WOOKAI_SUPPLIER_PRICE_CHECKER))
                  &&
                    <li>
                      <Link to='/#' className='has-arrow waves-effect'>
                        {/* <i className='fas fa-shopping-cart'></i> */}
                        
                        <img className='blackIcon' src={inventoryIcon} alt="" />
                        <img className='orangeIcon' src={inventoryOrangeIcon} alt="" />                 
                        <span>{props.t('Inventory')}</span>
                      </Link>
                      <ul className='sub-menu' aria-expanded='false'>
                        <li>
                          <Link to='/inventoryCosts/list' className='waves-effect'>
                            {/* <i className='fas fa-shopping-cart'></i> */}
                            <img className='blackIcon' src={orderIcon} alt="" />
                          <img className='orangeIcon' src={orderOrangeIcon} alt="" />       
                            <span>{props.t('Inventory Counts')}</span>
                          </Link>
                        </li>
                        <li>
                          <Link to='/inventoryCosts/report' className='waves-effect'>
                            {/* <i className='fas fa-shopping-cart'></i> */}
                            
                            <img className='blackIcon' src={saveOrderIcon} alt="" />
                            <img className='orangeIcon' src={saveOrderOrangeIcon} alt="" />                      
                            <span>{props.t('Cost of Goods Report')}</span>
                          </Link>
                        </li>
                      </ul>
                    </li>
            }

             {
                ((selectedBusinessLocationObject != null) && (selectedBusinessLocationObject.stripe_plan_id && selectedBusinessLocationObject.stripe_plan_id == Plans.WOOKAI_SUPPLIER_PRICE_CHECKER))
                  &&
                <li className='disabled'>
                  <Link to='/#' className='waves-effect'>
                    {/* <i className='fas fa-shopping-cart'></i> */}
                    <img className='blackIcon' src={inventoryIcon} alt="" />
                    <img className='orangeIcon' src={inventoryOrangeIcon} alt="" /> 
  
                    <span>{props.t('Inventory')}</span>
                  </Link>
                </li>
            }

              {
						  ((selectedBusinessLocationObject != null) && (selectedBusinessLocationObject.stripe_plan_id == Plans.WOOKAI_SUPPLY_LIST_MANAGEMENT_SUPPLIER_PRICE_MANAGEMENT_ORDERING_RECEIVING 
                || selectedBusinessLocationObject.stripe_plan_id == Plans.WOOKAI_SUPPLY_LIST_MANAGEMENT_SUPPLIER_PRICE_MANAGEMENT_ORDERING_RECEIVING_ADDITIONAL
                || selectedBusinessLocationObject.stripe_plan_id == Plans.WOOKAI_SUPPLIER_PRICE_CHECKER
                ))
                &&
              <li className='disabled'>
                <Link to='/#' className='has-arrow waves-effect'>
                  {/* <i className='fas fa-pizza-slice'></i> */}
                  
                  <img className='orangeIcon' src={priceManagementOrangeIcon} alt="priceManagementOrangeIcon" />
                  <img className='blackIcon' src={priceManagementIcon} alt="priceManagementIcon" />                      
                  <span>{props.t('Prep Planning & Menu Item Setup')}</span>
                </Link>
                <ul className='sub-menu' aria-expanded='false'>
                  <li>
                    <Link to='#' className='waves-effect'>
                      {/* <i className='fas fa-pizza-slice'></i> */}
                      <img className='blackIcon' src={ProceduresIcon} alt="" />
                      <img className='orangeIcon' src={ProceduresOrangeIcon} alt="" /> 
                      <span>{props.t('Recipes')}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to='#' className='waves-effect'>
                      {/* <i className='fas fa-pizza-slice'></i> */}
                      <img className='blackIcon' src={PrepPlanningIcon} alt="" />
                      <img className='orangeIcon' src={PrepPlanningOrangeIcon} alt="" />                       
                      <span>{props.t('Prep Planning')}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to='#' className='waves-effect'>
                      {/* <i className='fas fa-pizza-slice'></i> */}               
                      <img className='blackIcon' src={IdealProductCostIcon} alt="" />
                      <img className='orangeIcon' src={IdealProductCostOrangeIcon} alt="" />                       
                      <span>{props.t('Menu Costing')}</span>
                    </Link>
                  </li>
                  <li>
                      <Link to='#' className='waves-effect'>
                        <img className='blackIcon' src={DailyProductionIcon} alt="" />
                        <img className='orangeIcon' src={DailyProductionIconYellow} alt="" />
                          <span>{props.t('Daily Production')}</span>
                      </Link>
                  </li>
                  <li>
                    <Link to='#'>
                      <img className='orangeIcon' src={MenuItemsCostPricingOrangeIcon} alt="" />
                      <img className='blackIcon' src={MenuItemsCostPricingIcon} alt="" />  
                      <span>{props.t('Menumatic')}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to='#'>
                      <img className='blackIcon' src={EditParLevelsIcon} alt="" />
                      <img className='orangeIcon' src={EditParLevelsOrangeIcon} alt="" />      
                      <span>{props.t('Edit Recipe Locations')}</span>
                    </Link>
                  </li>
                </ul>
              </li>
              }

            {
						  ((selectedBusinessLocationObject != null) && (selectedBusinessLocationObject.stripe_plan_id == Plans.WOOKAI_SUPPLY_LIST_MANAGEMENT_SUPPLIER_PRICE_MANAGEMENT_PRODUCT_MANAGEMENT 
                || selectedBusinessLocationObject.stripe_plan_id == Plans.WOOKAI_SUPPLY_LIST_MANAGEMENT_SUPPLIER_PRICE_MANAGEMENT_PRODUCT_MANAGEMENT_ADDITIONAL ||
                selectedBusinessLocationObject.stripe_plan_id == Plans.WOOKAI_ULTIMATE ||
                selectedBusinessLocationObject.stripe_plan_id == Plans.WOOKAI_ULTIMATE_ADDITIONAL
                ||  selectedBusinessLocationObject.stripe_plan_id == Plans.WOOKAI_ULTIMATE_YEARLY
                ||  selectedBusinessLocationObject.stripe_plan_id == Plans.WOOKAI_ULTIMATE_YEARLY_ADDITIONAL
                ))
                &&
              <li>
                <Link to='/#' className='has-arrow waves-effect'>
                  {/* <i className='fas fa-pizza-slice'></i> */}
                  
                  <img className='orangeIcon' src={priceManagementOrangeIcon} alt="priceManagementOrangeIcon" />
                  <img className='blackIcon' src={priceManagementIcon} alt="priceManagementIcon" />                         
                  <span>{props.t('Prep Planning & Menu Item Setup')}</span>
                </Link>
                <ul className='sub-menu' aria-expanded='false'>
                  <li>
                    <Link to='/procedures/list' className='waves-effect'>
                      {/* <i className='fas fa-pizza-slice'></i> */}
                      <img className='blackIcon' src={ProceduresIcon} alt="" />
                      <img className='orangeIcon' src={ProceduresOrangeIcon} alt="" /> 
                      <span>{props.t('Recipes')}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to='/prep-plan/list' className='waves-effect'>
                      {/* <i className='fas fa-pizza-slice'></i> */}
                      <img className='blackIcon' src={PrepPlanningIcon} alt="" />
                      <img className='orangeIcon' src={PrepPlanningOrangeIcon} alt="" />                       
                      <span>{props.t('Prep Planning')}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to='/ideal-product-cost/list' className='waves-effect'>
                      {/* <i className='fas fa-pizza-slice'></i> */}               
                      <img className='blackIcon' src={IdealProductCostIcon} alt="" />
                      <img className='orangeIcon' src={IdealProductCostOrangeIcon} alt="" />                       
                      <span>{props.t('Menu Costing')}</span>
                    </Link>
                  </li>
                  <li>
                      <Link to='/reports/prep-plan'>
                        <img className='blackIcon' src={DailyProductionIcon} alt="" />
                        <img className='orangeIcon' src={DailyProductionIconYellow} alt="" />
                          <span>{props.t('Daily Production')}</span>
                      </Link>
                  </li>
                  <li>
                    <Link to='/reports/variance'>
                      <img className='orangeIcon' src={MenuItemsCostPricingOrangeIcon} alt="" />
                      <img className='blackIcon' src={MenuItemsCostPricingIcon} alt="" />  
                      <span>{props.t('Menumatic')}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to='/procedures/editLocations'>
                      <img className='blackIcon' src={EditParLevelsIcon} alt="" />
                      <img className='orangeIcon' src={EditParLevelsOrangeIcon} alt="" />      
                      <span>{props.t('Edit Recipe Locations')}</span>
                    </Link>
                  </li>
                </ul>
              </li>
              }

              {
                ((selectedBusinessLocationObject != null) && (selectedBusinessLocationObject.stripe_plan_id && selectedBusinessLocationObject.stripe_plan_id != Plans.WOOKAI_SUPPLIER_PRICE_CHECKER))
                  &&
                  <li>
                  <Link to='/#' className='has-arrow waves-effect'>
                    {/* <i className='bx bx-store'></i> */}
                    <img className='blackIcon' src={ItemListManagementBlackIcon} alt="" />
                    <img className='orangeIcon' src={ItemListManagementOrangeIcon} alt="" />
                    <span>{props.t('Item List Management')}</span>
                  </Link>
                  <ul className='sub-menu' aria-expanded='false'>
                    <li>
                    
  
                      <Link to='/items/list'>
                          <img className='blackIcon' src={ListIcon} alt="" />
                          <img className='orangeIcon' src={ListOrangeIcon} alt="" />                                               
                          <span>{props.t('List')}</span>
                        </Link>
                    </li>
                    {
                      (selectedBusinessLocationObject) && 
                      <>

                      {/* show pending additional info if length greater than 0 */}
                      {
                          (pendingAdditionalInfo != undefined && pendingAdditionalInfo > 0 && jwtDecode.hide_additional_info == 0) &&
                          <li>
                            <Link to='/items/pendingAdditionalInfo'>
                                 <img className='blackIcon' src={PendingAdditionalInfoIcon} alt="" />
                                 <img className='orangeIcon' src={PendingAdditionalInfoOrangeIcon} alt="" />                             
                                 <span>{props.t('Pending Additional Info')}</span></Link>
                          </li>
                        }

                        {/* show pending units if length greater than 0 */}
                        {
                          (pendingUnits != undefined && pendingUnits > 0) &&
                          <li>
                            <Link to='/items/pendingUnits'>
                             <img className='blackIcon' src={PendingUnitsIcon} alt="" />
                             <img className='orangeIcon' src={PendingUnitsOrangeIcon} alt="" />                           
                              <span>{props.t('Pending Units')}</span></Link>
                          </li>
                        }
                        
                        
                        </>
                      }
  
                      {
                        (selectedBusinessLocationObject && selectedBusinessLocationObject.par_levels_enabled == 1) && 
                        <>
                          {
                            (selectedBusinessLocationObject && selectedBusinessLocationObject.is_slave == 0) &&
                            <>
  
                            {/* show pending par levels if length greater than 0 */}
                            {
                              (pendingParLevels != undefined && pendingParLevels > 0) &&
                              <li>
                                <Link to='/items/pendingParLevels'>
                                  <img className='blackIcon' src={PendingParLevelsIcon} alt="" />
                                  <img className='orangeIcon' src={PendingParLevelsOrangeIcon} alt="" />                                
                                  <span>{props.t('Pending Par Levels')}</span>
                                  </Link>
                              </li>
                            }
                            </>
                            
                          }
                          
                          <li>
                            <Link to='/items/editParLevels'>
                               <img className='blackIcon' src={EditParLevelsIcon} alt="" />
                                <img className='orangeIcon' src={EditParLevelsOrangeIcon} alt="" />                             
                                <span>{props.t('Edit Par Levels')}</span>
                              </Link>
                          </li>
                        </>
                      }
                      {
                          (selectedBusinessLocationObject) &&
                          <li>
                             <Link to='/items/hide'>
                                <img className='blackIcon' src={HideIcon} alt="" />
                                <img className='orangeIcon' src={HideOrange} alt="" />   
                                <span>{props.t('Hide Items')}</span>
                            </Link>         
                          </li>
                      }

                      <li>
                        <Link to='/items/sort'>
                            <img className='blackIcon' src={EditParLevelsIcon} alt="" />
                            <img className='orangeIcon' src={EditParLevelsOrangeIcon} alt="" />                             
                            <span>{props.t('Sort')}</span>
                          </Link>
                      </li>

                      <li>
                        <Link to='/items/editLocations'>
                            <img className='blackIcon' src={EditParLevelsIcon} alt="" />
                            <img className='orangeIcon' src={EditParLevelsOrangeIcon} alt="" />                             
                            <span>{props.t('Edit Locations')}</span>
                          </Link>
                      </li>
                  </ul>
                </li>
              }

              {
                ((selectedBusinessLocationObject != null) && (selectedBusinessLocationObject.stripe_plan_id && selectedBusinessLocationObject.stripe_plan_id == Plans.WOOKAI_SUPPLIER_PRICE_CHECKER))
                  &&
                  <li className='disabled'>
                  <Link to='/#' className='has-arrow waves-effect'>
                    {/* <i className='bx bx-store'></i> */}
                    <img className='blackIcon' src={ItemListManagementBlackIcon} alt="" />
                    <img className='orangeIcon' src={ItemListManagementOrangeIcon} alt="" />
                    <span>{props.t('Item List Management')}</span>
                  </Link>
                  <ul className='sub-menu' aria-expanded='false'>
                    <li>
                    
  
                      <Link to='#'>
                          <img className='blackIcon' src={ListIcon} alt="" />
                          <img className='orangeIcon' src={ListOrangeIcon} alt="" />                                               
                          <span>{props.t('List')}</span>
                        </Link>
                    </li>
                    {
                      (selectedBusinessLocationObject && selectedBusinessLocationObject.is_slave == 0) && 
                      <>

                      {/* show pending additional info if length greater than 0 */}
                      {
                          (pendingAdditionalInfo != undefined && pendingAdditionalInfo > 0 && jwtDecode.hide_additional_info == 0) &&
                          <li>
                            <Link to='#'>
                                 <img className='blackIcon' src={PendingAdditionalInfoIcon} alt="" />
                                 <img className='orangeIcon' src={PendingAdditionalInfoOrangeIcon} alt="" />                             
                                 <span>{props.t('Pending Additional Info')}</span></Link>
                          </li>
                        }

                        {/* show pending units if length greater than 0 */}
                        {
                          (pendingUnits != undefined && pendingUnits > 0) &&
                          <li>
                            <Link to='#'>
                             <img className='blackIcon' src={PendingUnitsIcon} alt="" />
                             <img className='orangeIcon' src={PendingUnitsOrangeIcon} alt="" />                           
                              <span>{props.t('Pending Units')}</span></Link>
                          </li>
                        }
                        
                        
                        </>
                      }
  
                      {
                        (selectedBusinessLocationObject && selectedBusinessLocationObject.par_levels_enabled == 1) && 
                        <>
                          {
                            (selectedBusinessLocationObject && selectedBusinessLocationObject.is_slave == 0) &&
                            <>
  
                            {/* show pending par levels if length greater than 0 */}
                            {
                              (pendingParLevels != undefined && pendingParLevels > 0) &&
                              <li>
                                <Link to='#'>
                                  <img className='blackIcon' src={PendingParLevelsIcon} alt="" />
                                  <img className='orangeIcon' src={PendingParLevelsOrangeIcon} alt="" />                                
                                  <span>{props.t('Pending Par Levels')}</span>
                                  </Link>
                              </li>
                            }
                            </>
                            
                          }
                          
                          <li>
                            <Link to='#'>
                               <img className='blackIcon' src={EditParLevelsIcon} alt="" />
                                <img className='orangeIcon' src={EditParLevelsOrangeIcon} alt="" />                             
                                <span>{props.t('Edit Par Levels')}</span>
                              </Link>
                          </li>
                        </>
                      }
                      {
                          (selectedBusinessLocationObject) &&
                          <li>
                            <Link to='/items/hide'>
                                <img className='blackIcon' src={HideIcon} alt="" />
                                <img className='orangeIcon' src={HideOrange} alt="" />   
                                <span>{props.t('Hide Items')}</span>
                            </Link>         
                          </li>
                      }
                  </ul>
                </li>
              }



              {
                ((selectedBusinessLocationObject != null) && (selectedBusinessLocationObject.stripe_plan_id && selectedBusinessLocationObject.stripe_plan_id == Plans.WOOKAI_SUPPLIER_PRICE_CHECKER))
                  &&
                  <>
                  {
                    (selectedBusinessLocationObject && selectedBusinessLocationObject.is_slave == 0) &&
                    <li>
                      <Link to='/#' className='has-arrow waves-effect'>
                        {/* <i className='fa fa-file'></i> */}
                        
                      <img className='blackIcon' src={Reports} alt="" />
                      <img className='orangeIcon' src={ReportsOrange} alt="" />                     
                        <span>{props.t('Reports')}</span>
                      </Link>
                      <ul className='sub-menu' aria-expanded='false'>
                        <li>
                          <Link to='/reports/vendorPriceChange'>
                          
                            <img className='blackIcon' src={SuppliersPriceChangeIcon} alt="" />
                            <img className='orangeIcon' src={SuppliersPriceChangeOrangeIcon} alt="" />                      
                            <span>{props.t('Suppliers Price Change')}</span>
                          </Link>
                        </li>
                        <li className='disabled'>
                          <Link to='#'>
                            <img className='blackIcon' src={SuppliersUnrelatedItemsIcon} alt="" />
                            <img className='orangeIcon' src={SuppliersUnrelatedItemsOrangeIcon} alt="" />
                            <span>{props.t('Suppliers Unrelated Items')}</span></Link>
                        </li>
                        <li className='disabled'>
                          <Link to='#'>
                            <img className='blackIcon' src={SuppliersAuditIcon} alt="" />
                            <img className='orangeIcon' src={SuppliersAuditOrangeIcon} alt="" />
                            <span>{props.t('Suppliers Audit')}</span>
                            </Link>
                        </li>
                        <li className='disabled'>
                          <Link to='#'>
                          <img className='blackIcon' src={priceHistory} alt="" />
                          <img className='orangeIcon' src={priceHistoryYellow} alt="" />
                            <span>{props.t('Price History')}</span>
                            </Link>
                        </li>
                        <li className='disabled'>
                          <Link to='#'>
                            <img className='blackIcon' src={rebateIcon} alt="" />
                            <img className='orangeIcon' src={rebateOrangeIcon} alt="" />
                            <span>{props.t('Rebate')}</span>
                          </Link>
                        </li>
                      </ul>
                    </li>
                  }
                  </>
              }

              {
                ((selectedBusinessLocationObject != null) && (selectedBusinessLocationObject.stripe_plan_id && selectedBusinessLocationObject.stripe_plan_id != Plans.WOOKAI_SUPPLIER_PRICE_CHECKER))
                  &&
                  <>
                  {
                    (selectedBusinessLocationObject && (userType == 'Client' || userType == 'Mid-Level')) &&
                    <li>
                      <Link to='/#' className='has-arrow waves-effect'>
                        {/* <i className='fa fa-file'></i> */}
                        
                      <img className='blackIcon' src={Reports} alt="" />
                      <img className='orangeIcon' src={ReportsOrange} alt="" />                     
                        <span>{props.t('Reports')}</span>
                      </Link>
                      <ul className='sub-menu' aria-expanded='false'>
                        <li>
                          <Link to='/reports/vendorPriceChange'>
                          
                            <img className='blackIcon' src={SuppliersPriceChangeIcon} alt="" />
                            <img className='orangeIcon' src={SuppliersPriceChangeOrangeIcon} alt="" />                      
                            <span>{props.t('Suppliers Price Change')}</span>
                          </Link>
                        </li>
                        <li>
                          <Link to='/reports/vendorUnrelatedItems'>
                            <img className='blackIcon' src={SuppliersUnrelatedItemsIcon} alt="" />
                            <img className='orangeIcon' src={SuppliersUnrelatedItemsOrangeIcon} alt="" />
                            <span>{props.t('Suppliers Unrelated Items')}</span></Link>
                        </li>
                        <li>
                          <Link to='/reports/vendorsAudit'>
                            <img className='blackIcon' src={SuppliersAuditIcon} alt="" />
                            <img className='orangeIcon' src={SuppliersAuditOrangeIcon} alt="" />
                            <span>{props.t('Suppliers Audit')}</span>
                            </Link>
                        </li>
                        <li>
                          <Link to='/reports/priceHistory'>
                          <img className='blackIcon' src={priceHistory} alt="" />
                          <img className='orangeIcon' src={priceHistoryYellow} alt="" />
                            <span>{props.t('Price History')}</span>
                            </Link>
                        </li>
                        <li className=''>
                          <Link to='/reports/rebates'>
                          <img className='blackIcon' src={rebateIcon} alt="" />
                          <img className='orangeIcon' src={rebateOrangeIcon} alt="" />
                            <span>{props.t('Rebate')}</span>
                            </Link>
                        </li>
                      </ul>
                    </li>
                  }
                  </>
              }


            {
              ((selectedBusinessLocationObject != null) && (selectedBusinessLocationObject.stripe_plan_id && selectedBusinessLocationObject.stripe_plan_id != Plans.WOOKAI_SUPPLIER_PRICE_CHECKER))
                &&
              <li>
                <a target = "_blank"  href="https://wookai.com/wookai-members-only">
                  {/* <i className='fas fa-shopping-cart'></i> */}
                  <img className='blackIcon' src={helpIcon} alt="" />
                  <img className='orangeIcon' src={helpOrangeIcon} alt="" />                  
                  <span>{props.t('Help')}</span>
                </a>
              </li>
            }

            {
              ((selectedBusinessLocationObject != null) && (selectedBusinessLocationObject.stripe_plan_id && selectedBusinessLocationObject.stripe_plan_id == Plans.WOOKAI_SUPPLIER_PRICE_CHECKER))
                &&
              <li className='disabled'>
                <a target = "_blank"  href="https://wookai.com/wookai-members-only">
                  {/* <i className='fas fa-shopping-cart'></i> */}
                  <img className='blackIcon' src={helpIcon} alt="" />
                  <img className='orangeIcon' src={helpOrangeIcon} alt="" />                  
                  <span>{props.t('Help')}</span>
                </a>
              </li>
            }

            </React.Fragment>
          )}

          {/* Side bar for Mid-Level */}    
          {userType == 'Mid-Level' && (
            <React.Fragment>

              {
                <li>
                  <Link to='/#' className='has-arrow waves-effect'>
                    {/* <i className='fas fa-balance-scale'></i> */}
                    <img className='blackIcon' src={wookaiBlackIcon} alt="" />
                    <img className='orangeIcon' src={wookaiOrangeIcon} alt="" />
                    <span>{props.t('Measurements & Organization')}</span>
                  </Link>
                  <ul className='sub-menu' aria-expanded='false'>
                    {
                      (selectedBusinessLocationObject && selectedBusinessLocationObject.is_slave == 0) && 
                      <>
                        <li>
                          <Link to='/units/list'>
                            {/* <i className='fas fa-balance-scale'></i> */}
                            <img className='blackIcon' src={UnitsIcon} alt="" />
                            <img className='orangeIcon' src={UnitsOrange} alt="" />

                            <span>{props.t('Units')}</span>
                          </Link>
                        </li>
                        <li>
                          <Link to='/conversions/list' className='waves-effect'>
                            {/* <i className='fas fa-exchange-alt'></i> */}
                            
                            <img className='blackIcon' src={ConversionsIcon} alt="" />
                            <img className='orangeIcon' src={ConversionsOrangeIcon} alt="" />                        
                            <span>{props.t('Conversions')}</span>
                          </Link>
                        </li>
                        <li>
                          <Link to='/categories/list' className='waves-effect'>
                            {/* <i className='fas fa-clipboard-list'></i> */}
                            
                            <img className='blackIcon' src={categoriesIcon} alt="" />
                            <img className='orangeIcon' src={categoriesOrangeIcon} alt="" />                           
                            <span>{props.t('Categories')}</span>
                          </Link>
                        </li>
                        <li>
                          <Link to='/subCategories/list' className='waves-effect'>
                            {/* <i className="fas fa-project-diagram"></i> */}
                            <img className='blackIcon' src={SubCategoriesIcon} alt="" />
                            <img className='orangeIcon' src={SubCategoriesOrangeIcon} alt="" />                          
                            <span>{props.t('Sub Categories')}</span>
                          </Link>
                        </li>
                      </>
                    }
                    
                    <li>
                      <Link to='/itemLocations/list' className='waves-effect'>
                        {/* <i className='fas fa-location-arrow'></i> */}                         
                        <img className='blackIcon' src={ItemsLocationIcon} alt="" />
                        <img className='orangeIcon' src={ItemsLocationOrangeIcon} alt="" />                          
                        <span>{props.t('Item Locations')}</span>
                      </Link>
                    </li>
                  </ul>
                </li>         
              }

              <li>
                <Link to='/#' className='has-arrow waves-effect'>
                  {/* <i className='fas fa-pizza-slice'></i> */}
                  
                  <img className='blackIcon' src={priceManagementIcon} alt="" />
                  <img className='orangeIcon' src={priceManagementOrangeIcon} alt="" />                      
                  <span>{props.t('Prep Planning & Menu Item Setup')}</span>
                </Link>
                <ul className='sub-menu' aria-expanded='false'>
                  <li>
                    <Link to='/procedures/list' className='waves-effect'>
                      {/* <i className='fas fa-pizza-slice'></i> */}
                      <img className='blackIcon' src={ProceduresIcon} alt="" />
                      <img className='orangeIcon' src={ProceduresOrangeIcon} alt="" /> 
                      <span>{props.t('Recipes')}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to='/prep-plan/list' className='waves-effect'>
                      {/* <i className='fas fa-pizza-slice'></i> */}
                      <img className='blackIcon' src={PrepPlanningIcon} alt="" />
                      <img className='orangeIcon' src={PrepPlanningOrangeIcon} alt="" />                       
                      <span>{props.t('Prep Planning')}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to='/ideal-product-cost/list' className='waves-effect'>
                      {/* <i className='fas fa-pizza-slice'></i> */}               
                      <img className='blackIcon' src={IdealProductCostIcon} alt="" />
                      <img className='orangeIcon' src={IdealProductCostOrangeIcon} alt="" />                       
                      <span>{props.t('Ideal Product Cost')}</span>
                    </Link>
                  </li>
                  <li>
                      <Link to='/reports/prep-plan'>
                        <img className='blackIcon' src={DailyProductionIcon} alt="" />
                        <img className='orangeIcon' src={DailyProductionIconYellow} alt="" />
                        <span>{props.t('Daily Production')}</span>
                      </Link>
                  </li>
                  <li>
                    <Link to='/reports/variance'>
                      <img className='blackIcon' src={rebateIcon} alt="" />
                      <img className='orangeIcon' src={rebateOrangeIcon} alt="" />
                      <span>{props.t('Menumatic')}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to='/procedures/editLocations'>
                      <img className='blackIcon' src={EditParLevelsIcon} alt="" />
                      <img className='orangeIcon' src={EditParLevelsOrangeIcon} alt="" />      
                      <span>{props.t('Edit Recipe Locations')}</span>
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to='/#' className='has-arrow waves-effect'>
                  {/* <i className='fas fa-users'></i> */} 
                  <img className='blackIcon' src={supplierUserManagementIcon} alt="" />
                  <img className='orangeIcon' src={supplierUserManagementOrangeIcon} alt="" />

                  <span>{props.t('Supplier & User Management')}</span>
                </Link>
                <ul className='sub-menu' aria-expanded='false'>
                  {
                    (selectedBusinessLocationObject && selectedBusinessLocationObject.is_slave == 0) &&
                    <li>
                      <Link to='/vendors/list' className='waves-effect'>
                        {/* <i className='fas fa-industry'></i> */}
                        <img className='blackIcon' src={supplierIcon} alt="" />
                        <img className='orangeIcon' src={supplierOrangeIcon} alt="" />
                        
                        <span>{props.t('Suppliers')}</span>
                      </Link>
                    </li>
                  }
                    <li>
                      <Link to='/users/list' className='waves-effect'>
                        {/* <i className='fas fa-users'></i> */}
                        <img className='blackIcon' src={managAssistantIcon} alt="" />
                        <img className='orangeIcon' src={managAssistantOrangeIcon} alt="" />                        
                        <span>{props.t('Supervisors/Managers')}</span>
                      </Link>
                    </li>

                    <li>
                      <Link to='/assignedReps/list' className='waves-effect'>
                        {/* <i className="fas fa-chalkboard-teacher"></i> */}
                        
                        <img className='blackIcon' src={salesRepresentativeIcon} alt="" />
                        <img className='orangeIcon' src={salesRepresentativeOrangeIcon} alt="" />                           
                        <span>{props.t('Sales Representatives')}</span>
                      </Link>
                    </li>
                </ul>
              </li>


              <li>
                <Link to='/#' className='has-arrow waves-effect'>
                  {/* <i className='bx bx-store'></i> */}
                  <img className='blackIcon' src={ItemListManagementBlackIcon} alt="" />
                  <img className='orangeIcon' src={ItemListManagementOrangeIcon} alt="" />
                  <span>{props.t('Item List Management')}</span>
                </Link>
                <ul className='sub-menu' aria-expanded='false'>
                  <li>
                  

                    <Link to='/items/list'>
                        <img className='blackIcon' src={ListIcon} alt="" />
                        <img className='orangeIcon' src={ListOrangeIcon} alt="" />                                               
                        <span>{props.t('List')}</span>
                      </Link>
                  </li>
                  {
                    (selectedBusinessLocationObject && selectedBusinessLocationObject.is_slave == 0) && 
                    <>

                    {/* show pending additional info if length greater than 0 */}
                    {
                        (pendingAdditionalInfo != undefined && pendingAdditionalInfo > 0 && jwtDecode.hide_additional_info == 0) &&
                        <li>
                          <Link to='/items/pendingAdditionalInfo'>
                               <img className='blackIcon' src={PendingAdditionalInfoIcon} alt="" />
                               <img className='orangeIcon' src={PendingAdditionalInfoOrangeIcon} alt="" />                             
                               <span>{props.t('Pending Additional Info')}</span></Link>
                        </li>
                      }
                      
                      {/* show pending units if length greater than 0 */}
                      {
                        (pendingUnits != undefined && pendingUnits > 0) &&
                        <li>
                          <Link to='/items/pendingUnits'>
                           <img className='blackIcon' src={PendingUnitsIcon} alt="" />
                           <img className='orangeIcon' src={PendingUnitsOrangeIcon} alt="" />                           
                            <span>{props.t('Pending Units')}</span></Link>
                        </li>
                      }
                      
                      
                      </>
                    }

                    {
                      (selectedBusinessLocationObject && selectedBusinessLocationObject.par_levels_enabled == 1) && 
                      <>
                        {
                          (selectedBusinessLocationObject && selectedBusinessLocationObject.is_slave == 0) &&
                          <>

                          {/* show pending par levels if length greater than 0 */}
                          {
                            (pendingParLevels != undefined && pendingParLevels > 0) &&
                            <li>
                              <Link to='/items/pendingParLevels'>
                                <img className='blackIcon' src={PendingParLevelsIcon} alt="" />
                                <img className='orangeIcon' src={PendingParLevelsOrangeIcon} alt="" />                                
                                <span>{props.t('Pending Par Levels')}</span>
                                </Link>
                            </li>
                          }
                          </>
                          
                        }
                        
                        <li>
                          <Link to='/items/editParLevels'>
                             <img className='blackIcon' src={EditParLevelsIcon} alt="" />
                              <img className='orangeIcon' src={EditParLevelsOrangeIcon} alt="" />                             
                              <span>{props.t('Edit Par Levels')}</span>
                            </Link>
                        </li>
                      </>
                    }
                    {
                        (selectedBusinessLocationObject) &&
                        <li>
                            <Link to='/items/hide'>
                                <img className='blackIcon' src={HideIcon} alt="" />
                                <img className='orangeIcon' src={HideOrange} alt="" />   
                                <span>{props.t('Hide Items')}</span>
                            </Link>                        
                        </li>
                    }

                    <li>
                        <Link to='/items/sort'>
                            <img className='blackIcon' src={EditParLevelsIcon} alt="" />
                            <img className='orangeIcon' src={EditParLevelsOrangeIcon} alt="" />                             
                            <span>{props.t('Sort')}</span>
                          </Link>
                    </li>
                    <li>
                        <Link to='/items/editLocations'>
                            <img className='blackIcon' src={EditParLevelsIcon} alt="" />
                            <img className='orangeIcon' src={EditParLevelsOrangeIcon} alt="" />                             
                            <span>{props.t('Edit Locations')}</span>
                          </Link>
                      </li>
                </ul>
              </li>


              <li>
                <Link to='/#' className='has-arrow waves-effect'>
                  {/* <i className='fas fa-shopping-cart'></i> */}
                  
                  <img className='blackIcon' src={orderingReceiving} alt="" />
                  <img className='orangeIcon' src={orderingReceivingOrange} alt="" />                  
                  <span>{props.t('Orders & Receiving')}</span>
                </Link>
                <ul className='sub-menu' aria-expanded='false'>
                  <li>
                    <Link to='/orders/list' className='waves-effect'>
                      {/* <i className='fas fa-shopping-cart'></i> */}
                      <img className='blackIcon' src={orderIcon} alt="" />
                     <img className='orangeIcon' src={orderOrangeIcon} alt="" />       
                      <span>{props.t('Orders')}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to='/savedOrders/list' className='waves-effect'>
                      {/* <i className='fas fa-shopping-cart'></i> */}
                      
                      <img className='blackIcon' src={saveOrderIcon} alt="" />
                      <img className='orangeIcon' src={saveOrderOrangeIcon} alt="" />                      
                      <span>{props.t('Saved Orders & App Exports')}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to='/emergencyItems/list' className='waves-effect'>
                      {/* <i className='fas fa-shopping-cart'></i> */}
                      <img className='blackIcon' src={EmergencyItemsIcon} alt="" />
                      <img className='orangeIcon' src={EmergencyItemsOrangeIcon} alt="" />                        
                      <span>{props.t('Special Order Items')}</span>
                    </Link>
                  </li>
                </ul>
              </li>

              {
                (selectedBusinessLocationObject) &&
                <li>
                  <Link to='/#' className='has-arrow waves-effect'>
                    {/* <i className='fa fa-file'></i> */}
                    
                    <img className='blackIcon' src={Reports} alt="" />
                    <img className='orangeIcon' src={ReportsOrange} alt="" />                     
                    <span>{props.t('Reports')}</span>
                  </Link>
                  <ul className='sub-menu' aria-expanded='false'>
                    <li>
                      <Link to='/reports/vendorPriceChange'>
                      
                        <img className='blackIcon' src={SuppliersPriceChangeIcon} alt="" />
                        <img className='orangeIcon' src={SuppliersPriceChangeOrangeIcon} alt="" />                      
                        <span>{props.t('Suppliers Price Change')}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to='/reports/vendorUnrelatedItems'>
                        <img className='blackIcon' src={SuppliersUnrelatedItemsIcon} alt="" />
                        <img className='orangeIcon' src={SuppliersUnrelatedItemsOrangeIcon} alt="" />
                        <span>{props.t('Suppliers Unrelated Items')}</span></Link>
                    </li>
                    <li>
                      <Link to='/reports/vendorsAudit'>
                        <img className='blackIcon' src={SuppliersAuditIcon} alt="" />
                        <img className='orangeIcon' src={SuppliersAuditOrangeIcon} alt="" />
                        <span>{props.t('Suppliers Audit')}</span>
                        </Link>
                    </li>
                    <li>
                      <Link to='/reports/priceHistory'>
                          <img className='blackIcon' src={priceHistory} alt="" />
                          <img className='orangeIcon' src={priceHistoryYellow} alt="" />
                        <span>{props.t('Price History')}</span>
                      </Link>
                    </li>
                    <li className=''>
                      <Link to='/reports/rebates'>
                        <img className='blackIcon' src={rebateIcon} alt="" />
                        <img className='orangeIcon' src={rebateOrangeIcon} alt="" />
                        <span>{props.t('Rebate')}</span>
                      </Link>
                    </li>
                  </ul>
                </li>
              }

              <li>
                <Link to='/#' className='has-arrow waves-effect'>
                  {/* <i className='fas fa-shopping-cart'></i> */}
                  
                  <img className='blackIcon' src={inventoryIcon} alt="" />
                  <img className='orangeIcon' src={inventoryOrangeIcon} alt="" />                 
                  <span>{props.t('Inventory')}</span>
                </Link>
                <ul className='sub-menu' aria-expanded='false'>
                  <li>
                    <Link to='/inventoryCosts/list' className='waves-effect'>
                      {/* <i className='fas fa-shopping-cart'></i> */}
                      <img className='blackIcon' src={orderIcon} alt="" />
                    <img className='orangeIcon' src={orderOrangeIcon} alt="" />       
                      <span>{props.t('Inventory Counts')}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to='/inventoryCosts/report' className='waves-effect'>
                      {/* <i className='fas fa-shopping-cart'></i> */}
                      
                      <img className='blackIcon' src={saveOrderIcon} alt="" />
                      <img className='orangeIcon' src={saveOrderOrangeIcon} alt="" />                      
                      <span>{props.t('Cost of Goods Report')}</span>
                    </Link>
                  </li>
                </ul>
              </li>


              <li>
              <a target = "_blank"  href="https://wookai.com/wookai-members-only">
                  {/* <i className='fas fa-shopping-cart'></i> */}
                  <img className='blackIcon' src={helpIcon} alt="" />
                  <img className='orangeIcon' src={helpOrangeIcon} alt="" />                  
                  <span>{props.t('Help')}</span>
                </a>
              </li>      

             
            </React.Fragment>
          )}

           {/* Side bar for Mid-Level */}    
          {userType == 'Low-Level' && (
            <React.Fragment>
               <li>
                <Link to='/#' className='has-arrow waves-effect'>
                  {/* <i className='fas fa-pizza-slice'></i> */}
                  
                  <img className='blackIcon' src={priceManagementIcon} alt="" />
                  <img className='orangeIcon' src={priceManagementOrangeIcon} alt="" />                      
                  <span>{props.t('Prep Planning & Menu Item Setup')}</span>
                </Link>
                <ul className='sub-menu' aria-expanded='false'>
                  <li>
                    <Link to='/procedures/list' className='waves-effect'>
                      {/* <i className='fas fa-pizza-slice'></i> */}
                      <img className='blackIcon' src={ProceduresIcon} alt="" />
                      <img className='orangeIcon' src={ProceduresOrangeIcon} alt="" /> 
                      <span>{props.t('Recipes')}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to='/prep-plan/list' className='waves-effect'>
                      {/* <i className='fas fa-pizza-slice'></i> */}
                      <img className='blackIcon' src={PrepPlanningIcon} alt="" />
                      <img className='orangeIcon' src={PrepPlanningOrangeIcon} alt="" />                       
                      <span>{props.t('Prep Planning')}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to='/ideal-product-cost/list' className='waves-effect'>
                      {/* <i className='fas fa-pizza-slice'></i> */}               
                      <img className='blackIcon' src={IdealProductCostIcon} alt="" />
                      <img className='orangeIcon' src={IdealProductCostOrangeIcon} alt="" />                       
                      <span>{props.t('Ideal Product Cost')}</span>
                    </Link>
                  </li>
                  <li>
                      <Link to='/reports/prep-plan'>
                        <img className='blackIcon' src={DailyProductionIcon} alt="" />
                        <img className='orangeIcon' src={DailyProductionIconYellow} alt="" />
                        <span>{props.t('Daily Production')}</span>
                      </Link>
                  </li>
                  <li>
                    <Link to='/reports/variance'>
                      <img className='blackIcon' src={rebateIcon} alt="" />
                      <img className='orangeIcon' src={rebateOrangeIcon} alt="" />
                      <span>{props.t('Menumatic')}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to='/procedures/editLocations'>
                      <img className='blackIcon' src={EditParLevelsIcon} alt="" />
                      <img className='orangeIcon' src={EditParLevelsOrangeIcon} alt="" />      
                      <span>{props.t('Edit Recipe Locations')}</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to='/#' className='has-arrow waves-effect'>
                  {/* <i className='fas fa-shopping-cart'></i> */}
                  
                  <img className='blackIcon' src={orderingReceiving} alt="" />
                  <img className='orangeIcon' src={orderingReceivingOrange} alt="" />                  
                  <span>{props.t('Orders & Receiving')}</span>
                </Link>
                
                <ul className='sub-menu' aria-expanded='false'>
                  <li>
                    <Link to='/orders/list' className='waves-effect'>
                      {/* <i className='fas fa-shopping-cart'></i> */}
                      <img className='blackIcon' src={orderIcon} alt="" />
                     <img className='orangeIcon' src={orderOrangeIcon} alt="" />       
                      <span>{props.t('Orders')}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to='/savedOrders/list' className='waves-effect'>
                      {/* <i className='fas fa-shopping-cart'></i> */}
                      
                      <img className='blackIcon' src={saveOrderIcon} alt="" />
                      <img className='orangeIcon' src={saveOrderOrangeIcon} alt="" />                      
                      <span>{props.t('Saved Orders & App Exports')}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to='/emergencyItems/list' className='waves-effect'>
                      {/* <i className='fas fa-shopping-cart'></i> */}
                      <img className='blackIcon' src={EmergencyItemsIcon} alt="" />
                      <img className='orangeIcon' src={EmergencyItemsOrangeIcon} alt="" />                        
                      <span>{props.t('Special Order Items')}</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                  <Link to='/#' className='has-arrow waves-effect'>
                    {/* <i className='fa fa-file'></i> */}
                    
                  <img className='blackIcon' src={Reports} alt="" />
                  <img className='orangeIcon' src={ReportsOrange} alt="" />                     
                    <span>{props.t('Reports')}</span>
                  </Link>
                  <ul className='sub-menu jkl' aria-expanded='false'>
                    <li>
                      <Link to='/reports/vendorPriceChange'>
                      
                        <img className='blackIcon' src={SuppliersPriceChangeIcon} alt="" />
                        <img className='orangeIcon' src={SuppliersPriceChangeOrangeIcon} alt="" />                      
                        <span>{props.t('Suppliers Price Change')}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to='/reports/vendorUnrelatedItems'>
                        <img className='blackIcon' src={SuppliersUnrelatedItemsIcon} alt="" />
                        <img className='orangeIcon' src={SuppliersUnrelatedItemsOrangeIcon} alt="" />
                        <span>{props.t('Suppliers Unrelated Items')}</span></Link>
                    </li>
                    <li>
                      <Link to='/reports/vendorsAudit'>
                        <img className='blackIcon' src={SuppliersAuditIcon} alt="" />
                        <img className='orangeIcon' src={SuppliersAuditOrangeIcon} alt="" />
                        <span>{props.t('Suppliers Audit')}</span>
                        </Link>
                    </li>
                    <li>
                      <Link to='/reports/priceHistory'>
                        <img className='blackIcon' src={priceHistory} alt="" />
                        <img className='orangeIcon' src={priceHistoryYellow} alt="" />
                        <span>{props.t('Price History')}</span>
                      </Link>
                    </li>
                  <li className=''>
                    <Link to='/reports/rebates'>
                      <img className='blackIcon' src={rebateIcon} alt="" />
                      <img className='orangeIcon' src={rebateOrangeIcon} alt="" />
                      <span>{props.t('Rebate')}</span>
                    </Link>
                  </li>
                  </ul>
                </li>
            </React.Fragment>
          )}        

          {/* Side bar for Admin/Sub Admin */}        
          {
            (userType == 'Super-Admin' || userType == 'Sub-Admin') && 
            <>
             {userType === 'Super-Admin' && (
                <li>
                  <Link to='/admins/list' className='waves-effect'>
                    <i className='bx bx-store'></i>
                    <span>{props.t('Admins')}</span>
                  </Link>
                </li>
              )}
              <li>
                <Link to='/clients/list' className='waves-effect'>
                  <i className='bx bx-store'></i>
                  <span>{props.t('Clients')}</span>
                </Link>
              </li>
              <li>
                <Link to='/users/list' className='waves-effect'>
                  <i className='bx bx-store'></i>
                  <span>{props.t('Supervisors/Managers')}</span>
                </Link>
              </li>
              <li>
              <Link to='/supplierReps/list' className='waves-effect'>
                <i className="fas fa-chalkboard-teacher"></i>
                <span>{props.t('Sales Representatives')}</span>
              </Link>
            </li>
            <li>
              <Link to='/wookaiVendors/list' className='waves-effect'>
                <i className='bx bx-store'></i>
                <span>{props.t('WookAI Suppliers')}</span>
              </Link>
            </li>

            <li>
              <Link to='/units/list' className='waves-effect'>
                <i className='bx bx-store'></i>
                <span>{props.t('Units')}</span>
              </Link>
            </li>         

            <li>
              <Link to='/conversions/list' className='waves-effect'>
                <i className='bx bx-store'></i>
                <span>{props.t('Conversions')}</span>
              </Link>
            </li>

            <li>
              <Link to='/categories/list' className='waves-effect'>
                <i className='fas fa-clipboard-list'></i>
                <span>{props.t('Categories')}</span>
              </Link>
            </li>

            <li>
              <Link to='/subCategories/list' className='waves-effect'>
                <i className="fas fa-project-diagram"></i>
                <span>{props.t('Sub Categories')}</span>
              </Link>
            </li>

            <li>
              <Link to='/itemLocations/list' className='waves-effect'>
                <i className='fas fa-location-arrow'></i>
                <span>{props.t('Item Locations')}</span>
              </Link>
            </li>

            <li>
                <Link to='/procedures/list' className='waves-effect'>
                  <i className='fas fa-pizza-slice'></i>
                  <span>{props.t('Recipes')}</span>
                </Link>
              </li>

            <li>
              <Link to='/businessProfiles/list' className='waves-effect'>
                <i className='bx bx-store'></i>
                <span>{props.t('Business Profiles')}</span>
              </Link>
            </li>
            
            <li>
              <Link to='/defaultProfiles/list' className='waves-effect'>
                <i className='bx bx-store'></i>
                <span>{props.t('Demo Profiles')}</span>
              </Link>
            </li>

            <li>
              <Link to='/#' className='has-arrow waves-effect'>
                <i className='bx bx-store'></i>
                <span>{props.t('Emails')}</span>
              </Link>
              <ul className='sub-menu' aria-expanded='false'>
                <li>
                  <Link to='/massEmails/list'>{props.t('List Mass Emails')}</Link>
                </li>
                <li>
                  <Link to='/emailContents/list'>{props.t('List Email Templates')}</Link>
                </li>
                {/* <li>
                  <Link to='/cannedEmails/add'>{props.t('Add Canned Emails')}</Link>
                </li>
                <li>
                  <Link to='/cannedEmails/list'>{props.t('List Canned Emails')}</Link>
                </li> */}
              </ul>
            </li>

            <li>
                <Link to='/cms/list' className='waves-effect'>
                  <i className='bx bx-store'></i>
                  <span>{props.t('Cms')}</span>
                </Link>
              </li>

              <li>
                <Link to='/videos/list' className='waves-effect'>
                  <i className='bx bx-store'></i>
                  <span>{props.t('Videos')}</span>
                </Link>
              </li>

              <li>
                <Link to='/ads' className=' waves-effect'>
                  <i className='bx bx-calendar'></i>
                  <span>{props.t('Google Ads')}</span>
                </Link>
              </li>

              <li>
                <Link to='/enquiries' className=' waves-effect'>
                  <i className='fas fa-address-book'></i>
                  <span>{props.t('Enquiries')}</span>
                </Link>
              </li>

              <li>
                <Link to='/settings' className=' waves-effect'>
                  <i className='bx bx-calendar'></i>
                  <span>{props.t('Settings')}</span>
                </Link>
              </li>
           </>
          }

          {/* Side bar for Rep Level */}   
          {userType === "Rep-Level" && (
            <React.Fragment>
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  {/* <i className='bx bx-store'></i> */}
                  <img
                    className="blackIcon"
                    src={ItemListManagementBlackIcon}
                    alt=""
                  />
                  <img
                    className="orangeIcon"
                    src={ItemListManagementOrangeIcon}
                    alt=""
                  />
                  <span>{props.t("Item List Management")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/repItems/list">
                      <img className="blackIcon" src={ListIcon} alt="" />
                      <img className="orangeIcon" src={ListOrangeIcon} alt="" />
                      <span>{props.t("List")}</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                  <Link to='/#' className='has-arrow waves-effect'>
                    {/* <i className='fas fa-users'></i> */} 
                    <img className='blackIcon' src={supplierUserManagementIcon} alt="" />
                    <img className='orangeIcon' src={supplierUserManagementOrangeIcon} alt="" />
  
                    <span>{props.t('Suppliers')}</span>
                  </Link>
                  <ul className='sub-menu' aria-expanded='false'>
                    <li>
                    <Link to='/repVendors/list' className='waves-effect'>
                      {/* <i className='fas fa-industry'></i> */}
                      <img className="blackIcon" src={ListIcon} alt="" />
                      <img className="orangeIcon" src={ListOrangeIcon} alt="" />
                      
                      <span>{props.t('List')}</span>
                    </Link>
                  </li>
                      
                  </ul>
                </li>
                <li>
              <Link to='/#' className='has-arrow waves-effect'>
                {/* <i className='fas fa-shopping-cart'></i> */}
                
                <img className='blackIcon' src={orderingReceiving} alt="" />
                <img className='orangeIcon' src={orderingReceivingOrange} alt="" />                  
                <span>{props.t('Orders')}</span>
              </Link>
              <ul className='sub-menu' aria-expanded='false'>
                <li>
                  <Link to='/repOrders/list' className='waves-effect'>
                    {/* <i className='fas fa-shopping-cart'></i> */}
                    <img className="blackIcon" src={ListIcon} alt="" />
                    <img className="orangeIcon" src={ListOrangeIcon} alt="" />    
                    <span>{props.t('List')}</span>
                  </Link>
                </li>
                <li>
                  <Link to='/repSavedOrders/list' className='waves-effect'>
                    {/* <i className='fas fa-shopping-cart'></i> */}
                    
                    <img className='blackIcon' src={saveOrderIcon} alt="" />
                    <img className='orangeIcon' src={saveOrderOrangeIcon} alt="" />                      
                    <span>{props.t('Requests for Pricing')}</span>
                  </Link>
                </li>
              </ul>
            </li>
            </React.Fragment>
          )}
           



          {/* <li className='menu-title'>{props.t('Apps')}</li>

          <li>
            <Link to='calendar' className=' waves-effect'>
              <i className='bx bx-calendar'></i>
              <span>{props.t('Calendar')}</span>
            </Link>
          </li>

          <li>
            <Link to='chat' className=' waves-effect'>
              <i className='bx bx-chat'></i>
              <span className='badge badge-pill badge-success float-right'>
                {props.t('New')}
              </span>
              <span>{props.t('Chat')}</span>
            </Link>
          </li>

          <li>
            <Link to='/#' className='has-arrow waves-effect'>
              <i className='bx bx-store'></i>
              <span>{props.t('Ecommerce')}</span>
            </Link>
            <ul className='sub-menu' aria-expanded='false'>
              <li>
                <Link to='ecommerce-products'>{props.t('Products')}</Link>
              </li>
              <li>
                <Link to='ecommerce-product-detail'>
                  {props.t('Product Detail')}
                </Link>
              </li>
              <li>
                <Link to='ecommerce-orders'>{props.t('Orders')}</Link>
              </li>
              <li>
                <Link to='ecommerce-customers'>{props.t('Customers')}</Link>
              </li>
              <li>
                <Link to='ecommerce-cart'>{props.t('Cart')}</Link>
              </li>
              <li>
                <Link to='ecommerce-checkout'>{props.t('Checkout')}</Link>
              </li>
              <li>
                <Link to='ecommerce-shops'>{props.t('Shops')}</Link>
              </li>
              <li>
                <Link to='ecommerce-add-product'>{props.t('Add Product')}</Link>
              </li>
            </ul>
          </li>

          <li>
            <Link to='/#' className='has-arrow waves-effect'>
              <i className='bx bx-bitcoin'></i>
              <span>{props.t('Crypto')}</span>
            </Link>
            <ul className='sub-menu' aria-expanded='false'>
              <li>
                <Link to='crypto-wallet'>{props.t('Wallet')}</Link>
              </li>
              <li>
                <Link to='crypto-buy-sell'>{props.t('Buy/Sell')}</Link>
              </li>
              <li>
                <Link to='crypto-exchange'>{props.t('Exchange')}</Link>
              </li>
              <li>
                <Link to='crypto-lending'>{props.t('Lending')}</Link>
              </li>
              <li>
                <Link to='crypto-orders'>{props.t('Orders')}</Link>
              </li>
              <li>
                <Link to='crypto-kyc-application'>
                  {props.t('KYC Application')}
                </Link>
              </li>
              <li>
                <Link to='crypto-ico-landing'>{props.t('ICO Landing')}</Link>
              </li>
            </ul>
          </li>

          <li>
            <Link to='/#' className='has-arrow waves-effect'>
              <i className='bx bx-envelope'></i>
              <span>{props.t('Email')}</span>
            </Link>
            <ul className='sub-menu' aria-expanded='false'>
              <li>
                <Link to='email-inbox'>{props.t('Inbox')}</Link>
              </li>
              <li>
                <Link to='email-read'>{props.t('Read Email')} </Link>
              </li>
            </ul>
          </li>

          <li>
            <Link to='/#' className='has-arrow waves-effect'>
              <i className='bx bx-receipt'></i>
              <span>{props.t('Invoices')}</span>
            </Link>
            <ul className='sub-menu' aria-expanded='false'>
              <li>
                <Link to='invoices-list'>{props.t('Invoice List')}</Link>
              </li>
              <li>
                <Link to='invoices-detail'>{props.t('Invoice Detail')}</Link>
              </li>
            </ul>
          </li>

          <li>
            <Link to='/#' className='has-arrow waves-effect'>
              <i className='bx bx-briefcase-alt-2'></i>
              <span>{props.t('Projects')}</span>
            </Link>
            <ul className='sub-menu' aria-expanded='false'>
              <li>
                <Link to='projects-grid'>{props.t('Projects Grid')}</Link>
              </li>
              <li>
                <Link to='projects-list'>{props.t('Projects List')}</Link>
              </li>
              <li>
                <Link to='projects-overview'>
                  {props.t('Project Overview')}
                </Link>
              </li>
              <li>
                <Link to='projects-create'>{props.t('Create New')}</Link>
              </li>
            </ul>
          </li>

          <li>
            <Link to='/#' className='has-arrow waves-effect'>
              <i className='bx bx-task'></i>
              <span>{props.t('Tasks')}</span>
            </Link>
            <ul className='sub-menu' aria-expanded='false'>
              <li>
                <Link to='tasks-list'>{props.t('Task List')}</Link>
              </li>
              <li>
                <Link to='tasks-kanban'>{props.t('Kanban Board')}</Link>
              </li>
              <li>
                <Link to='tasks-create'>{props.t('Create Task')}</Link>
              </li>
            </ul>
          </li>

          <li>
            <Link to='/#' className='has-arrow waves-effect'>
              <i className='bx bxs-user-detail'></i>
              <span>{props.t('Contacts')}</span>
            </Link>
            <ul className='sub-menu' aria-expanded='false'>
              <li>
                <Link to='contacts-grid'>{props.t('User Grid')}</Link>
              </li>
              <li>
                <Link to='contacts-list'>{props.t('User List')}</Link>
              </li>
              <li>
                <Link to='contacts-profile'>{props.t('Profile')}</Link>
              </li>
            </ul>
          </li>

          <li className='menu-title'>Pages</li>
          <li>
            <Link to='/#' className='has-arrow waves-effect'>
              <i className='bx bx-user-circle'></i>
              <span>{props.t('Authentication')}</span>
            </Link>
            <ul className='sub-menu'>
              <li>
                <Link to='pages-login'>{props.t('Login')}</Link>
              </li>
              <li>
                <Link to='pages-register'>{props.t('Register')}</Link>
              </li>
              <li>
                <Link to='pages-forget-pwd'>{props.t('Forget Password')}</Link>
              </li>
              <li>
                <Link to='auth-lock-screen'>{props.t('Lock Screen')}</Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to='/#' className='has-arrow waves-effect'>
              <i className='bx bx-file'></i>
              <span>{props.t('Utility')}</span>
            </Link>
            <ul className='sub-menu' aria-expanded='false'>
              <li>
                <Link to='pages-starter'>{props.t('Starter Page')}</Link>
              </li>
              <li>
                <Link to='pages-maintenance'>{props.t('Maintenance')}</Link>
              </li>
              <li>
                <Link to='pages-comingsoon'>{props.t('Coming Soon')}</Link>
              </li>
              <li>
                <Link to='pages-timeline'>{props.t('Timeline')}</Link>
              </li>
              <li>
                <Link to='pages-faqs'>{props.t('FAQs')}</Link>
              </li>
              <li>
                <Link to='pages-pricing'>{props.t('Pricing')}</Link>
              </li>
              <li>
                <Link to='pages-404'>{props.t('Error 404')}</Link>
              </li>
              <li>
                <Link to='pages-500'>{props.t('Error 500')}</Link>
              </li>
            </ul>
          </li>

          <li className='menu-title'>{props.t('Components')}</li>

          <li>
            <Link to='/#' className='has-arrow waves-effect'>
              <i className='bx bx-tone'></i>
              <span>{props.t('UI Elements')}</span>
            </Link>
            <ul className='sub-menu' aria-expanded='false'>
              <li>
                <Link to='ui-alerts'>{props.t('Alerts')}</Link>
              </li>
              <li>
                <Link to='ui-buttons'>{props.t('Buttons')}</Link>
              </li>
              <li>
                <Link to='ui-cards'>{props.t('Cards')}</Link>
              </li>
              <li>
                <Link to='ui-carousel'>{props.t('Carousel')}</Link>
              </li>
              <li>
                <Link to='ui-dropdowns'>{props.t('Dropdowns')}</Link>
              </li>
              <li>
                <Link to='ui-grid'>{props.t('Grid')}</Link>
              </li>
              <li>
                <Link to='ui-images'>{props.t('Images')}</Link>
              </li>
              <li>
                <Link to='ui-lightbox'>{props.t('Lightbox')}</Link>
              </li>
              <li>
                <Link to='ui-modals'>{props.t('Modals')}</Link>
              </li>
              <li>
                <Link to='ui-rangeslider'>{props.t('Range Slider')}</Link>
              </li>
              <li>
                <Link to='ui-session-timeout'>
                  {props.t('Session Timeout')}
                </Link>
              </li>
              <li>
                <Link to='ui-progressbars'>{props.t('Progress Bars')}</Link>
              </li>
              <li>
                <Link to='ui-sweet-alert'>{props.t('Sweet-Alert')}</Link>
              </li>
              <li>
                <Link to='ui-tabs-accordions'>
                  {props.t('Tabs & Accordions')}
                </Link>
              </li>
              <li>
                <Link to='ui-typography'>{props.t('Typography')}</Link>
              </li>
              <li>
                <Link to='ui-video'>{props.t('Video')}</Link>
              </li>
              <li>
                <Link to='ui-general'>{props.t('General')}</Link>
              </li>
              <li>
                <Link to='ui-colors'>{props.t('Colors')}</Link>
              </li>
              <li>
                <Link to='ui-rating'>{props.t('Rating')}</Link>
              </li>
              <li>
                <Link to='ui-notifications'>{props.t('Notifications')}</Link>
              </li>
              <li>
                <Link to='ui-image-cropper'>{props.t('Image Cropper')}</Link>
              </li>
            </ul>
          </li>

          <li>
            <Link to='/#' className='waves-effect'>
              <i className='bx bxs-eraser'></i>
              <span className='badge badge-pill badge-danger float-right'>
                6
              </span>
              <span>{props.t('Forms')}</span>
            </Link>
            <ul className='sub-menu' aria-expanded='false'>
              <li>
                <Link to='form-elements'>{props.t('Form Elements')}</Link>
              </li>
              <li>
                <Link to='form-validation'>{props.t('Form Validation')}</Link>
              </li>
              <li>
                <Link to='form-advanced'>{props.t('Form Advanced')}</Link>
              </li>
              <li>
                <Link to='form-editors'>{props.t('Form Editors')}</Link>
              </li>
              <li>
                <Link to='form-uploads'>{props.t('Form File Upload')} </Link>
              </li>
              <li>
                <Link to='form-xeditable'>{props.t('Form Xeditable')}</Link>
              </li>
              <li>
                <Link to='form-repeater'>{props.t('Form Repeater')}</Link>
              </li>
              <li>
                <Link to='form-wizard'>{props.t('Form Wizard')}</Link>
              </li>
              <li>
                <Link to='form-mask'>{props.t('Form Mask')}</Link>
              </li>
            </ul>
          </li>

          <li>
            <Link to='/#' className='has-arrow waves-effect'>
              <i className='bx bx-list-ul'></i>
              <span>{props.t('Tables')}</span>
            </Link>
            <ul className='sub-menu' aria-expanded='false'>
              <li>
                <Link to='tables-basic'>{props.t('Basic Tables')}</Link>
              </li>
              <li>
                <Link to='tables-datatable'>{props.t('Data Tables')}</Link>
              </li>
              <li>
                <Link to='tables-responsive'>
                  {props.t('Responsive Table')}
                </Link>
              </li>
              <li>
                <Link to='tables-editable'>{props.t('Editable Table')}</Link>
              </li>
            </ul>
          </li>

          <li>
            <Link to='/#' className='has-arrow waves-effect'>
              <i className='bx bxs-bar-chart-alt-2'></i>
              <span>{props.t('Charts')}</span>
            </Link>

            <ul className='sub-menu' aria-expanded='false'>
              <li>
                <Link to='apex-charts'>{props.t('Apex charts')}</Link>
              </li>
              <li>
                <Link to='chartist-charts'>{props.t('Chartist Chart')}</Link>
              </li>
              <li>
                <Link to='chartjs-charts'>{props.t('Chartjs Chart')}</Link>
              </li>
              <li>
                <Link to='e-charts'>{props.t('E Chart')}</Link>
              </li>
              <li>
                <Link to='tui-charts'>{props.t('Toast UI Chart')}</Link>
              </li>
              <li>
                <Link to='sparkline-charts'>{props.t('Sparkline Chart')}</Link>
              </li>
              <li>
                <Link to='charts-knob'>{props.t('Knob Chart')}</Link>
              </li>
            </ul>
          </li>

          <li>
            <Link to='/#' className='has-arrow waves-effect'>
              <i className='bx bx-aperture'></i>
              <span>{props.t('Icons')}</span>
            </Link>
            <ul className='sub-menu' aria-expanded='false'>
              <li>
                <Link to='icons-boxicons'>{props.t('Boxicons')}</Link>
              </li>
              <li>
                <Link to='icons-materialdesign'>
                  {props.t('Material Design')}
                </Link>
              </li>
              <li>
                <Link to='icons-dripicons'>{props.t('Dripicons')}</Link>
              </li>
              <li>
                <Link to='icons-fontawesome'>{props.t('Font awesome')}</Link>
              </li>
            </ul>
          </li>

          <li>
            <Link to='/#' className='has-arrow waves-effect'>
              <i className='bx bx-map'></i>
              <span>{props.t('Maps')}</span>
            </Link>
            <ul className='sub-menu' aria-expanded='false'>
              <li>
                <Link to='maps-google'>{props.t('Google Maps')}</Link>
              </li>
              <li>
                <Link to='maps-vector'>{props.t('Vector Maps')}</Link>
              </li>
              <li>
                <Link to='maps-leaflet'>{props.t('Leaflet Maps')}</Link>
              </li>
            </ul>
          </li>

          <li>
            <Link to='/#' className='has-arrow waves-effect'>
              <i className='bx bx-share-alt'></i>
              <span>{props.t('Multi Level')}</span>
            </Link>
            <ul className='sub-menu' aria-expanded='true'>
              <li>
                <Link to='#'>{props.t('Level 1.1')}</Link>
              </li>
              <li>
                <Link to='#' className='has-arrow'>
                  {props.t('Level 1.2')}
                </Link>
                <ul className='sub-menu' aria-expanded='true'>
                  <li>
                    <Link to='#'>{props.t('Level 2.1')}</Link>
                  </li>
                  <li>
                    <Link to='#'>{props.t('Level 2.2')}</Link>
                  </li>
                </ul>
              </li>
            </ul>
          </li> */}
        </ul>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { businessLocations, filter, loading } = state.BusinessLocation;
  return { businessLocations, filter, loading };
};

export default withRouter(
  connect(mapStatetoProps, { getBusinessLocations })(
    withNamespaces()(SidebarContent)
  )
);
