import { takeEvery, fork, put, all, call, select } from 'redux-saga/effects';
import {
  GET_ORDERS,
  GET_ORDERS_LIST,
  ADD_ORDER,
  DELETE_ORDER,
  EDIT_ORDER,
  UPDATE_ORDER,
  GET_SAVED_ORDERS,
  DELETE_SAVED_ORDER
} from './actionTypes';
import {
  setOrdersList,
  setOrders,
  emptyOrder,
  setOrder,
  apiError,
  getOrders as fetchOrders,
  setOrderFilter,
  setSavedOrders,
  setSavedOrderFilter,
  getSavedOrders as fetchSavedOrders
} from './actions';
import { httpDelete, httpGet, httpPost, httpPut } from '../../utils/http';
import { ORDERS_ENDPOINT } from '../../config/endPoints';
import { successToaster, errorToaster } from "../../components/Common/Toaster";

function* getOrders() {
  try {
    const orderState = yield select(getOrder);
    let URL = ORDERS_ENDPOINT;
    URL += `?offSet=${orderState.filter.offSet}`;
    URL += `&limit=${orderState.filter.limit}`;
    URL += `&query=${orderState.filter.query}`;

    const res = yield httpGet(URL);

    if (res.status !== 200) {
      yield put(apiError(res.data.error));
      return false;
    }

    yield put(setOrders(res.data.records));
    yield put(
      setOrderFilter({
        field: 'pages',
        value: res.data.pages,
      })
    );
  } catch (error) {
    yield put(apiError(error));
  }
}

function* getSavedOrders() {
  try {
    const orderState = yield select(getOrder);
    let URL = `${ORDERS_ENDPOINT}/saved`;
    URL += `?offSet=${orderState.savedOrdersFilter.offSet}`;
    URL += `&limit=${orderState.savedOrdersFilter.limit}`;
    URL += `&query=${orderState.savedOrdersFilter.query}`;

    const res = yield httpGet(URL);

    if (res.status !== 200) {
      yield put(apiError(res.data.error));
      return false;
    }

    yield put(setSavedOrders(res.data.records));
    yield put(
      setSavedOrderFilter({
        field: 'pages',
        value: res.data.pages,
      })
    );
  } catch (error) {
    yield put(apiError(error));
  }
}

function* deleteOrder({ payload: { id } }) {
  try {
    const res = yield httpDelete(`${ORDERS_ENDPOINT}/${id}`);
    if (res.status !== 200) {
      yield put(apiError(res.data.error));
      return false;
    }
    console.log('shoud run');
    yield put(fetchOrders());
  } catch (error) {
    yield put(apiError(error));
  }
}

function* deleteSavedOrder({ payload: { id } }) {
  try {
    const res = yield httpDelete(`${ORDERS_ENDPOINT}/saved/${id}`);
    if (res.status !== 200) {
      yield put(apiError(res.data.error));
      return false;
    }
    console.log('shoud run');
    yield put(fetchSavedOrders());
  } catch (error) {
    yield put(apiError(error));
  }
}

const getOrder = (state) => state.Order;

export function* watchGetOrders() {
  yield takeEvery(GET_ORDERS, getOrders);
}

export function* watchDeleteOrder() {
  yield takeEvery(DELETE_ORDER, deleteOrder);
}

export function* watchGetSavedOrders() {
  yield takeEvery(GET_SAVED_ORDERS, getSavedOrders);
}

export function* watchDeleteSavedOrder() {
  yield takeEvery(DELETE_SAVED_ORDER, deleteSavedOrder);
}

function* orderSaga() {
  yield all([
    fork(watchGetOrders),
    fork(watchDeleteOrder),
    fork(watchGetSavedOrders),
    fork(watchDeleteSavedOrder)
  ]);
}

export default orderSaga;
