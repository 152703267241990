import {
  GET_RECEIPES,
  SET_RECEIPES,
  API_ERROR,
  SET_RECEIPE,
  SET_RECEIPE_FILTER,
  EMPTY_RECEIPE,
  SET_RECEIPES_LIST,
} from "./actionTypes";

const initialState = {
  receipesList: [],
  receipes: [],
  filter: {
    offSet: 0,
    limit: 10,
    pages: 0,
    currentPage: 1,
    query: "",
  },
  receipe: {
    receipe_name: '',
    receipe_description: '',
    url: '',
    size_number: 1,
    receipe_details: [
      {
        naTotal: 0,
        custom: 0,
        is_procedure: 0,
        procedure: '',
        product: '',
        ru: '',
        ru_in_cpu: '',
        cpu: '',
        total_ru: '',
        total: '',
        ratio_receipe: '',
        per_receipe: '',
        cost: '',
        cost_clone: '',
        vendor: '',
        vendor_item: ''
      }
    ],
    receipe_instructions: [
      {
       step: '',
       time: [
        {
          hours: '',
          minutes: '',
          seconds: ''
        }
       ]
      }
    ],
    image: '',
    plu: ''
  },
  error: "",
  loading: false,
};

const receipe = (state = initialState, action) => {
  switch (action.type) {
    case GET_RECEIPES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case SET_RECEIPES:
      state = {
        ...state,
        receipes: action.payload,
        loading: false,
      };
      break;
    case SET_RECEIPE:
      state = {
        ...state,
        receipe: {
          ...state.receipe,
          [action.payload.field]: action.payload.value,
        },
      };
      break;
    case EMPTY_RECEIPE:
      state = {
        ...state,
        receipe: {
          ...state.receipe,
          receipe_name: '',
          receipe_description: '',
          url: '',
          image: '',
          receipe_details: [
            {
              naTotal: 0,
              custom: 0,
              is_procedure: 0,
              procedure: '',
              product: '',
              ru: '',
              ru_in_cpu: '',
              cpu: '',
              total_ru: '',
              total: '',
              cost_clone: '',
              ratio_receipe: '',
              per_receipe: '',
              cost: '',
              vendor: '',
              vendor_item: ''
            }
          ],
          receipe_instructions: [
            {
              step: '',
              time: [
               {
                 hours: '',
                 minutes: '',
                 seconds: ''
               }
              ]
             }
          ],
          plu: ''
        },
      };
      break;
    case SET_RECEIPE_FILTER:
      state = {
        ...state,
        filter: {
          ...state.filter,
          [action.payload.field]: action.payload.value,
        },
      };
      break;
    case SET_RECEIPES_LIST:
      state = {
        ...state,
        receipesList: action.payload,
      };
      break;
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default receipe;
