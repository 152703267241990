import { takeEvery, fork, put, all, call, select } from 'redux-saga/effects';
import {
  GET_ENQUIRIES,
  GET_UNITS,
} from './actionTypes';
import {
  setEnquiries,
  apiError,
  setUnitFilter,
  setEnquiryFilter,
} from './actions';
import { httpDelete, httpGet, httpPost, httpPut } from '../../utils/http';
import { ENQUIRIES_ENDPOINT } from '../../config/endPoints';
import { successToaster, errorToaster } from "../../components/Common/Toaster";

function* getEnquiries() {
  try {
    const enqueryState = yield select(getEnquery);
    let URL = ENQUIRIES_ENDPOINT;
    URL += `?offSet=${enqueryState.filter.offSet}`;
    URL += `&limit=${enqueryState.filter.limit}`;
    URL += `&query=${enqueryState.filter.query}`;

    const res = yield httpGet(URL);

    if (res.status !== 200) {
      yield put(apiError(res.data.error));
      return false;
    }

    yield put(setEnquiries(res.data.records));
    yield put(
      setEnquiryFilter({
        field: 'pages',
        value: res.data.pages,
      })
    );
  } catch (error) {
    yield put(apiError(error));
  }
}

const getEnquery = (state) => state.Enquery;

export function* watchGetEnquiries() {
  yield takeEvery(GET_ENQUIRIES, getEnquiries);
}

function* enquerySaga() {
  yield all([
    fork(watchGetEnquiries)
  ]);
}

export default enquerySaga;
