import {
  GET_PREP_PLAN_REPORTS,
  SET_PREP_PLAN_REPORTS,
  GET_PREP_PLAN_REPORTS_LIST,
  SET_PREP_PLAN_REPORTS_LIST,
  DELETE_PREP_PLAN_REPORT,
  SET_PREP_PLAN_REPORT_FILTER,
  API_ERROR,
} from "./actionTypes";

export const getPrepPlanReports = () => {
  return {
    type: GET_PREP_PLAN_REPORTS,
    payload: {},
  };
};

export const setPrepPlanReports = (data) => {
  return {
    type: SET_PREP_PLAN_REPORTS,
    payload: data,
  };
};

export const deletePrepPlanReport = (id) => {
  return {
    type: DELETE_PREP_PLAN_REPORT,
    payload: { id },
  };
};

export const setPrepPlanReport = (data) => {
  return {
    type: SET_PREP_PLAN_REPORT_FILTER,
    payload: data,
  };
};

export const getPrepPlanReportsList = () => {
  return {
    type: GET_PREP_PLAN_REPORTS_LIST,
    payload: {},
  };
};

export const setPrepPlanReportsList = (data) => {
  return {
    type: SET_PREP_PLAN_REPORTS_LIST,
    payload: data,
  };
};

export const setPrepPlanReportFilter = (data) => {
  return {
    type: SET_PREP_PLAN_REPORT_FILTER,
    payload: data,
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};
