/**
 * Live Plans
//  */
export const Plans = {
    WOOKAI_SUPPLIER_PRICE_CHECKER: 'price_1LiDHZElv5pcJu684m0f81XO',
    WOOKAI_SUPPLY_LIST_MANAGEMENT_SUPPLIER_PRICE_MANAGEMENT_PRODUCT_MANAGEMENT: 'price_1LiDHTElv5pcJu68u4UnyUsS',
    WOOKAI_SUPPLY_LIST_MANAGEMENT_SUPPLIER_PRICE_MANAGEMENT_PRODUCT_MANAGEMENT_ADDITIONAL: 'price_1LiDHhElv5pcJu68mvlYiyED',
    WOOKAI_SUPPLY_LIST_MANAGEMENT_SUPPLIER_PRICE_MANAGEMENT_ORDERING_RECEIVING: 'price_1LiDHJElv5pcJu68h5vMPoA3',
    WOOKAI_SUPPLY_LIST_MANAGEMENT_SUPPLIER_PRICE_MANAGEMENT_ORDERING_RECEIVING_ADDITIONAL: 'price_1LiDHoElv5pcJu687FFDnyVW',
    WOOKAI_ULTIMATE: 'price_1LiDHBElv5pcJu68IVYkxbJ8',
    WOOKAI_ULTIMATE_ADDITIONAL: 'price_1LiDHvElv5pcJu68RVsnUtKR',
    WOOKAI_ULTIMATE_YEARLY: 'price_1NExivElv5pcJu682qQdytiF',
    WOOKAI_ULTIMATE_YEARLY_ADDITIONAL: 'price_1NExllElv5pcJu68IuLBqFBh'
}

/**
 * Test Plans
 */
//  export const Plans = {
//     WOOKAI_SUPPLIER_PRICE_CHECKER: 'price_1Lc0PKElv5pcJu68AZqAwIV0',
//     WOOKAI_SUPPLY_LIST_MANAGEMENT_SUPPLIER_PRICE_MANAGEMENT_PRODUCT_MANAGEMENT: 'price_1LcjsxElv5pcJu684rY7Hwdf',
//     WOOKAI_SUPPLY_LIST_MANAGEMENT_SUPPLIER_PRICE_MANAGEMENT_PRODUCT_MANAGEMENT_ADDITIONAL: 'price_1Lck9mElv5pcJu681Z7VEeyj',
//     WOOKAI_SUPPLY_LIST_MANAGEMENT_SUPPLIER_PRICE_MANAGEMENT_ORDERING_RECEIVING: 'price_1LckDgElv5pcJu6802Kv6Mog',
//     WOOKAI_SUPPLY_LIST_MANAGEMENT_SUPPLIER_PRICE_MANAGEMENT_ORDERING_RECEIVING_ADDITIONAL: 'price_1LckGAElv5pcJu68X0b1hyHF',
//     WOOKAI_ULTIMATE: 'price_1LckI2Elv5pcJu68nvJLIwTk',
//     WOOKAI_ULTIMATE_ADDITIONAL: 'price_1LckKfElv5pcJu6852byTcv4'
// }