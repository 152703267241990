import React, { useState, useEffect } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, FormGroup, Button, Row, Col } from 'reactstrap';

//i18n
import { withNamespaces } from 'react-i18next';
// Redux
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';


// users
import user1 from '../../../assets/images/users/avatar-1.jpg';
import jwt_decode from 'jwt-decode';
import { USERS_ENDPOINT, ASSETS_PATH, BUCKET_URL, SETTINGS_ENDPOINT } from '../../../config/endPoints';
import { httpGet, httpPost } from '../../../utils/http';
import { success } from 'toastr';
import { errorToaster, successToaster } from '../../Common/Toaster';

const ProfileMenu = (props) => {

   const userObject = JSON.parse(localStorage.getItem('wookaiUserObject'));


   // Declare a new state variable, which we'll call "menu"
   const [menu, setMenu] = useState(false);
   const [password, setPassword] = useState('');
   const [isModal, setModal] = useState(false);

  
   const [username, setusername] = useState("Admin");

    // decode token to get the user type
    const jwtDecode = jwt_decode(localStorage.getItem('token'));
    const userName = jwtDecode.full_name;
    const userType = jwtDecode.user_type;

   useEffect(() => {
           if(localStorage.getItem("authUser"))
           {
             if(process.env.REACT_APP_DEFAULTAUTH === 'firebase')
             {
                const obj = JSON.parse(localStorage.getItem("authUser"));
                setusername(obj.displayName);
             }
              else if((process.env.REACT_APP_DEFAULTAUTH === 'fake') || (process.env.REACT_APP_DEFAULTAUTH === 'jwt'))
             {
                const obj = JSON.parse(localStorage.getItem("authUser"));
                setusername(obj.username);
             }
          }
      },[props.success]);

  const closeModal = () => {
    setModal(false);
    setPassword('');
  }

  const openMasterPasswordModal = async() => {
    const res = await httpGet(`${SETTINGS_ENDPOINT}/1`);

    setPassword(res.data.master_password);
    setModal(true);
  }
  
  const changePasswordHandleSubmit = async () => {
    if(!password){
        errorToaster('Please enter password', 'Error');
        return false;
    }
    await httpPost(`${SETTINGS_ENDPOINT}/changeMasterPassword`, {password});

    closeModal();
    successToaster('Master password changed successfully.');
  }

  return (
    <React.Fragment>
        <Modal isOpen={isModal} toggle={() => closeModal}>
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              Change Master Password
            </h5>
            <button
              type="button"
              onClick={() => closeModal()}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Row>
              <Col md="12">
                <FormGroup>
                  <input
                    className={`form-control ${password ? "" : "is-invalid"}`}
                    onChange={(event) => setPassword(event.target.value)}
                    value={password}
                    name="password"
                    type="text"
                  />
                  <div className="invalid-feedback">Enter password</div>
                </FormGroup>
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <Button
              color="primary"
              type="button"
              onClick={() => changePasswordHandleSubmit()}
            >
              Submit
            </Button>
          </div>
        </Modal>
                <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block" >
                    <DropdownToggle className="btn header-item waves-effect" id="page-header-user-dropdown" tag="button">

                        {
                            userObject.image 
                            ? 
                            <img className="rounded-circle header-profile-user" alt="Header Avatar" id="page-header-user-dropdown" tag="button"  src={`${BUCKET_URL}/${userObject.image}`} />
                            :
                            <img className="rounded-circle header-profile-user" alt="Header Avatar" id="page-header-user-dropdown" tag="button"  src={`${ASSETS_PATH}/images/noimagefound.jpg`} />
                        }
                       
                        <span className="d-none d-xl-inline-block ml-2 mr-1">{userName}</span>
                        <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                    </DropdownToggle>
                    <DropdownMenu right>
                        {/* <DropdownItem tag="a"  href="/profile"> <i className="bx bx-user font-size-16 align-middle mr-1"></i>{props.t('Profile')}  </DropdownItem>
                        <DropdownItem tag="a" href="/crypto-wallet"><i className="bx bx-wallet font-size-16 align-middle mr-1"></i>{props.t('My Wallet')}</DropdownItem>
                        <DropdownItem tag="a" href="#"><span className="badge badge-success float-right">11</span><i className="mdi mdi-settings font-size-17 align-middle mr-1"></i>{props.t('Settings')}</DropdownItem>
                        <DropdownItem tag="a" href="auth-lock-screen"><i className="bx bx-lock-open font-size-16 align-middle mr-1"></i>{props.t('Lock screen')}</DropdownItem>
                        <div className="dropdown-divider"></div> */}
                        <Link to="/manageAccount" className="dropdown-item">
                            <i className="bx bx-user font-size-16 align-middle mr-1 text-danger"></i>
                            <span>{props.t('Manage Account')}</span>
                        </Link>
                        {userType !== 'Super-Admin' && userType !== 'Sub-Admin' && (
                        <Link to="/selectBusinessLocation" className="dropdown-item">
                            <i className="bx bxs-business font-size-16 align-middle mr-1 text-danger"></i>
                            <span>{props.t('Add/Change Location(s)')}</span>
                        </Link>
                        )}
                        {(userType == 'Super-Admin' || userType == 'Sub-Admin') && (
                            <Link to="#" className="dropdown-item" onClick={() => openMasterPasswordModal()}>
                                <i className="bx bxs-user font-size-16 align-middle mr-1 text-danger"></i>
                                <span>{props.t('Change Master Password')}</span>
                            </Link>

                        )}
                        <Link to="/logout" className="dropdown-item">
                            <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
                            <span>{props.t('Logout')}</span>
                        </Link>
                    </DropdownMenu>
                </Dropdown>
            </React.Fragment>
  );
}

const mapStatetoProps = state => {
    const { error,success } = state.Profile;
    return { error,success };
}

export default withRouter(connect(mapStatetoProps, {  })(withNamespaces()(ProfileMenu)));

