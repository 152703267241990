import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { FORGET_PASSWORD, VERIFY_CODE, CHANGE_PASSWORD_AFTER_CODE_VERIFY } from "./actionTypes";
import {
  userForgetPasswordSuccess,
  userForgetPasswordError,
  codeSentOnEmail,
  verifyCodeSuccess,
  verifyCodeFailed,
} from "./actions";

import { AUTH_ENDPOINT } from "../../../config/endPoints";
import {
  successToaster,
  errorToaster,
} from "../../../components/Common/Toaster";
import { httpPost } from "../../../utils/http";

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { email, history } }) {
  try {
    const data = {
      email,
    };

    const res = yield httpPost(`${AUTH_ENDPOINT}/forgotPassword/sendOtp`, data);
    if (res.status !== 200) {
      yield put(userForgetPasswordError(res.data));
      return false;
    }

    yield put(codeSentOnEmail(true));
    successToaster("Code Sent on your email", "Success.");
  } catch (error) {
    yield put(userForgetPasswordError(error));
  }
}

function* userVerifyCode({ payload: { email, code } }) {
  console.log("inisde saaga");
  try {
    const data = {
      email,
      code,
    };

    const res = yield httpPost(
      `${AUTH_ENDPOINT}/forgotPassword/verifySentOtp`,
      data
    );
    if (res.status !== 200) {
      yield put(verifyCodeFailed(res.data));
      return false;
    }

    yield put(verifyCodeSuccess(true));
    successToaster("Change your Password", "Success.");
  } catch (error) {
    yield put(verifyCodeFailed(error));
  }
}

function* changePasswordAfterVerify({ payload: { history, email, password } }) {
  console.log("inisde saaga");
  try {
    const data = {
      email,
      password,
    };

    const res = yield httpPost(
      `${AUTH_ENDPOINT}/forgotPassword/changePassword`,
      data
    );
    if (res.status !== 200) {
      errorToaster("Something went wrong please try again.", "Error");
      return false;
    }

    history.push('/dashboard');
    successToaster("Password changed successfully.", "Success");
  } catch (error) {
    yield put(verifyCodeFailed(error));
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetUser);
}

export function* watchUserPasswordCodeVerify() {
  yield takeEvery(VERIFY_CODE, userVerifyCode);
}

export function* watchUserPasswordChangeAfterVerify() {
  yield takeEvery(CHANGE_PASSWORD_AFTER_CODE_VERIFY, changePasswordAfterVerify);
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget), fork(watchUserPasswordCodeVerify), fork(watchUserPasswordChangeAfterVerify)]);
}

export default forgetPasswordSaga;
