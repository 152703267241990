export const GET_INVENTORY_COSTS = 'GET_INVENTORY_COSTS';
export const SET_INVENTORY_COSTS = 'SET_INVENTORY_COSTS';
export const API_ERROR = 'LOGIN_API_ERROR';
export const SET_INVENTORY_COST = 'SET_INVENTORY_COST';
export const ADD_INVENTORY_COST = 'ADD_INVENTORY_COST';
export const EMPTY_INVENTORY_COST = 'EMPTY_INVENTORY_COST';
export const DELETE_INVENTORY_COST = 'DELETE_INVENTORY_COST';
export const EDIT_INVENTORY_COST = 'EDIT_INVENTORY_COST';
export const UPDATE_INVENTORY_COST = 'UPDATE_INVENTORY_COST';
export const SET_INVENTORY_COST_FILTER = 'SET_INVENTORY_COST_FILTER';
export const GET_INVENTORY_COSTS_LIST = 'GET_INVENTORY_COSTS_LIST';
export const SET_INVENTORY_COSTS_LIST = 'SET_INVENTORY_COSTS_LIST';
export const GET_SAVED_INVENTORY_COSTS = 'GET_SAVED_INVENTORY_COSTS';
export const SET_SAVED_INVENTORY_COSTS = 'SET_SAVED_INVENTORY_COSTS';
export const SET_SAVED_INVENTORY_COST_FILTER = 'SET_SAVED_INVENTORY_COST_FILTER';
export const DELETE_SAVED_INVENTORY_COST = 'DELETE_SAVED_INVENTORY_COST';