export const GET_ORDERS = 'GET_ORDERS';
export const SET_ORDERS = 'SET_ORDERS';
export const API_ERROR = 'LOGIN_API_ERROR';
export const SET_ORDER = 'SET_ORDER';
export const ADD_ORDER = 'ADD_ORDER';
export const EMPTY_ORDER = 'EMPTY_ORDER';
export const DELETE_ORDER = 'DELETE_ORDER';
export const EDIT_ORDER = 'EDIT_ORDER';
export const UPDATE_ORDER = 'UPDATE_ORDER';
export const SET_ORDER_FILTER = 'SET_ORDER_FILTER';
export const GET_ORDERS_LIST = 'GET_ORDERS_LIST';
export const SET_ORDERS_LIST = 'SET_ORDERS_LIST';
export const GET_SAVED_ORDERS = 'GET_SAVED_ORDERS';
export const SET_SAVED_ORDERS = 'SET_SAVED_ORDERS';
export const SET_SAVED_ORDER_FILTER = 'SET_SAVED_ORDER_FILTER';
export const DELETE_SAVED_ORDER = 'DELETE_SAVED_ORDER';