import {
  GET_INVENTORY_COSTS,
  SET_INVENTORY_COSTS,
  DELETE_INVENTORY_COST,
  SET_INVENTORY_COST_FILTER,
  API_ERROR,
} from "./actionTypes";

export const getInventoryCosts = () => {
  return {
    type: GET_INVENTORY_COSTS,
    payload: {},
  };
};

export const setInventoryCosts = (data) => {
  return {
    type: SET_INVENTORY_COSTS,
    payload: data,
  };
};

export const deleteInventoryCost = (id) => {
  return {
    type: DELETE_INVENTORY_COST,
    payload: { id },
  };
};

export const setInventoryCostFilter = (data) => {
  return {
    type: SET_INVENTORY_COST_FILTER,
    payload: data,
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};
