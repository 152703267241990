import {
  GET_ORDERS,
  SET_ORDERS,
  GET_ORDERS_LIST,
  SET_ORDERS_LIST,
  SET_ORDER,
  ADD_ORDER,
  EMPTY_ORDER,
  DELETE_ORDER,
  EDIT_ORDER,
  UPDATE_ORDER,
  SET_ORDER_FILTER,
  SET_SAVED_ORDERS,
  GET_SAVED_ORDERS,
  SET_SAVED_ORDER_FILTER,
  DELETE_SAVED_ORDER,
  API_ERROR,
} from "./actionTypes";

export const getOrders = () => {
  return {
    type: GET_ORDERS,
    payload: {},
  };
};

export const setOrders = (data) => {
  return {
    type: SET_ORDERS,
    payload: data,
  };
};

export const setOrder = (data) => {
  return {
    type: SET_ORDER,
    payload: data,
  };
};

export const addOrder = (history) => {
  return {
    type: ADD_ORDER,
    payload: { history },
  };
};

export const emptyOrder = () => {
  return {
    type: EMPTY_ORDER,
    payload: {},
  };
};

export const deleteOrder = (id) => {
  return {
    type: DELETE_ORDER,
    payload: { id },
  };
};

export const editOrder = (id) => {
  return {
    type: EDIT_ORDER,
    payload: { id },
  };
};

export const updateOrder = (history, id) => {
  return {
    type: UPDATE_ORDER,
    payload: { history, id },
  };
};

export const setOrderFilter = (data) => {
  return {
    type: SET_ORDER_FILTER,
    payload: data,
  };
};

export const getOrdersList = () => {
  return {
    type: GET_ORDERS_LIST,
    payload: {},
  };
};

export const setOrdersList = (data) => {
  return {
    type: SET_ORDERS_LIST,
    payload: data,
  };
};

export const getSavedOrders = () => {
  return {
    type: GET_SAVED_ORDERS,
    payload: {},
  };
};

export const setSavedOrders = (data) => {
  return {
    type: SET_SAVED_ORDERS,
    payload: data,
  };
};

export const setSavedOrderFilter = (data) => {
  return {
    type: SET_SAVED_ORDER_FILTER,
    payload: data,
  };
};

export const deleteSavedOrder = (id) => {
  return {
    type: DELETE_SAVED_ORDER,
    payload: { id },
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};
