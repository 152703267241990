import {
  GET_IDEAL_PRODUCT_COSTS,
  SET_IDEAL_PRODUCT_COSTS,
  GET_IDEAL_PRODUCT_COSTS_LIST,
  SET_IDEAL_PRODUCT_COSTS_LIST,
  SET_IDEAL_PRODUCT_COST,
  ADD_IDEAL_PRODUCT_COST,
  EMPTY_IDEAL_PRODUCT_COST,
  DELETE_IDEAL_PRODUCT_COST,
  EDIT_IDEAL_PRODUCT_COST,
  UPDATE_IDEAL_PRODUCT_COST,
  API_ERROR,
  SET_IDEAL_PRODUCT_COST_FILTER,
  ADD_IDEAL_PRODUCT_COST_SORT,
} from './actionTypes';

export const getIdealProductCosts = () => {
  return {
    type: GET_IDEAL_PRODUCT_COSTS,
    payload: {},
  };
};

export const setIdealProductCosts = (data) => {
  return {
    type: SET_IDEAL_PRODUCT_COSTS,
    payload: data,
  };
};

export const getIdealProductCostsList = () => {
  return {
    type: GET_IDEAL_PRODUCT_COSTS_LIST,
    payload: {},
  };
};

export const setIdealProductCostsList = (data) => {
  return {
    type: SET_IDEAL_PRODUCT_COSTS_LIST,
    payload: data,
  };
};

export const setIdealProductCost = (data) => {
  return {
    type: SET_IDEAL_PRODUCT_COST,
    payload: data,
  };
};

export const addIdealProductCost = (history) => {
  return {
    type: ADD_IDEAL_PRODUCT_COST,
    payload: {history},
  };
};

export const addIdealProductSort = (data) => {
  return {
    type: ADD_IDEAL_PRODUCT_COST_SORT,
    payload: data,
  };
};


export const emptyIdealProductCost = () => {
  return {
    type: EMPTY_IDEAL_PRODUCT_COST,
    payload: {},
  };
};

export const deleteIdealProductCost = (id) => {
  return {
    type: DELETE_IDEAL_PRODUCT_COST,
    payload: { id },
  };
};

export const editIdealProductCost = (id) => {
  return {
    type: EDIT_IDEAL_PRODUCT_COST,
    payload: { id },
  };
};

export const updateIdealProductCost = (history, id) => {
  return {
    type: UPDATE_IDEAL_PRODUCT_COST,
    payload: { history, id },
  };
};

export const setIdealProductCostFilter = (data) => {
  return {
    type: SET_IDEAL_PRODUCT_COST_FILTER,
    payload: data,
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};
