export const GET_RECEIPES = 'GET_RECEIPES';
export const SET_RECEIPES = 'SET_RECEIPES';
export const API_ERROR = 'LOGIN_API_ERROR';
export const SET_RECEIPE = 'SET_RECEIPE';
export const ADD_RECEIPE = 'ADD_RECEIPE';
export const EMPTY_RECEIPE = 'EMPTY_RECEIPE';
export const DELETE_RECEIPE = 'DELETE_RECEIPE';
export const EDIT_RECEIPE = 'EDIT_RECEIPE';
export const UPDATE_RECEIPE = 'UPDATE_RECEIPE';
export const SET_RECEIPE_FILTER = 'SET_RECEIPE_FILTER';
export const GET_RECEIPES_LIST = 'GET_RECEIPES_LIST';
export const SET_RECEIPES_LIST = 'SET_RECEIPES_LIST';