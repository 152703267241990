import {
  GET_PRODUCT_CATEGORIES,
  SET_PRODUCT_CATEGORIES,
  API_ERROR,
  SET_PRODUCT_CATEGORY,
  SET_PRODUCT_CATEGORY_FILTER,
  EMPTY_PRODUCT_CATEGORY,
  SET_PRODUCT_CATEGORIES_LIST,
} from "./actionTypes";

const initialState = {
  productCategoriesList: [],
  productCategories: [],
  filter: {
    offSet: 0,
    limit: 10,
    pages: 0,
    currentPage: 1,
    query: "",
  },
  productCategory: {
    category_name: "",
  },
  error: "",
  loading: false,
};

const productCategory = (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCT_CATEGORIES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case SET_PRODUCT_CATEGORIES:
      state = {
        ...state,
        productCategories: action.payload,
        loading: false,
      };
      break;
    case SET_PRODUCT_CATEGORY:
      state = {
        ...state,
        productCategory: {
          ...state.productCategory,
          [action.payload.field]: action.payload.value,
        },
      };
      break;
    case EMPTY_PRODUCT_CATEGORY:
      state = {
        ...state,
        productCategory: {
          ...state.productCategory,
          category_name: "",
        },
      };
      break;
    case SET_PRODUCT_CATEGORY_FILTER:
      state = {
        ...state,
        filter: {
          ...state.filter,
          [action.payload.field]: action.payload.value,
        },
      };
      break;
    case SET_PRODUCT_CATEGORIES_LIST:
      state = {
        ...state,
        productCategoriesList: action.payload,
      };
      break;
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default productCategory;
