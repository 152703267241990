import React, { Suspense } from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';

const Authmiddleware = ({ component: Component, layout: Layout }) => (
  <Route
    render={(props) => {
      // here you can apply condition
      if (!localStorage.getItem('token')) {
        return (
          <Suspense fallback={null}>
            <Redirect
              to={{ pathname: '/login', state: { from: props.location } }}
            />
          </Suspense>
        );
      }
      console.log('props', props);

      return (
          <Layout>
            <Suspense fallback={null}>
              <Component {...props} />
            </Suspense>

          </Layout>
      );
    }}
  />
);

export default withRouter(Authmiddleware);