export const GET_ITEM_LOCATIONS = 'GET_ITEM_LOCATIONS';
export const SET_ITEM_LOCATIONS = 'SET_ITEM_LOCATIONS';
export const API_ERROR = 'LOGIN_API_ERROR';
export const SET_ITEM_LOCATION = 'SET_ITEM_LOCATION';
export const ADD_ITEM_LOCATION = 'ADD_ITEM_LOCATION';
export const EMPTY_ITEM_LOCATION = 'EMPTY_ITEM_LOCATION';
export const DELETE_ITEM_LOCATION = 'DELETE_ITEM_LOCATION';
export const EDIT_ITEM_LOCATION = 'EDIT_ITEM_LOCATION';
export const UPDATE_ITEM_LOCATION = 'UPDATE_ITEM_LOCATION';
export const SET_ITEM_LOCATION_FILTER = 'SET_ITEM_LOCATION_FILTER';
export const GET_ITEM_LOCATIONS_LIST = 'GET_ITEM_LOCATIONS_LIST';
export const SET_ITEM_LOCATIONS_LIST = 'SET_ITEM_LOCATIONS_LIST';