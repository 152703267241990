import { takeEvery, fork, put, all, call, select } from 'redux-saga/effects';
import {
  GET_IDEAL_PRODUCT_COSTS,
  ADD_IDEAL_PRODUCT_COST,
  DELETE_IDEAL_PRODUCT_COST,
  EDIT_IDEAL_PRODUCT_COST,
  UPDATE_IDEAL_PRODUCT_COST,
  GET_IDEAL_PRODUCT_COSTS_LIST
} from './actionTypes';
import {
  setIdealProductCostsList,
  setIdealProductCosts,
  emptyIdealProductCost,
  setIdealProductCost,
  apiError,
  getIdealProductCosts as fetchIdealProductCosts,
  setIdealProductCostFilter,
} from './actions';
import { httpDelete, httpGet, httpPost, httpPut, httpPostFormData, httpPutFormData } from '../../utils/http';
import { IDEAL_PRODUCT_COSTS_ENDPOINT } from '../../config/endPoints';
import { successToaster, errorToaster } from "../../components/Common/Toaster";

function* getIdealProductCosts() {
  try {
    const idealProductCostState = yield select(getIdealProductCost);
    let URL = IDEAL_PRODUCT_COSTS_ENDPOINT;
    URL += `?offSet=${idealProductCostState.filter.offSet}`;
    URL += `&limit=${idealProductCostState.filter.limit}`;
    URL += `&query=${idealProductCostState.filter.query}`;

    //get all sorts
    for(let i = 0; i < idealProductCostState.sorts.length; i++){
      if(idealProductCostState.sorts[i].name && idealProductCostState.sorts[i].direction){
        URL += `&${idealProductCostState.sorts[i].name}=${idealProductCostState.sorts[i].direction}`;
      }
    }

    const res = yield httpGet(URL);

    if (res.status !== 200) {
      yield put(apiError(res.data.error));
      return false;
    }

    yield put(setIdealProductCosts(res.data.records));
    yield put(
      setIdealProductCostFilter({
        field: 'pages',
        value: res.data.pages,
      })
    );
  } catch (error) {
    yield put(apiError(error));
  }
}

function* getIdealProductCostsList() {
  try {
    let URL = `${IDEAL_PRODUCT_COSTS_ENDPOINT}/get/list`;
    const res = yield httpGet(URL);

    if (res.status !== 200) {
      yield put(apiError(res.data.error));
      return false;
    }
    yield put(setIdealProductCostsList(res.data));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* addIdealProductCost({payload: {history}}) {
  try {
    const idealProductCostState = yield select(getIdealProductCost);
    const idealProductCost = idealProductCostState.idealProductCost;

    let formData = new FormData();
    formData.append('data', JSON.stringify(idealProductCost));
    formData.append('image', idealProductCost.image);

    const res = yield httpPostFormData(IDEAL_PRODUCT_COSTS_ENDPOINT, formData);
    console.log(res);
    if (res.status !== 200) {
      yield put(apiError(res.data.error));
      return false;
    }

    yield put(emptyIdealProductCost());
    history.push('/ideal-product-cost/list');
    successToaster('Record added.', 'Success');
  } catch (error) {
    yield put(apiError(error));
  }
}

function* deleteIdealProductCost({ payload: { id } }) {
  try {
    const res = yield httpDelete(`${IDEAL_PRODUCT_COSTS_ENDPOINT}/${id}`);
    if (res.status !== 200) {
      yield put(apiError(res.data.error));
      return false;
    }
    console.log('shoud run');
    yield put(fetchIdealProductCosts());
  } catch (error) {
    yield put(apiError(error));
  }
}

function* editIdealProductCost({ payload: { id } }) {
  try {
    const res = yield httpGet(`${IDEAL_PRODUCT_COSTS_ENDPOINT}/${id}`);
    if (res.status !== 200) {
      yield put(apiError(res.data.error));
      return false;
    }

    yield put(setIdealProductCost({ field: 'ideal_product_cost_name', value: res.data.ideal_product_cost_name }));
    yield put(setIdealProductCost({ field: 'ideal_product_cost_description', value: res.data.ideal_product_cost_description }));
    yield put(setIdealProductCost({ field: 'ideal_product_cost_description', value: res.data.ideal_product_cost_description }));
    yield put(setIdealProductCost({ field: 'ideal_product_cost_details', value: res.data.ideal_product_cost_details }));
    yield put(setIdealProductCost({ field: 'ideal_product_cost_instructions', value: res.data.ideal_product_cost_instructions }));
    yield put(setIdealProductCost({ field: 'url', value: res.data.url }));
    yield put(setIdealProductCost({ field: 'image', value: res.data.image }));
    yield put(setIdealProductCost({ field: 'is_sized', value: res.data.is_sized }));
    yield put(setIdealProductCost({ field: 'size_number', value: res.data.size_number }));
    yield put(setIdealProductCost({ field: 'is_suggested_quantity', value: res.data.is_suggested_quantity }));
    yield put(setIdealProductCost({ field: 'quantity_number', value: res.data.quantity_number }));
    yield put(setIdealProductCost({ field: 'current_menu_net_price', value: res.data.current_menu_net_price }));
    yield put(setIdealProductCost({ field: 'ideal_cost_goods', value: res.data.ideal_cost_goods }));
    yield put(setIdealProductCost({ field: 'fk_product_category_id', value: res.data.fk_product_category_id }));
    yield put(setIdealProductCost({ field: 'fk_product_sub_category_id', value: res.data.fk_product_sub_category_id }));
    yield put(setIdealProductCost({ field: 'plu', value: res.data.plu }));

  } catch (error) {
    yield put(apiError(error));
  }
}

function* updateIdealProductCost({ payload: { history, id } }) {
  try {
    const idealProductCostState = yield select(getIdealProductCost);
    const idealProductCost = idealProductCostState.idealProductCost;

    let formData = new FormData();
    formData.append('data', JSON.stringify(idealProductCost));
    formData.append('image', idealProductCost.image);

    const res = yield httpPutFormData(`${IDEAL_PRODUCT_COSTS_ENDPOINT}/${id}`, formData);
    console.log(res);
    if (res.status !== 200) {
      yield put(apiError(res.data.error));
      return false;
    }

    yield put(emptyIdealProductCost());
    history.push('/ideal-product-cost/list');
    successToaster('Record updated.', 'Success');
  } catch (error) {
    yield put(apiError(error));
  }
}

const getIdealProductCost = (state) => state.IdealProductCost;

export function* watchGetIdealProductCosts() {
  yield takeEvery(GET_IDEAL_PRODUCT_COSTS, getIdealProductCosts);
}

export function* watchGetIdealProductCostsList() {
  yield takeEvery(GET_IDEAL_PRODUCT_COSTS_LIST, getIdealProductCostsList);
}

export function* watchAddIdealProductCost() {
  yield takeEvery(ADD_IDEAL_PRODUCT_COST, addIdealProductCost);
}

export function* watchDeleteIdealProductCost() {
  yield takeEvery(DELETE_IDEAL_PRODUCT_COST, deleteIdealProductCost);
}

export function* watchEditIdealProductCost() {
  yield takeEvery(EDIT_IDEAL_PRODUCT_COST, editIdealProductCost);
}

export function* watchUpdateIdealProductCost() {
  yield takeEvery(UPDATE_IDEAL_PRODUCT_COST, updateIdealProductCost);
}

function* idealProductCostSaga() {
  yield all([
    fork(watchGetIdealProductCosts),
    fork(watchAddIdealProductCost),
    fork(watchDeleteIdealProductCost),
    fork(watchEditIdealProductCost),
    fork(watchUpdateIdealProductCost),
    fork(watchGetIdealProductCostsList)
  ]);
}

export default idealProductCostSaga;
