import { takeEvery, fork, put, all, call, select } from 'redux-saga/effects';
import {
  GET_INVENTORY_COSTS,
  DELETE_INVENTORY_COST
} from './actionTypes';
import {
  setInventoryCosts,
  apiError,
  getInventoryCosts as fetchInventoryCosts,
  getSavedOrders as fetchSavedOrders,
  setInventoryCostFilter
} from './actions';
import { httpDelete, httpGet, httpPost, httpPut } from '../../utils/http';
import { INVENTORY_COSTS_ENDPOINT } from '../../config/endPoints';
import { successToaster, errorToaster } from "../../components/Common/Toaster";

function* getInventoryCosts() {
  try {
    const orderState = yield select(getInventoryCost);
    let URL = INVENTORY_COSTS_ENDPOINT;
    URL += `?offSet=${orderState.filter.offSet}`;
    URL += `&limit=${orderState.filter.limit}`;
    URL += `&query=${orderState.filter.query}`;

    const res = yield httpGet(URL);

    if (res.status !== 200) {
      yield put(apiError(res.data.error));
      return false;
    }

    yield put(setInventoryCosts(res.data.records));
    yield put(
      setInventoryCostFilter({
        field: 'pages',
        value: res.data.pages,
      })
    );
  } catch (error) {
    yield put(apiError(error));
  }
}

function* deleteInventoryCost({ payload: { id } }) {
  try {
    const res = yield httpDelete(`${INVENTORY_COSTS_ENDPOINT}/${id}`);
    if (res.status !== 200) {
      yield put(apiError(res.data.error));
      return false;
    }
    console.log('shoud run');
    yield put(fetchInventoryCosts());
  } catch (error) {
    yield put(apiError(error));
  }
}

const getInventoryCost = (state) => state.InventoryCost;

export function* watchGetInventoryCosts() {
  yield takeEvery(GET_INVENTORY_COSTS, getInventoryCosts);
}

export function* watchDeleteInventoryCost() {
  yield takeEvery(DELETE_INVENTORY_COST, deleteInventoryCost);
}

function* inventoryCostSaga() {
  yield all([
    fork(watchGetInventoryCosts),
    fork(watchDeleteInventoryCost)
  ]);
}

export default inventoryCostSaga;
